import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Busy() {
  const app = useSelector((state) => state.app)

  return (
    <StyledSwitchDisplay hide={!app.busy}>
      <FontAwesomeIcon icon="spinner" spin={true} color="black" />
    </StyledSwitchDisplay>
  )
}

//@ts-ignore
const StyledSwitchDisplay = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'block')};
`
