import { createFilterOptions } from '@mui/material/Autocomplete'
import { Autocomplete, Box, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterOptionsState } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
//@ts-expect-error
import type { AppState } from 'state/index'

//@ts-expect-error
import { createBrand } from 'api/farm'

declare module 'components/farm-settings/equipment/make-selector.js' {
  export type MakeSelectorProps = {
    value: BrandOption | null
    onValueChange: Function
    row: any
  }

  type BrandOption = {
    id: number | string
    name: string
    inputValue: string | undefined
  }

  export function MakeSelectorComponent(
    props: MakeSelectorProps,
  ): ReactElement<any, any>
}

export default function MakeSelectorComponent(
  props: MakeSelectorProps,
): ReactElement<any, any> {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filter = createFilterOptions()

  /*Brands*/
  const brands = useSelector((state) => (state as AppState).brand.brands)
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([])
  /*end*/

  const { value, onValueChange, row } = props

  const [isLoading, setIsLoading] = useState(true)
  const [makeBrands, setMakeBrands] = useState<Partial<BrandOption>[]>([])
  const [selectedVal, setSelectedVal] = useState<Partial<BrandOption | null>>({
    id: '',
    name: '',
    inputValue: '',
  })

  /*Brands functions*/
  useEffect(() => {
    let _brandOptions: BrandOption[] = []
    brands.forEach((item) => {
      _brandOptions.push({
        id: item.id,
        name: item.name,
        inputValue: '',
      })
    })
    setBrandOptions(_brandOptions)
    setSelectedVal(value)
    setIsLoading(false)
  }, [brands, value])
  /*End*/

  const handleBrandChange = useCallback(
    (e, newValue?) => {
      //@ts-ignore
      if (newValue && newValue.inputValue !== '') {
        //@ts-ignore
        dispatch(createBrand({ name: newValue.inputValue })).then((res) => {
          const newBrandOption = { id: res.id, name: res.name, inputValue: '' }
          setBrandOptions([...brandOptions, newBrandOption])
          setSelectedVal(newBrandOption)
          onValueChange(newBrandOption)
        })
      } else {
        setSelectedVal(newValue)
        onValueChange(newValue)
      }
    },
    [makeBrands, row],
  )

  return isLoading ? (
    <div>{t('loading')}</div>
  ) : (
    <Autocomplete
      value={selectedVal}
      onChange={(e, newValue) => handleBrandChange(e, newValue)}
      filterOptions={(
        options: Partial<BrandOption>[],
        params: FilterOptionsState<Partial<BrandOption>>,
      ) => {
        const filtered = filter(options, params)
        const { inputValue } = params

        const exists = brandOptions.find(
          (m) => m.name?.toLowerCase() === inputValue?.toLowerCase(),
        )

        if (inputValue !== '' && !exists) {
          filtered.push({
            inputValue,
            name: `${t('add')} "${inputValue}"`,
          })
        }

        return filtered
      }}
      id="equipment-make-selector"
      options={brandOptions}
      getOptionLabel={(option: BrandOption) => {
        if (option.inputValue) {
          return option.inputValue
        }
        return option.name
      }}
      renderOption={(props, option: BrandOption) => (
        <li {...props} key={option.id}>
          <Box display="flex" alignItems="center">
            {option.inputValue !== '' ? (
              <Box>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </Box>
            ) : (
              ''
            )}
            <Box ml={1}>{option.name}</Box>
          </Box>
        </li>
      )}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('select_make_brand')}
          variant="outlined"
          placeholder={t('select_make_brand')}
        />
      )}
    />
  )
}
