import InvitedUsers from 'components/invited-users'
import { useMemo } from 'react'

export default function AgProUsers() {
  const users = useSelector((state) => state.account.invitedUsers)

  const el = useMemo(() => {
    return <InvitedUsers users={users} />
  }, [users])

  return <>{el}</>
}
