import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea'

const optionStyle = (option, value) => ({
  borderRadius: '10px',
  marginRight: '20px',
  border: option === value ? '3px solid #003057' : '1px solid #003057',
})

export default function ImageRadioButtonGroup({ options, setSelectedOption }) {
  const [option, setOption] = useState()

  useEffect(() => {
    if (!option) {
      setOption(options[0].defaultSelection)
      setSelectedOption(options[0].defaultSelection)
    }
  }, [options])

  const handleOnChange = useCallback((option) => {
    setOption(option)
    setSelectedOption(option)
  })

  return (
    <Box display="flex" justifyContent="flex-start">
      {options.map((item) => (
        <Card key={item.label} sx={optionStyle(option, item.value)}>
          <CardActionArea
            centerRipple={false}
            onClick={() => handleOnChange(item.value)}
            sx={{
              padding: '10px 30px',
            }}
          >
            <label
              htmlFor={item.label}
              style={{
                cursor: 'pointer',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                display: 'inline-block',
                width: '135px',
                height: '75px',
                lineHeight: '200px',
                backgroundImage: `url(${item.buttonImage})`,
              }}
            >
              {item.label}
            </label>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  )
}
