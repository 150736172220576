import { Muted } from 'components/foundation'
import { FormGroup, FormControl } from './foundation'

const BaseField = React.forwardRef((props, ref) => {
  const {
    autocomplete = false,
    autoFocus = false,
    defaultValue = '',
    disabled = false,
    error,
    hint,
    isRequired = false,
    label,
    name,
    onChange = () => {},
    onFocus = () => {},
    onKeyUp = () => {},
    placeholder = '',
    type = 'text',
  } = props

  const requiredEl = isRequired ? <span className="required">*</span> : ''

  return (
    <FormGroup {...props}>
      {label ? (
        <label htmlFor={name}>
          {label} {requiredEl}
        </label>
      ) : null}
      <FormControl
        className={disabled ? 'disabled' : ''}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        key={defaultValue}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        autoComplete={autocomplete ? 'on' : 'off'}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        disabled={disabled}
        ref={ref}
      />
      {error ? <span>{error}</span> : null}
      {hint ? <Muted>{hint}</Muted> : null}
    </FormGroup>
  )
})

export default BaseField

BaseField.displayName = 'BaseField'

BaseField.propTypes = {
  autocomplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hint: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}
