//@ts-expect-error
import * as c from 'common/c'

declare module 'state/crop.js' {
  export type CropConstant = {
    buPerTonne: number
    createdAt: null
    cropClassification: string
    defaultTestWeightKgHl: number
    defaultYmCalibration: null
    dielectricClassId: number
    id: number
    marketMoisture: number
    minMoisture: number
    maxMoisture: number
    name: string
    updatedAt: string | null
    color: string
  }

  export type Crop = {
    cropConstantsCollection: CropConstant[]
    cropsWithCropVarieties: CropConstant[]
  }
}
const initialState = { cropConstantsCollection: [], cropsWithCropVarieties: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case c.CROP_CONSTANTS_FETCHED: {
      //@ts-expect-error
      return update(state, {
        cropConstantsCollection: { $set: action.payload },
      })
    }

    case c.CROPS_WITH_VARIETIES_FETCHED: {
      //@ts-expect-error
      return update(state, { cropsWithCropVarieties: { $set: action.payload } })
    }

    default:
      return state
  }
}
