//@ts-expect-error
import * as c from 'common/c'
import { ReactElement } from 'react'

declare module 'state/app.js' {
  export interface ApplicationStatus {
    busy: boolean
    state: symbol
    event: undefined | ReactElement
    modal: undefined | ReactElement
    popup: undefined | ReactElement
    view: string
  }
}

export default (
  state = {
    busy: false,
    state: c.APP_STATE.UNDEFINED,
    event: undefined,
    modal: undefined,
    popup: undefined,
    view: 'single',
  },
  action,
) => {
  switch (action.type) {
    // app state
    case c.AJAX_START:
      //@ts-expect-error
      return update(state, { busy: { $set: true } })
    case c.AJAX_STOP:
      //@ts-expect-error
      return update(state, { busy: { $set: false } })
    case c.STATE_SET:
      //@ts-expect-error
      return update(state, { state: { $set: action.payload } })

    // events
    case c.ADD_EVENT:
      //@ts-expect-error
      return update(state, { event: { $set: action.payload } })
    case c.CLEAR_EVENT:
      //@ts-expect-error
      return update(state, { event: { $set: undefined } })

    // modal
    case c.MODAL_SHOW: {
      const modal = {
        component: action.payload,
        props: action.meta,
      }

      //@ts-expect-error
      return update(state, { modal: { $set: modal } })
    }
    case c.MODAL_CLOSE:
      //@ts-expect-error
      return update(state, { modal: { $set: undefined } })

    case c.POPUP_SHOW: {
      const popup = {
        component: action.payload,
        props: action.meta,
      }

      //@ts-expect-error
      return update(state, { popup: { $set: popup } })
    }
    case c.POPUP_CLOSE:
      //@ts-expect-error
      return update(state, { popup: { $set: undefined } })

    case c.SPLIT_VIEW:
      //@ts-expect-error
      return update(state, { view: { $set: 'split' } })

    case c.SPLIT_SINGLE:
      //@ts-expect-error
      return update(state, { view: { $set: 'single' } })

    default:
      return state
  }
}
