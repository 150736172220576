import styled from 'styled-components'
import { H } from 'components/layout'
import {
  ArrowForwardIosTwoTone as ArrowForwardIcon,
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
  Clear as ClearIcon,
  SaveOutlined as SaveOutlinedIcon,
} from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import colors from 'common/colors'

function getStyledRootStyle(open) {
  return open ? selectedStyle : defaultStyle
}

const selectedStyle = {
  paddingTop: '8px',
  paddingBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#9ec7d2',
}

const defaultStyle = {
  paddingTop: '4px',
  paddingBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

export default function FieldHarvestRow(props) {
  const {
    title,
    onClick,
    open = false,
    children,
    onEditField,
    onDeleteField,
    edit = false,
    saveName,
    cancelEdit,
    fieldName,
    fieldId,
    farmId,
  } = props

  const { t } = useTranslation()
  const [newName, setNewName] = useState(fieldName)

  const enableButton = useCallback(() => {
    return !(newName != '' && newName != fieldName)
  }, [newName, fieldName])

  const handleInputChange = useCallback((e) => {
    const { value } = e.target
    setNewName(value)
  })

  const saveData = useCallback(() => {
    saveName(fieldId, newName, farmId)
  }, [newName])
  const handleCancel = useCallback((e) => {
    e.preventDefault()
    cancelEdit(e)
  })

  return (
    <>
      <StyledRoot sx={getStyledRootStyle(open)} onClick={onClick} id={props.id}>
        <StyledRoot
          sx={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}
        >
          <StyledItem
            sx={{
              overflow: 'hidden',
              flexGrow: 1,
              flexBasis: 0,
              flexShrink: 1,
              textOverflow: 'ellipsis',
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            {edit ? (
              <TextField
                value={newName}
                required
                size="small"
                name="name"
                sx={{
                  width: '15ch',
                }}
                onChange={(e) => handleInputChange(e)}
              />
            ) : (
              <StyledHeader open={open}>{title}</StyledHeader>
            )}
          </StyledItem>{' '}
          {open && edit ? (
            <StyledItem>
              <IconButton
                color="secondary"
                aria-label="save the item"
                disabled={enableButton()}
                onClick={(e) => saveData(e)}
                size="large"
              >
                <SaveOutlinedIcon
                  fontSize="small"
                  style={{ color: colors.brandLight }}
                />
              </IconButton>
              <IconButton
                color="error"
                aria-label={t('cancel')}
                onClick={(e) => handleCancel(e)}
                size="large"
              >
                <ClearIcon fontSize="small" style={{ color: '#B2023B' }} />
              </IconButton>
            </StyledItem>
          ) : (
            <StyledItem>
              <EditIcon
                size="small"
                sx={{ height: '0.9em' }}
                onClick={onEditField}
                titleAccess={t('edit_field')}
              />
              <DeleteIcon
                size="small"
                sx={{ height: '0.9em' }}
                titleAccess={t('delete_field')}
                onClick={onDeleteField}
              />
            </StyledItem>
          )}
          <ArrowForwardIcon sx={{ cursor: 'pointer' }} />
        </StyledRoot>
      </StyledRoot>
      {open ? <StyledContainer>{children}</StyledContainer> : null}
    </>
  )
}

const StyledRoot = styled(H)`
  margin: 3px;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`

const StyledItem = styled(H.Item)``

const StyledHeader = styled.h1`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin: 0;
`

const StyledContainer = styled.div`
  padding: 0;
`
