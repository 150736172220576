import { configureStore } from '@reduxjs/toolkit'
import reducers from './state'
import { middlewares } from './redux'

const store = configureStore({
  reducer: reducers,
  middleware: () => middlewares,
})

export default store
