import UserAccount from 'components/user-account'
import { fetchAccessibleAccounts, fetchInvitedUsers } from 'api/tenant'
import ImpersonateCheck from 'components/impersonate-check'

export default function UserAccounts() {
  const dispatch = useDispatch()
  const accounts = useSelector((state) => state.account.accessibleAccounts)
  const [impersonate, setImpersonate] = useState()
  const token = useSelector((state) => state.account['token'])

  useEffect(() => {
    setImpersonate(!!localStorage.getItem('impersonate'))
  }, [])

  const onUse = useCallback(() => {
    setImpersonate(true)
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(fetchAccessibleAccounts())
      dispatch(fetchInvitedUsers())
    }
  }, [token, impersonate])

  const el = useMemo(() => {
    return (
      <ImpersonateCheck showMessage={true}>
        <UserAccount farms={accounts} onUse={onUse} />
      </ImpersonateCheck>
    )
  }, [impersonate, accounts])

  return <>{el}</>
}
