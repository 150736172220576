import { ReactElement } from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

declare module 'components/layout/vertical.js' {
  export type VItem = {
    propTypes: {
      space: boolean
      displayName: string
      children: (React.Component | ReactElement | JSX.Element | null)[]
    }
  }
  export interface V {
    children: (React.Component | ReactElement | JSX.Element | null)[]
  }
}
export function V(props: {
  children: (React.Component | ReactElement | JSX.Element | null)[]
}) {
  const { children } = props

  return <StyledContainer {...props}>{children}</StyledContainer>
}

V.displayName = 'VerticalLayout'

//@ts-ignore
const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

export function VItem(props: {
  children: React.Component | ReactElement | null
  space: boolean
}) {
  const { children, space = false } = props
  return <StyledItem {...props}>{children}</StyledItem>
}

//@ts-ignore
VItem.propTypes = {
  space: PropTypes.bool,
}

//@ts-ignore
VItem.displayName = 'VerticalLayout.Item'

//@ts-ignore
const StyledItem = styled.div`
  & + & {
    margin-top: ${(props: { space: boolean }) => (props.space ? 0 : '8px')};
  }
`
