import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
//@ts-expect-error
import SearchField from 'components/search-field/index'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
//@ts-expect-error
import type { AppState } from 'state/index'
import { useSelector } from 'react-redux'

export default function UserAccountsComponent() {
  const userList = useSelector((state) => (state as AppState).account.userList)
  const [hoveredRow, setHoveredRow] = useState('')

  const [columns, setColumns] = useState<any[]>([])
  const [columnVisibility, setColumnVisibility] = useState({
    fullName: true,
    email: true,
    phoneNumber: true,
  })

  useEffect(() => {
    const _columns = [
      {
        field: 'fullName',
        headerName: t('name'),
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('email'),
        flex: 1,
      },
    ]
    setColumns(_columns)
  }, [hoveredRow])

  const onSearch = (value) => {
    setSearchValue(value)
  }

  const onMouseEnterRow = (event) => {
    setHoveredRow(event.currentTarget.dataset.id)
  }
  const onMouseLeaveRow = () => {
    setHoveredRow('')
  }

  const [searchValue, setSearchValue] = useState('')

  const filterOrganizations = (searchValue, organization) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()

      return (
        organization.tenantId.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        organization.organizationName?.toLowerCase().indexOf(lowerSearchValue) >
          -1 ||
        organization.fullName?.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        organization.email?.toLowerCase().indexOf(lowerSearchValue) > -1
      )
    }

    return true
  }

  function CustomNoRowsOverlayOrganizations() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {t<string>('no_users_found')}
      </div>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        height: '80%',
      }}
      width={1}
    >
      <h1>{t<string>('user_accounts')}</h1>
      <SearchField
        sx={{ width: '100%' }}
        name={searchValue}
        placeholder={t('search_by_name_email')}
        onSearch={onSearch}
      />
      <div
        style={{
          flexGrow: 1,
          height: '100%',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <DataGrid
          autoPageSize
          columnVisibilityModel={columnVisibility}
          initialState={{
            sorting: {
              sortModel: [{ field: 'fullName', sort: 'asc' }],
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlayOrganizations,
          }}
          slotProps={{
            row: {
              onMouseEnter: onMouseEnterRow,
              onMouseLeave: onMouseLeaveRow,
            },
          }}
          //@ts-ignore
          rows={
            Array.isArray(userList) &&
            userList
              .map((c) => ({
                ...c,
                id: c.id,
                tenantId: c.tenantId,
                fullName: c.fullName,
              }))
              .filter((c) => filterOrganizations(searchValue, c))
          }
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f8f8f9',
              textTransform: 'uppercase',
            },
          }}
          pageSizeOptions={[25, 50, 100]}
        />
      </div>
    </Box>
  )
}
