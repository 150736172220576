import styled from 'styled-components'
import { ReactElement } from 'react'

declare module 'components/modal/modal-actions.js' {
  export type ModalActionProps = {
    children: ReactElement<any, any>[] | ReactElement<any, any> | null
  }
}
export default function ModalActions(props: ModalActionProps) {
  return <StyledActions>{props.children}</StyledActions>
}
//@ts-ignore
const StyledActions = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 1em;
  & > * {
    margin-left: 1em;
  }
`
