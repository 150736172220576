import WelcomeScreen from 'components/new-wizard/welcome-screen'
import WizardStepper from 'components/new-wizard/wizard-stepper'
import AcceptEUSAScreen from 'components/new-wizard/eusa-accept'
import { BrowserRouter, Routes, Route } from 'react-router'

export default function RegistrationWizard({ partner }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/wizardStepper"
          element={<WizardStepper partner={partner} />}
        />
        <Route path="/welcomeScreen" element={<WelcomeScreen />} />
        <Route path="/" element={<AcceptEUSAScreen />} />
      </Routes>
    </BrowserRouter>
  )
}
