//@ts-expect-error
import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'
import update from 'immutability-helper'

declare module 'state/headers.js' {
  export type HeaderModel = {
    cutWidth: number
    equipmentModelId: number | null
    headerType: string | null
    id: string
    makeBrandId: number | null
    name: string
    year: number | null
    tenantId: string
    units: string
    createdAt: string
    updatedAt: string
  }

  export type Header = {
    collection: HeaderModel[]
    headerTypes: string[]
  }
}

const initialState = {
  collection: [],
  headerTypes: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.HEADERS_FETCHED: {
      //@ts-expect-error
      return update(state, { collection: { $set: action.payload } })
    }

    case c.ADD_HEADER: {
      //@ts-expect-error
      return update(state, { collection: { $unshift: [action.payload] } })
    }

    case c.DELETE_HEADER: {
      const idx = findIndex(
        get(state, 'collection', []),
        (header: HeaderModel) => header.id === action.payload,
      )
      if (idx > -1) {
        //@ts-expect-error
        return update(state, { collection: { $splice: [[idx, 1]] } })
      } else return
    }

    case c.EDIT_HEADER: {
      const index = findIndex(
        get(state, 'collection', []),
        (header: HeaderModel) => header.id === action.payload.id,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          collection: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }

    case c.HEADER_TYPES_FETCHED: {
      //@ts-expect-error
      return update(state, { headerTypes: { $set: action.payload } })
    }

    default:
      return state
  }
}
