import styled from 'styled-components'
import ManageUsers from 'components/manage-users'
import { showModal } from 'actions/app'
import { Box, Button } from '@mui/material'
import SearchField from 'components/search-field'
import { DataGrid } from '@mui/x-data-grid'
import { DeleteOutline as DeleteIcon } from '@mui/icons-material'
import { removeAccessibleAccount } from 'api/tenant'

export default function InvitedUsers({ users = [] }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')

  const columns = [
    {
      field: 'email',
      headerName: t('email'),
      minWidth: 500,
      flex: 1,
    },
    {
      field: 'role',
      headerName: t('role'),
      minWidth: 400,
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return <DeleteIcon onClick={() => onRemove(params.row)} />
      },
    },
  ]

  const onInviteClick = useCallback(() => {
    dispatch(showModal(<ManageUsers />, { title: t('invite_agpro') }))
  }, [])

  const onRemove = useCallback((user) => {
    const { tenantId, userId } = user
    dispatch(removeAccessibleAccount(tenantId, userId))
  }, [])

  const onSearch = useCallback(
    (value) => {
      setSearchValue(value)
    },
    [searchValue],
  )

  const filterUsers = (searchValue, user) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()

      return (
        user.email?.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        user.role?.toLowerCase().indexOf(lowerSearchValue) > -1
      )
    }

    return true
  }

  function CustomNoRowsOverlayUsers() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {t('no_users_found')}
      </div>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        height: '80%',
      }}
      width={1}
    >
      <h1>{t('agpro_users')}</h1>
      <SearchField
        sx={{ width: '100%' }} // TODO this doesn't work
        name={searchValue}
        placeholder={t('search')}
        onSearch={onSearch}
      />
      <div
        style={{
          flexGrow: 1,
          height: '100%',
          width: '100%',
          maxHeight: '800px',
          marginTop: '2rem',
        }}
      >
        <DataGrid
          autoPageSize
          slots={{
            noRowsOverlay: CustomNoRowsOverlayUsers,
          }}
          rows={users
            .map((u) => ({ ...u, id: u.email }))
            .filter((u) => filterUsers(searchValue, u))}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f8f8f9',
              textTransform: 'uppercase',
            },
          }}
          pageSizeOptions={[10, 25]}
        />
      </div>
      <StyledButton onClick={onInviteClick} variant="contained" color="primary">
        {t('invite')}
      </StyledButton>
    </Box>
  )
}

const StyledButton = styled(Button)`
  margin-top: 1em;
  width: fit-content;
`
