import styled from 'styled-components'
import { FormGroup } from './foundation'

const ReadonlyField = ({ defaultValue = '', label }) => {
  const labelEl = label ? <label>{label}</label> : null

  return (
    <Styled>
      {labelEl}
      <span>{defaultValue}</span>
    </Styled>
  )
}

ReadonlyField.displayName = 'ReadonlyField'

ReadonlyField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
}

export default ReadonlyField

const Styled = styled(FormGroup)`
  width: 100%;

  > span {
    display: block;
  }
`
