//@ts-expect-error
import * as c from 'common/c'
import { get } from 'lodash'
declare module 'state/brand.js' {
  export interface BrandEntry {
    id: number
    name: string
  }
  export interface Brand {
    brands: BrandEntry[]
  }
}
const initialState = { brands: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case c.FARMSETTINGS_COMBINE_BRAND_ADDED: {
      //@ts-expect-error
      return update(state, { brands: { $push: [action.payload] } })
    }

    case c.FARMSETTINGS_COMBINE_BRAND_UPDATED: {
      const { data } = action.payload
      const brands: BrandEntry[] = get(state, 'brands', [])
      let index = brands.findIndex((p) => p.id == data['id'])
      //@ts-expect-error
      return update(state, { ['brands']: { [index]: { $set: data } } })
    }

    case c.FARMSETTINGS_COMBINE_BRAND_DELETED: {
      const brands: BrandEntry[] = get(state, 'brands', [])
      let index = brands.findIndex((p) => p.id === action.payload.id)
      //@ts-expect-error
      return update(state, { ['brands']: { $splice: [[index, 1]] } })
    }

    case c.FARMSETTINGS_COMBINE_BRAND_FETCHED: {
      //@ts-expect-error
      return update(state, { brands: { $set: action.payload } })
    }

    default:
      return state
  }
}
