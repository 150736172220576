//@ts-expect-error
import * as c from 'common/c'
//@ts-expect-error
import type { JobData } from 'state/job'

export const createJob = (data: JobData) => {
  return {
    type: c.CREATE_JOB,
    payload: data,
  }
}

export const updateJob = (data: JobData) => {
  return {
    type: c.UPDATE_JOB,
    payload: data,
  }
}

export const removeJob = (jobId: string) => {
  return {
    type: c.REMOVE_JOB,
    payload: jobId,
  }
}
