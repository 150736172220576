import { createField } from 'api/field'
import { FormControlLabel, TextField } from '@mui/material'

import { isEmpty } from 'lodash-es'
import { useSelector } from 'react-redux'
import { closeModal } from 'actions/app'
import ModalTool from 'components/modal/modal-tool'

export default function EditMultipleFields(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { fields } = props

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })

  const selectedFarm = useSelector((state) => state.farm.singleton)
  const selectedClient = useSelector((state) => state?.client?.singleton)
  const farms = useSelector((state) => state.farm.collection || [])
  const clients = useSelector((state) => state?.client?.collection || [])
  const [farm, setFarm] = useState(selectedFarm)
  const [client, setClient] = useState(selectedClient)
  const [filteredFarms, setFilteredFarms] = useState(farms)

  const onSubmit = useCallback(() => {
    const data = []
    for (let i = 0; i < fields.length; i++) {
      data.push({ id: fields[i], farmId: farm.id })
    }
    dispatch(createField(data))
  }, [farm])

  const onChangeFarm = useCallback((e) => {
    setFarm(e.target.value)
  })

  const onChangeClient = useCallback(
    (e) => {
      const _client = e.target.value
      if (farm.clientId !== _client.id) {
        setFarm({})
      }
      const _filteredFarms = farms.filter((f) => f.clientId === _client.id)
      setFilteredFarms(_filteredFarms)
      setClient(_client)
    },
    [farm, farms],
  )

  return (
    <ModalTool
      header={t('edit_fields')}
      content={
        <>
          {clients && clients.length > 1 ? (
            <FormControlLabel
              sx={{ alignItems: 'center' }}
              control={
                <TextField
                  sx={{ width: '25ch', padding: '10px' }}
                  select
                  name={'client'}
                  value={client}
                  onChange={onChangeClient}
                  slotProps={{ select: { native: true } }}
                  variant="outlined"
                  size="small"
                >
                  {clients.map((innerItem) => (
                    <option value={innerItem} key={innerItem.id}>
                      {innerItem.name}
                    </option>
                  ))}
                </TextField>
              }
              label={t('client')}
              labelPlacement="start"
            />
          ) : null}
          {filteredFarms && filteredFarms.length > 1 ? (
            <FormControlLabel
              sx={{ alignItems: 'center' }}
              control={
                <TextField
                  sx={{ width: '25ch', padding: '10px' }}
                  select
                  name={'farm'}
                  value={farm}
                  onChange={onChangeFarm}
                  slotProps={{ select: { native: true } }}
                  variant="outlined"
                  size="small"
                >
                  {filteredFarms.map((innerItem) => (
                    <option value={innerItem} key={innerItem.id}>
                      {innerItem.name}
                    </option>
                  ))}
                </TextField>
              }
              label={t('farm')}
              labelPlacement="start"
            />
          ) : null}
        </>
      }
      onClose={closePopup}
      onApply={onSubmit}
      disabled={isEmpty(client) || isEmpty(farm)}
    />
  )
}
