//@ts-expect-error
import http from 'common/http'
import {
  notificationsFetched,
  notificationsRead,
  notificationsDismissed,
  notificationsAdded,
  //@ts-expect-error
} from 'actions/notification'

export const fetchNotifications = () => {
  return (dispatch) => {
    return http.get(`/notification`).then((res) => {
      dispatch(notificationsFetched(res.data))
    })
  }
}

export const readNotifications = (ids) => {
  return (dispatch) => {
    return http.post(`/notification/read`, ids).then((res) => {
      dispatch(notificationsRead(res.data))
    })
  }
}

export const dismissNotifications = (ids) => {
  return (dispatch) => {
    return http.post(`/notification/dismiss`, ids).then((res) => {
      dispatch(notificationsDismissed(res.data))
    })
  }
}
