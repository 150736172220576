//@ts-expect-error
import { closeModal } from 'actions/app'
//@ts-expect-error
import ModalTool from 'components/modal/modal-tool'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCallback, ReactElement } from 'react'
//@ts-expect-error
import { editTenantPreferences } from 'api/tenant'

interface EnableCFFConfirmationProps {
  onResult: (result: 'apply' | 'close') => void
}

export default function EnableCFFConfirmation({
  onResult,
}: EnableCFFConfirmationProps): ReactElement<any, any> {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const closePopup = useCallback(() => {
    dispatch(closeModal())
    onResult('close')
  }, [dispatch, onResult])

  const onEnableCFF = useCallback(() => {
    dispatch(
      editTenantPreferences({
        path: 'cff.enabled',
        value: true,
      }),
    )
    dispatch(closeModal())
    onResult('apply')
  }, [dispatch, onResult])

  return (
    <ModalTool
      header={t('cff_not_enabled')}
      content={
        <>
          <p>{t('do_you_want_to_enable_cff')}</p>
        </>
      }
      onClose={closePopup}
      onApply={onEnableCFF}
      applyTranslationKey="yes"
      closeTranslationKey="no"
    />
  )
}
