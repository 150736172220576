import styled from 'styled-components'
//@ts-expect-error
import ManageIntegrationComponent from 'components/manage-integrations/index'
//@ts-expect-error
import { showModal } from 'actions/app'
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material'
//@ts-expect-error
import SearchField from 'components/search-field/index'
import { DataGrid } from '@mui/x-data-grid'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import {
  createIntegration,
  linkIntegration,
  requestIntegrationCode,
  //@ts-expect-error
} from 'api/integration'
//@ts-expect-error
import { setIntegrationUrls } from 'actions/integrations'
//@ts-expect-error
import * as c from 'common/c'
//@ts-expect-error
import { getTranslatedName, isTokenValid } from 'common/misc'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState, useCallback, ReactElement, useEffect } from 'react'
//@ts-expect-error
import ItemSwitch from 'components/layers-group/item-switch'
import { useNavigate } from 'react-router'

//@ts-expect-error
import { getCurrentSubscription } from 'api/subscriptions'
//React/other utilities
import { cloneDeep, isEmpty } from 'lodash-es'
//Types
//@ts-expect-error
import type { TenantIntegration } from 'state/integrations'
//@ts-expect-error
import type { IntegrationUrls, IntegrationRef } from 'state/integrations'
import { SubscriptionsOutlined as SubscriptionsIcon } from '@mui/icons-material'
//@ts-expect-error
import { AppState } from 'state/index'
import JDLogo from 'images/JDOC-tm-black.svg'
import TrimbleLogo from 'images/Trimble-logo.png'
//@ts-expect-error
import EnableCFFConfirmation from './enableCffConfirmation'

export default function Integrations({
  availableIntegrations = [],
  enabledIntegrations = [],
}): ReactElement<any, any> {
  const baseURL: string = window.location.origin
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const navigate = useNavigate()
  const urls: {
    [key: string]: {
      IntegrationRef
      authenticated: boolean
      status: number
      availableConnections?: IntegrationUrls[]
    }
  } = useSelector((state: AppState) => state.integrations.integrationUrls)

  const cffPreferences = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff,
  )

  const currentSubscription = useSelector(
    (state: AppState) => state.account.currentSubscription,
  )
  const permissions = useSelector(
    (state: AppState) => state.account.permissions,
  )
  const customClaims = useSelector(
    (state: AppState) => state.account.customClaims,
  )
  const isAdmin =
    permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM]
  const isPartner =
    permissions.includes('partner:admin') || customClaims?.[c.FT_PARTNER_CLAIM]

  const [validSubscription, setValidSubscription] = useState(false)
  const [linkableOrgs, setLinkableOrgs] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState<{
    [key: string]: IntegrationUrls
  }>({})
  useEffect(() => {
    if (isEmpty(currentSubscription)) {
      dispatch(getCurrentSubscription())
    }
    const _validSubscription =
      currentSubscription?.subscriptionStatus == 'free_year' ||
      currentSubscription?.subscriptionStatus === 'paid' ||
      currentSubscription?.subscriptionStatus === 'comped'
    setValidSubscription(_validSubscription)
  }, [currentSubscription])

  const [selectedRowData, setSelectedRowData] = useState(null)

  useEffect(() => {
    let _selectedOrgs: { [key: string]: IntegrationUrls } = {}
    if (!isEmpty(urls)) {
      for (let key in urls) {
        const availableConnections = urls[key]?.availableConnections || []
        if (availableConnections.length > 0) {
          setLinkableOrgs(true)
          _selectedOrgs[key] = availableConnections[0]
        }
      }
      setSelectedOrg(_selectedOrgs)
    }
  }, [urls])

  const onConnectOrg = useCallback(
    (row) => {
      if (!isEmpty(selectedOrg)) {
        const customerId: string = selectedOrg[row.id].id
        const request = {
          integrationId: row.id,
          customerId,
          name: selectedOrg.name,
          status: c.ENABLED,
        }
        //@ts-ignore
        dispatch(createIntegration(request)).then((res) => {
          if (res.message === 'Integration Linked') {
            dispatch(
              setIntegrationUrls({
                [row.id]: {
                  [customerId]: selectedOrg[row.id],
                  authenticated: urls[row.id].authenticated,
                  status: urls[row.id].status,
                },
              }),
            )
          }
        })
      }
    },
    [selectedOrg],
  )

  const onChangeOrg = useCallback(
    (newValue, integrationId) => {
      let _selectedOrgs = cloneDeep(selectedOrg)
      _selectedOrgs[integrationId] = newValue
      setSelectedOrg(_selectedOrgs)
    },
    [selectedOrg],
  )

  const columns = [
    {
      field: 'name',
      headerName: t('product'),
      minWidth: 400,
      flex: 1,
      renderCell: (params) => {
        //TODO: Update integration table to store if an integration is in beta or not.
        //Maybe add to tenant if they are participants in beta testing
        switch (params.row.name) {
          case 'operations_center':
            return <img src={JDLogo} width="205px" />
          case 'trimble_ag':
            return (
              <img
                style={{ marginLeft: '19px' }}
                src={TrimbleLogo}
                height="75px"
                width="200px"
              />
            )
          default:
            return getTranslatedName(params.row.name) + ` ${t('beta')}`
        }
      },
    },
    {
      field: 'status',
      headerName: t('status'),
      minWidth: 400,
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const activeIntegration = enabledIntegrations.filter(
          (integration: TenantIntegration) =>
            integration?.integrationId === params.row.id &&
            integration?.status == c.ENABLED,
        )
        const token: string = localStorage.getItem(params.row.name) || ''
        const parsedToken = token === '' ? undefined : JSON.parse(token)
        const tokenValid: boolean = isTokenValid(parsedToken?.accessToken)
        const id = params.row.id
        const authenticated = (urls?.[id]?.authenticated && tokenValid) || false
        const availableConnections = urls?.[id]?.availableConnections || []
        if (activeIntegration.length > 0) {
          return (
            <StyledButton
              onClick={() => onManageIntegration(params.row, activeIntegration)}
              variant="contained"
              color="primary"
              disabled={!validSubscription || isAdmin || isPartner}
            >
              {authenticated ? t('manage') : t('login')}
            </StyledButton>
          )
        } else if (availableConnections.length > 0) {
          return (
            <>
              <Autocomplete
                disableClearable
                disabled={!validSubscription}
                value={selectedOrg[params.row.id] || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                style={{
                  maxHeight: '40px',
                  minWidth: '120px',
                  width: '75%',
                  marginRight: '10px',
                }}
                sx={{
                  '& input': {
                    height: '1em',
                  },
                  '& label': {
                    lineHeight: '1.5em',
                    fontSize: '0.75em',
                  },
                }}
                id={`set-all-farmtrx-clients`}
                autoHighlight
                size="small"
                options={availableConnections}
                defaultValue={availableConnections[0]}
                selectOnFocus
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, newValue, reason) => {
                  onChangeOrg(newValue, params.row.id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('select_client')}
                    label={t('farmtrx-client')}
                    style={{ width: '100%' }}
                  />
                )}
              />
              <StyledButton
                onClick={() => onConnectOrg(params.row)}
                variant="contained"
                color="primary"
                disabled={!validSubscription || isEmpty(selectedOrg)}
              >
                {t('connect')}
              </StyledButton>
            </>
          )
        }
        return (
          <StyledButton
            onClick={() => {
              setSelectedRowData(params.row)
            }}
            variant="contained"
            color="primary"
            disabled={
              !validSubscription ||
              enabledIntegrations.length > 0 ||
              isAdmin ||
              isPartner ||
              linkableOrgs
            }
          >
            {t('activate')}
          </StyledButton>
        )
      },
    },
  ]

  const filterIntegrations = (searchValue, integration) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()

      return integration.name?.toLowerCase().indexOf(lowerSearchValue) > -1
    }

    return true
  }

  const handleEnableCffShowModal = () => {
    dispatch(
      showModal(
        <EnableCFFConfirmation onResult={handleEnableCffShowModalResult} />,
      ),
    )
  }

  const handleEnableCffShowModalResult = useCallback(
    (result: 'apply' | 'close') => {
      if (result == 'apply') {
        redirectToIntegration(selectedRowData)
      } else {
        setSelectedRowData(null)
      }
    },
    [selectedRowData],
  )

  useEffect(() => {
    if (selectedRowData) {
      onActivateIntegration(selectedRowData)
    }
  }, [selectedRowData])

  const onActivateIntegration = useCallback(
    (rowData) => {
      if (cffPreferences === undefined || cffPreferences.enabled === false) {
        handleEnableCffShowModal()
        return
      } else {
        redirectToIntegration(rowData)
      }
    },
    [selectedRowData],
  )

  const redirectToIntegration = (rowData) => {
    //Call api endpoint to activate the integration.
    if (selectedRowData === null) {
      return
    }
    rowData.attempt = 1
    rowData.doOnboarding = true
    rowData.redirectUrl = baseURL
    localStorage.setItem('integrationLoginAttempt', JSON.stringify(rowData))
    requestIntegrationCode(
      rowData.authorizationUrl,
      rowData.scopes,
      rowData.clientId,
      rowData.redirectUrl,
      rowData.tokenUrl,
    )
  }

  const onManageIntegration = useCallback((data, activeIntegration) => {
    const token: string = localStorage.getItem(data.name) || ''
    const parsedToken = token === '' ? undefined : JSON.parse(token)
    const tokenValid: boolean = isTokenValid(parsedToken?.accessToken)
    if (parsedToken?.accessToken && tokenValid) {
      const translatedName = getTranslatedName(data.name)
      dispatch(
        showModal(
          <ManageIntegrationComponent
            token={parsedToken?.accessToken}
            integrationId={data.id}
            enabledClients={activeIntegration}
            name={data.name}
          />,
          {
            title: t('manage_integration', {
              integration_name: translatedName,
            }),
          },
        ),
      )
    } else {
      data.attempt = 1
      data.doOnboarding = true
      data.redirectUrl = baseURL
      localStorage.setItem('integrationLoginAttempt', JSON.stringify(data))
      requestIntegrationCode(
        data.authorizationUrl,
        data.scopes,
        data.clientId,
        data.redirectUrl,
        data.tokenUrl,
      )
    }
  }, [])

  const onSearch = useCallback(
    (value) => {
      setSearchValue(value)
    },
    [searchValue],
  )

  function CustomNoRowsOverlayUsers() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {t('no_integrations_found')}
      </div>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        height: '100%',
      }}
      width={1}
    >
      <h1>{t('third_party_integrations')}</h1>
      <Typography
        variant="h5"
        sx={{
          padding: 1,
          fontWeight: 500,
          fontSize: 'medium',
          color: '#439313',
        }}
      >
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1, ml: 1 }}
        >
          <InfoOutlinedIcon
            sx={{
              '& path': {
                fill: '#439313',
              },
            }}
          />
        </IconButton>
        {t('one_third_party_integration_allowed')}
      </Typography>
      {/* <SearchField
        sx={{ width: '100%' }} // TODO: this doesn't work
        name={searchValue}
        placeholder={t('search')}
        onSearch={onSearch}
      /> */}
      {!validSubscription ? (
        <ItemSwitch
          sx={{
            borderLeft: '8px solid #ff00006b !important',
            background: '#ff00001a !important',
            color: 'red !important',
            fontWeight: 'bold !important',
          }}
          title={t('no_subscriptions_activate_manage')}
          onClick={() => navigate('/admin/subscription')}
        >
          <span> {t('renew_subscription')}</span>
          {
            //@ts-ignore
            <SubscriptionsIcon
              size="small"
              style={{
                color: '#B2023B',
                height: '0.7em',
                verticalAlign: 'sub',
              }}
              titleAccess={t('no_subscriptions_activate_manage')}
            />
          }
        </ItemSwitch>
      ) : null}
      <div
        style={{
          flexGrow: 1,
          height: '100%',
          width: '100%',
          maxHeight: '800px',
          marginTop: '2rem',
        }}
      >
        <DataGrid
          autoPageSize
          slots={{
            noRowsOverlay: CustomNoRowsOverlayUsers,
          }}
          rows={availableIntegrations
            .map((i) => {
              return i
            })
            .filter((i) => filterIntegrations(searchValue, i))}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f8f8f9',
              textTransform: 'uppercase',
            },
          }}
          pageSizeOptions={[10]}
          rowHeight={75}
        />
      </div>
    </Box>
  )
}
//@ts-ignore
const StyledButton = styled(Button)`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: fit-content;
`
