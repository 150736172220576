import http from 'common/http'
import {
  cropVarietiesForTenantFetched,
  removeCropVariety,
  updateCropVariety,
  removeEmptyCropVariety,
  addEmptyCropVariety,
  setLocalCropVarietiesObj,
} from 'actions/crop-variety'
import { addEvent, shouldShowFarmSetupWizard } from 'actions/app'

export const getCropVarieties = () => {
  return (dispatch) => {
    return http.get('/crop-variety').then((res) => {
      if (Array.isArray(res.data) && res.data.length === 0) {
        dispatch(shouldShowFarmSetupWizard())
      }

      // TODO: uncomment line below to get wizard to show
      // dispatch(shouldShowFarmSetupWizard())
      dispatch(cropVarietiesForTenantFetched(res.data))
    })
  }
}

export const deleteCropVariety = (id) => {
  return (dispatch) => {
    return http.delete(`/crop-variety/${id}`).then(() => {
      dispatch(removeCropVariety(id))
    })
  }
}

export const configureCropVarieties = (data) => {
  return (dispatch) => {
    return http
      .post('/crop-variety/configure', data)
      .then((res) => {
        dispatch(cropVarietiesForTenantFetched(res.data))
      })
      .catch((error) => {
        if (
          error.response.status === 500 ||
          error.response.statusText === 'Validation Error'
        ) {
          dispatch(
            addEvent('An error occurred when configuring the crops.', 'Error'),
          )
        }
      })
  }
}

export const addCropVariety = (data) => {
  return (dispatch) => {
    return http.post('/crop-variety', data).then((res) => {
      dispatch(addEmptyCropVariety(res.data))
    })
  }
}

export const editCropVariety = (id, data) => {
  return (dispatch) => {
    return http.put(`/crop-variety/${id}`, data).then(() => {
      dispatch(updateCropVariety(data))
    })
  }
}

export const deleteEmptyCropVarietyObj = () => {
  return (dispatch) => {
    dispatch(removeEmptyCropVariety())
  }
}

export const addEmptyCropVarietyObj = (data) => {
  return (dispatch) => {
    dispatch(addEmptyCropVariety(data))
  }
}

export const editOtherValues = (data) => {
  return (dispatch) => {
    dispatch(updateCropVariety(data))
  }
}

export const setLocalCropVarieties = (data) => {
  return (dispatch) => {
    dispatch(setLocalCropVarietiesObj(data))
  }
}
