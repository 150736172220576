//@ts-expect-error
import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'

declare module 'state/crop-variety.js' {
  export type CropVarietyModel = {
    createdAt: string
    cropId: number
    id: string
    manufacturer: string
    mcMarket: number
    name: string
    tenantId: string
    testWeightKgHl: number
    updatedAt: string
    type?: string
  }

  export interface CropVariety {
    collection: CropVarietyModel[]
    defaultVariety: undefined | CropVarietyModel
    //Why does namesCollection exist?
    namesCollection: string[]
    //Why does localStore exist?
    localStore: CropVarietyModel[]
  }
}

const initialState = {
  collection: [],
  defaultVariety: undefined,
  namesCollection: [],
  localStore: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.CROP_VARIETIES_NAMES_FETCHED: {
      //@ts-expect-error
      return update(state, { namesCollection: { $set: action.payload } })
    }

    case c.CROP_VARIETY_ADDED: {
      //@ts-expect-error
      return update(state, { collection: { $unshift: [action.payload] } })
    }

    case c.TENANT_CROP_VARIETIES_FETCHED: {
      //@ts-expect-error
      return update(state, { collection: { $set: action.payload } })
    }

    case c.DELETE_CROP_VARIETY: {
      const varieties: CropVarietyModel[] = get(state, 'collection', [])
      const idx = findIndex(
        varieties,
        (variety) => variety.id === action.payload,
      )
      if (idx > -1) {
        //@ts-expect-error
        return update(state, { collection: { $splice: [[idx, 1]] } })
      } else return
    }

    case c.UPDATE_CROP_VARIETY: {
      const varieties: CropVarietyModel[] = get(state, 'collection', [])
      const index = findIndex(
        varieties,
        (variety) => variety.id === action.payload.id,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          collection: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }

    case c.SET_LOCAL_CROP_VARIETIES: {
      //@ts-expect-error
      return update(state, { localStore: { $set: action.payload } })
    }

    case c.DELETE_EMPTY_CROP_VARIETY: {
      const varieties: CropVarietyModel[] = get(state, 'collection', [])
      const idx = findIndex(varieties, (variety) => variety.type === 'temp')
      if (idx > -1) {
        //@ts-expect-error
        return update(state, { collection: { $splice: [[idx, 1]] } })
      } else return
    }

    default:
      return state
  }
}
