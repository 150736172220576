//@ts-expect-error
import useMap from './use-map'
import { isUndefined } from 'lodash-es'

export default function useZoom() {
  const map = useMap()

  const [zoom, setZoom] = useState()

  // on center change
  useEffect(() => {
    if (!map) return

    const mapView = map.getView()

    if (isUndefined(zoom)) {
      setZoom(mapView.getZoom())
    }

    function onResolution(e) {
      setZoom(mapView.getZoomForResolution(e.target.get(e.key)))
    }

    mapView.on('change:resolution', onResolution)

    return () => {
      mapView.un('change:resolution', onResolution)
    }
  }, [map])

  return zoom
}
