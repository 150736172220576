/**
 * UI Kit Foundation
 *
 * 1. Colors
 * 1. Parameters
 * 1. Position
 * 1. Links
 * 1. Lists
 * 1. Controls
 */

import styled, { css } from 'styled-components'
import { Link as ReactRouterLink } from 'react-router'
import colors from 'common/colors'

// 1. Colors
export { default as colors } from 'common/colors'

// 1. Parameters
export const pointer = css`
  cursor: pointer;
  cursor: hand;
`

export const center = css`
  display: block;
  margin: 0 auto;
`

export const defaultShadow = css`
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 5px 0 rgba(19, 72, 137, 0.1),
    0 0 0 0 rgba(48, 48, 48, 0.03);
`

export const defaultBackground = css`
  background: white;
`

// 1. Position
export const deadCenter = css`
  display: block;
  margin: 8px auto;
`

// 1. Links
export const Link = styled(ReactRouterLink).attrs({ as: 'button' })`
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: inline;
  color: ${colors.brand};
  margin: 0;
  padding: 0;
  ${pointer}

  &:hover {
    color: ${colors.brandLight};
  }
`

export const BlankLink = styled.span`
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: inline;
  color: ${colors.brand};
  margin: 0;
  padding: 0;
  ${pointer}

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${colors.brandLight};
  }
`

export const CallToAction = styled(Link)`
  color: ${colors.brandSecondary};
  ${({ center }) => (center === true ? deadCenter : 'margin: 8px 0')};

  &:hover {
    color: ${colors.brandSecondaryLight};
  }
`

// 1. Lists
export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`

List.Item = styled.li`
  padding-left: 8px;
  margin-bottom: 8px;
  & + & {
    margin-top: 6px;
  }
`

export const Muted = styled.span`
  color: ${colors.textMuted};
  font-size: 12px;
`

// 1. Controls
const formControl = css`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`

const formControlFocus = css`
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 1px rgb(0 123 255 / 25%);
`

export const Select = styled.select`
  ${formControl}

  &:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }

  &:focus {
    ${formControlFocus}
  }
`

Select.Option = styled.option``

export const InputText = styled.input`
  ${formControl}

  &:focus {
    ${formControlFocus}
  }
`
