import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'
import { Box, Button, Grid2 } from '@mui/material'
import Gravatar from 'react-gravatar'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { editTenant } from 'api/tenant'
import { showModal } from 'actions/app'
import UserPreference from './user-preference'

export default function UserDetails({ onScroll }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.account['singleton'])
  const [editing, setEditing] = useState(false)
  const [formFields, setFormFields] = useState({
    fullName: user.fullName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    streetAddress: user.streetAddress,
    postalCode: user.postalCode,
  })

  const { t } = useTranslation()

  const fields = [
    { label: t('full_name'), fieldName: 'fullName' },
    { label: t('email'), fieldName: 'email' },
    { label: t('phone_number'), fieldName: 'phoneNumber' },
    { label: t('street_address'), fieldName: 'streetAddress' },
    { label: t('postal_code'), fieldName: 'postalCode' },
  ]

  const handleInputChange = (e) => {
    if (e) {
      let { name, value } = e.target
      setFormFields((formFields) => ({
        ...formFields,
        [name]: value,
      }))
    }
  }

  const saveInput = () => {
    dispatch(
      editTenant({
        fullName: formFields.fullName,
        phoneNumber: formFields.phoneNumber,
        streetAddress: formFields.streetAddress,
        postalCode: formFields.postalCode,
      }),
    ).then(() => {
      dispatch(showModal(<UserDetails />))
    })
  }

  const openModifyPrefs = () => {
    dispatch(showModal(<UserPreference />))
  }

  return (
    // </>
    (<Box
      px={3}
      py={4}
      sx={{
        display: 'flex',
        maxHeight: '70vh',
        margin: 'auto',
        overflowY: 'auto',
        padding: '2rem',
        maxWidth: '60vw',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      onScroll={onScroll}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Gravatar
          email={formFields.email}
          size={100}
          default="identicon"
          protocol="https://"
        />
        <br />

        {editing ? (
          <div>
            {fields.map(({ label, fieldName }) => (
              <TextField
                key={fieldName}
                fullWidth
                disabled={fieldName === 'email'}
                label={label}
                type={
                  fieldName === 'email'
                    ? 'email'
                    : fieldName === 'phoneNumber'
                      ? 'tel'
                      : 'text'
                }
                margin="normal"
                name={fieldName}
                variant="outlined"
                defaultValue={formFields[fieldName]}
                onChange={handleInputChange}
                sx={{ width: '100%' }}
              />
            ))}
            <Box textAlign={'center'}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => {
                  saveInput()
                  setEditing(false)
                }}
                sx={{ marginTop: '1rem' }}
              >
                {t('save_profile')}
              </Button>
            </Box>
          </div>
        ) : (
          <Grid2 container alignItems="center" spacing={1}>
            <Grid2 size={12}>
              <Typography align={'center'} paragraph variant="h4">
                <b>{formFields.fullName}</b>
              </Typography>
            </Grid2>
            <Grid2 size={4}>
              <StyledText paragraph>{t('email')}</StyledText>
            </Grid2>
            <Grid2 size={8}>
              <StyledInputText paragraph>{formFields.email}</StyledInputText>
            </Grid2>
            <Grid2 size={4}>
              <StyledText paragraph>{t('phone_number')}</StyledText>
            </Grid2>
            <Grid2 size={8}>
              <StyledInputText paragraph>
                {formFields.phoneNumber
                  ? formFields.phoneNumber
                  : 'No phone number'}
              </StyledInputText>
            </Grid2>
            <Grid2 size={4}>
              <StyledText paragraph>{t('address')}</StyledText>
            </Grid2>
            <Grid2 size={8}>
              <StyledInputText paragraph>
                {formFields.streetAddress
                  ? formFields.streetAddress
                  : 'No address added'}
              </StyledInputText>
            </Grid2>
            <Grid2 size={4}>
              <StyledText paragraph>{t('postal_code')}</StyledText>
            </Grid2>
            <Grid2 size={8}>
              <StyledInputText paragraph>
                {formFields.postalCode
                  ? formFields.postalCode
                  : 'No postal code added'}
              </StyledInputText>
            </Grid2>
            <Grid2 size={12} align={'center'}>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setEditing(true)}
                size="large"
              >
                {t('edit_profile')}
              </Button>
            </Grid2>
          </Grid2>
        )}
        <br />
      </div>
      <Grid2 size={12} align={'center'}>
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => openModifyPrefs(true)}
          size="large"
        >
          {t('modify_notification_preferences')}
        </Button>
      </Grid2>
    </Box>)
  );
}

const StyledInputText = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Roboto';
`

const StyledText = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Roboto';
`
