import { useAuth0 } from '@auth0/auth0-react'
import Gravatar from 'react-gravatar'
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import styled from 'styled-components'
import EUSAScreen from 'components/eusa-screen'
import ScreenSizeWarning from 'components/screen-size-warning'
import { showModal } from 'actions/app'
import * as c from 'common/c'
import UserDetails from 'components/user-details'
import { clearIntegrationData } from 'common/integrationUtils'
import { FarmtrxIconLogout } from '@troo/farmtrx-icons'

export default function Logout() {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const email = useSelector((state) => state.account.singleton.email ?? '')
  const avatar = (
    <StyledAvatar>
      <Gravatar
        email={email}
        size={25}
        default="identicon"
        protocol="https://"
      />
    </StyledAvatar>
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    if (localStorage.getItem('impersonate')) {
      const data = JSON.parse(
        localStorage.getItem(
          `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email offline_access`,
        ),
      )
      const otok = localStorage.getItem('otok')
      const updateData = update(data, {
        body: { access_token: { $set: otok } },
      })
      localStorage.setItem(
        `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email offline_access`,
        JSON.stringify(updateData),
      )
      localStorage.removeItem('impersonate')
      localStorage.removeItem('visitingFarmName')
    }
    clearIntegrationData()
    //Remove the CFF localStorage items
    localStorage.removeItem('selectedClient')
    localStorage.removeItem('selectedFarms')
    //End
    logout({ returnTo: window.location.origin })
  }

  const onShowEusa = () => {
    dispatch(showModal(<EUSAScreen />))
  }

  const onUserDetails = () => {
    dispatch(showModal(<UserDetails />))
  }

  const onShowScreenSizeWarning = () => {
    const dismissedWarning = localStorage.getItem(c.WARNING_CLEARED_KEY)
    if (
      (window.screen.width < c.MIN_SCREEN_WIDTH ||
        window.screen.height < c.MIN_SCREEN_HEIGHT) &&
      !dismissedWarning
    ) {
      dispatch(showModal(<ScreenSizeWarning />))
    }
  }

  // const onHelp = () => {
  //   return window.open(`${HELPGUIDE_URL}`, '_blank')
  // }

  return (
    <>
      <Tooltip
        title={t('account')}
        onLoad={onShowScreenSizeWarning}
        disableInteractive
      >
        <IconButton size="small" onClick={handleClick} sx={{ ml: 2 }}>
          {avatar}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 8,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onUserDetails}>
          {avatar} {email}
        </MenuItem>
        <Divider />
        <MenuItem onClick={onShowEusa}>{t('show_eusa')}</MenuItem>
        <Divider />
        {/* <MenuItem onClick={onHelp}>
          <ListItemIcon>
            <OpenInNewIcon fontSize="small" />
          </ListItemIcon>
          {t('help')}
        </MenuItem>
        <Divider /> */}
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <FarmtrxIconLogout title={t('logout')} />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

const StyledAvatar = styled(Avatar)`
  width: 25px;
  height: 25px;
`
