import PopupInternal from './popup-internal.tsx'

export default function Popup(props) {
  const { component, onClose, title, pixel } = props

  const popupEl = (
    <PopupInternal
      key={pixel.x + pixel.y}
      onClose={onClose}
      title={title}
      pixel={pixel}
    >
      {component}
    </PopupInternal>
  )

  return ReactDOM.createPortal(popupEl, document.getElementById('popup-el'))
}

Popup.propTypes = {
  component: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  pixel: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}
