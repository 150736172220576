import { Box, TextField, InputAdornment, IconButton } from '@mui/material'
import {
  Search as SearchIcon,
  Add as AddIcon,
  Edit as EditIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material'
import { showModal } from 'actions/app'
import { get, noop, isEmpty } from 'lodash-es'
import FieldForm from 'components/field-form'
import { DataGrid } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import { convertAreaToPref } from 'common/unitConvert'
import useDebounce from 'hooks/util/debounce'
import EditMultipleFields from './edit-multiple-fields'
import OCFFDeleteField from './delete-field.tsx'

export default function OCFFieldsComponent() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const fields = useSelector((state) => state?.field?.collection)
  const farms = useSelector((state) => state?.farm?.collection || [])
  const clients = useSelector((state) => state?.client?.collection || [])
  const [farmDataMap, setFarmDataMap] = useState({})
  const [fieldList, setFieldList] = useState([])
  const [filteredFieldList, setFilteredFieldList] = useState([])
  const userPrefs = useSelector((state) => state.account.singleton.prefs)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const permissions = useSelector((state) => get(state, 'account.permissions'))

  const showFields = permissions.includes('farm:read')
  const addModifyFields = permissions.includes('farm:write')

  const invokeDebouncedSearch = useDebounce(() => {
    if (isEmpty(searchValue) && fieldList.length > 0) {
      setFilteredFieldList(fieldList)
    } else {
      const filter = searchValue.toLowerCase()
      setFilteredFieldList(
        fieldList.filter((f) => f.name.toLowerCase().includes(filter)),
      )
    }
  }, 250)
  useEffect(invokeDebouncedSearch, [fieldList, searchValue])

  const [columns, setColumns] = useState([])

  const [columnVisibility, setColumnVisibility] = useState({
    farmName: true,
    fieldName: true,
    clientName: true,
  })

  useEffect(() => {
    const _farmMap = {}

    for (let i = 0; i < farms.length; i++) {
      const farm = farms[i]
      const client = clients.find((c) => c.id === farm.clientId)
      _farmMap[farm.id] = {
        name: farm.name,
        clientId: farm.clientId,
        clientName: client?.name,
      }
    }
    setFarmDataMap(_farmMap)
  }, [farms, clients])

  useEffect(() => {
    const _fieldList = []
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const farmData = farmDataMap[field.farmId]
      _fieldList.push({
        id: field.id,
        name: field.name,
        farmId: field.farmId,
        farmName: farmData?.name,
        clientId: farmData?.clientId,
        clientName: farmData?.clientName,
        fieldArea: `${Math.floor(
          convertAreaToPref(field.areaSqm, userPrefs['units']['area_in']),
        )} ${userPrefs['units']['area_in']}`,
        boundarySvg: field.boundarySvg,
      })
    }
    setFieldList(_fieldList)
  }, [fields, farmDataMap])

  const onSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const filterFields = (searchValue, field) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()

      return field.name.toLowerCase().indexOf(lowerSearchValue) > -1
    }

    return true
  }

  const onCreateField = useCallback(() => {
    dispatch(showModal(<FieldForm />))
  }, [])

  const onEditField = useCallback(() => {
    if (rowSelectionModel.length === 1) {
      const fieldToEdit = fields.find((f) => f.id === rowSelectionModel[0])
      dispatch(
        showModal(
          <FieldForm
            defaultClient={farmDataMap[fieldToEdit.farmId].clientId}
            defaultFarm={fieldToEdit.farmId}
            fieldData={fieldToEdit}
          />,
        ),
      )
    } else {
      dispatch(showModal(<EditMultipleFields fields={rowSelectionModel} />))
    }
  }, [rowSelectionModel, fieldList, farmDataMap])

  useEffect(() => {
    const _columns = [
      {
        field: 'boundarySvg',
        headerName: '',
        minWidth: 50,
        maxWidth: 50,
        flex: 1,
        renderCell: (params) => {
          if (params.value !== '') {
            const svg = new Blob([params.value], { type: 'image/svg+xml' })
            const url = URL.createObjectURL(svg)
            return (
              <div>
                <img src={url} height="40" width="40" />
              </div>
            )
          } else {
            return <div />
          }
        },
      },
      {
        field: 'name',
        headerName: t('field'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
        headerClassName: 'data-grid--header',
      },
      {
        field: 'farmName',
        headerName: t('farm'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
        headerClassName: 'data-grid--header',
      },
      {
        field: 'clientName',
        headerName: t('client'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
        headerClassName: 'data-grid--header',
      },
      {
        field: 'fieldArea',
        headerName: t('field_area'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
        headerClassName: 'data-grid--header',
      },
    ]
    setColumns(_columns)
  }, [fieldList])

  function CustomNoRowsOverlayFields() {
    if (showFields) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {t('no_fields')}
        </div>
      )
    } else
      return (
        <div style={{ textAlign: 'center', padding: '32px' }}>
          {t('no_permissions', { item: t('fields') })}
        </div>
      )
  }

  const deleteField = useCallback(() => {
    if (rowSelectionModel.length) {
      const selectedField = filteredFieldList.find(
        (f) => f.id === rowSelectionModel[0],
      )
      dispatch(showModal(<OCFFDeleteField selectedField={selectedField} />))
    }
  }, [rowSelectionModel])

  const onClick = noop

  return (
    <>
      <Box
        px={3}
        py={4}
        width={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          height: '80%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h2>{t('fields')}</h2>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              style={{ display: addModifyFields ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              disabled={
                rowSelectionModel.length === 0 ||
                (rowSelectionModel.length > 1 && farms.length === 1)
              }
              onClick={deleteField}
              title={t('delete_item', { item: t('field') })}
              size="large"
            >
              <DeleteOutlineOutlinedIcon
                style={{ color: rowSelectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyFields ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              title={t('edit_field')}
              disabled={
                rowSelectionModel.length === 0 ||
                (rowSelectionModel.length > 1 && farms.length === 1)
              }
              onClick={onEditField}
              size="large"
            >
              <EditIcon
                style={{ color: rowSelectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyFields ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              onClick={onCreateField}
              title={t('add_field')}
              size="large"
            >
              <AddIcon style={{ color: '#003057' }} />
            </IconButton>

            <TextField
              id="search"
              onChange={(val) => {
                onSearch(val)
              }}
              type="search"
              placeholder="Search"
              hiddenLabel
              sx={{ marginLeft: '10px' }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Box>
        <Box
          my={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            height: '80%',
            '& .data-grid--header': {
              background: '#f8f8f9',
              textTransform: 'uppercase',
            },
          }}
        >
          <div style={{ flexGrow: 1, height: '100%', width: '100%' }}>
            <DataGrid
              autoPageSize
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                if (newRowSelectionModel.length > 1) {
                  const selectionSet = new Set(rowSelectionModel)
                  const result = newRowSelectionModel.filter(
                    (s) => !selectionSet.has(s),
                  )
                  setRowSelectionModel(result)
                } else {
                  setRowSelectionModel(newRowSelectionModel)
                }
              }}
              rowSelectionModel={rowSelectionModel}
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={(model) =>
                setColumnVisibility(model)
              }
              initialState={{
                sorting: {
                  sortModel: [{ field: 'fieldName', sort: 'asc' }],
                },
              }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlayFields,
              }}
              rows={filteredFieldList
                .map((c) => ({
                  ...c,
                  id: c.id,
                  farmId: c.farmId,
                }))
                .filter((c) => filterFields(searchValue, c))}
              columns={columns}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#f8f8f9',
                  textTransform: 'uppercase',
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              onRowClick={(params) => onClick(params.row)}
            />
          </div>
        </Box>
      </Box>
    </>
  )
}
