//@ts-expect-error
import SplitMapContext from 'context/split-map-context'

export default function useSplitMap(splitRef = 'split') {
  if (splitRef === 'split') {
    return React.useContext(SplitMapContext)
  } else if (splitRef === 'lock_a') {
    const { lock_a, onSwitchLockA } = React.useContext(SplitMapContext)
    return { lock: lock_a, onSwitchLock: onSwitchLockA }
  } else if (splitRef === 'lock_b') {
    const { lock_b, onSwitchLockB } = React.useContext(SplitMapContext)
    return { lock: lock_b, onSwitchLock: onSwitchLockB }
  }
}
