import styled from 'styled-components'
import { V, H, VItem } from 'components/layout'
import { colors } from 'components/foundation'

export default function InfoGroup({ children }) {
  return (
    <H>
      {React.Children.toArray(children).map((child, idx) => (
        <H.Item key={idx}>{child}</H.Item>
      ))}
    </H>
  )
}

InfoGroup.Section = function Section({ children }) {
  return (
    <StyledSection>
      {' '}
      {React.Children.toArray(children).map((child, idx) => (
        <VItem key={idx}>{child}</VItem>
      ))}
    </StyledSection>
  )
}

const StyledSection = styled(V)`
  padding-right: 8px;
  margin-right: 4px;
  border-right: 2px solid ${colors.brand};
`
