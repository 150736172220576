import { Box, TextField, InputAdornment, IconButton } from '@mui/material'
import {
  Search as SearchIcon,
  Add as AddIcon,
  Edit as EditIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material'
//@ts-expect-error
import { showModal } from 'actions/app'
import { get, noop, filter, find, sumBy } from 'lodash-es'
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid'
//@ts-expect-error
import { convertAreaToPref } from 'common/unitConvert'
//@ts-expect-error
import OCFFAddClientComponent from './add-client'
//@ts-expect-error
import OCFFEditClientComponent from './edit-client'
//@ts-expect-error
import OCFFDeleteClient from './delete-client'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState, ReactElement } from 'react'
//@ts-expect-error
import type { AppState } from 'state/index'
//@ts-expect-error
import type { ClientModel } from 'state/client'
// import type { AppState } from 'state'
//@ts-expect-error
import type { FieldModel } from 'state/field'

declare module 'components/ocff/clients/index.js' {
  type featuresOverviewType = {
    id: number
    geometry: object
    properties: object
  }

  type fieldAreaMapType = {
    id: number
    area: number
    farmId: number
  }

  type RowData = {
    id: number
    fieldArea: string | 0
    name: string
    farmsQuantity: number
    fieldsQuantity: number
  }

  type clientColumns = {
    field: string
    headerName: string
    minWidth: number
    maxWidth: number
    flex: number
  }
}
export default function OCFFClientsComponent(): ReactElement<any, any> {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const fields: FieldModel[] = useSelector((state) =>
    get(state, 'field.collection', []),
  )
  const farms = useSelector((state) =>
    get(state as AppState, 'farm.collection', []),
  )
  const clients: ClientModel[] = useSelector((state) =>
    get(state as AppState, 'client.collection', []),
  )
  const userPrefs = useSelector((state) =>
    get(state as AppState, 'account.singleton.prefs'),
  )
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const [searchValue, setSearchValue] = useState('')

  const permissions = useSelector((state) =>
    get(state as AppState, 'account.permissions'),
  )
  const showClients = permissions.includes('farm:read')
  const addModifyClients = permissions.includes('farm:write')

  const [columns, setColumns] = useState<clientColumns[]>([])
  const [fieldAreaMap, setFieldAreaMap] = useState<fieldAreaMapType[]>([])

  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
  })
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const _fieldAreaMap: fieldAreaMapType[] = []
    if (fields) {
      fields.forEach(function (field) {
        const area = field?.areaSqm || 0
        _fieldAreaMap.push({
          id: field.id,
          area: Math.floor(area),
          farmId: field.farmId,
        })
      })
    }
    setFieldAreaMap(_fieldAreaMap)
  }, [fields])

  const onSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const filterClients = (searchValue, client) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()
      return client.name.toLowerCase().indexOf(lowerSearchValue) > -1
    }
    return true
  }

  const showAddClientPopup = useCallback(() => {
    dispatch(showModal(<OCFFAddClientComponent />))
  }, [])

  useEffect(() => {
    const _columns: clientColumns[] = [
      {
        field: 'name',
        headerName: t('client'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'farmsQuantity',
        headerName: t('farms_quantity'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'fieldsQuantity',
        headerName: t('fields_quantity'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'fieldArea',
        headerName: t('field_area'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
    ]
    setColumns(_columns)
    setRowSelectionModel([])
  }, [clients])

  function CustomNoRowsOverlayFields(): ReactElement {
    if (showClients) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {t('no_clients')}
        </div>
      )
    } else
      return (
        <div style={{ textAlign: 'center', padding: '32px' }}>
          {t('no_permissions', { item: t('clients') })}
        </div>
      )
  }

  useEffect(() => {
    let _rows: RowData[] = []
    if (showClients) {
      clients.forEach((client) => {
        let totalFields = 0,
          fieldArea = 0,
          matchingObjs: fieldAreaMapType[] = []
        //For total Fields per client
        if (client?.farms?.length) {
          client?.farms?.map((farm) => {
            totalFields =
              filter(fields, ['farmId', farm.id]).length + totalFields
            matchingObjs = fieldAreaMap.length
              ? filter(fieldAreaMap, ['farmId', farm.id])
              : []
            fieldArea += sumBy(matchingObjs, 'area')
          })
        }
        //End
        _rows.push({
          id: client.id,
          fieldArea: `${Math.floor(
            convertAreaToPref(fieldArea, userPrefs?.['units']['area_in']),
          )} ${userPrefs?.['units']['area_in']}`,
          name: client.name,
          farmsQuantity: client?.farms?.length ?? 0,
          fieldsQuantity: totalFields ?? 0,
        })
      })
      setRows(_rows)
    } else {
      setRows([])
    }
  }, [clients, fieldAreaMap, fields, farms])

  const editClient = useCallback(() => {
    if (rowSelectionModel.length) {
      const selectedClient = find(rows, ['id', rowSelectionModel[0]])
      dispatch(
        showModal(<OCFFEditClientComponent selectedClient={selectedClient} />),
      )
    }
  }, [rowSelectionModel])

  const deleteClient = useCallback(() => {
    if (rowSelectionModel.length) {
      const selectedClient = find(rows, ['id', rowSelectionModel[0]])
      dispatch(showModal(<OCFFDeleteClient selectedClient={selectedClient} />))
    }
  }, [rowSelectionModel])

  const onClick = noop

  return (
    <>
      <Box
        px={3}
        py={4}
        width={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          height: '80%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h2>{t('clients')}</h2>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              style={{ display: addModifyClients ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              disabled={
                rowSelectionModel.length === 0 ||
                (rowSelectionModel.length > 1 && farms.length === 1)
              }
              onClick={deleteClient}
              title={t('delete_item', { item: t('client') })}
              size="large"
            >
              <DeleteOutlineOutlinedIcon
                style={{ color: rowSelectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>
            <IconButton
              style={{ display: addModifyClients ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              title={t('edit_client')}
              disabled={
                rowSelectionModel.length === 0 ||
                (rowSelectionModel.length > 1 && farms.length === 1)
              }
              onClick={editClient}
              size="large"
            >
              <EditIcon
                style={{ color: rowSelectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyClients ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              onClick={showAddClientPopup}
              title={t('add_client')}
              size="large"
            >
              <AddIcon style={{ color: '#003057' }} />
            </IconButton>
            <TextField
              id="search"
              onChange={(val) => {
                onSearch(val)
              }}
              type="search"
              placeholder="Search"
              hiddenLabel
              sx={{ marginLeft: '10px' }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Box>
        <Box
          my={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            height: '80%',
          }}
        >
          <div style={{ flexGrow: 1, height: '100%', width: '100%' }}>
            <DataGrid
              checkboxSelection
              onRowSelectionModelChange={(selection: []) => {
                if (selection.length > 1) {
                  const selectionSet = new Set(rowSelectionModel)
                  const result: number[] = selection.filter(
                    (s) => !selectionSet.has(s),
                  )
                  setRowSelectionModel(result)
                } else {
                  setRowSelectionModel(selection)
                }
              }}
              autoPageSize
              rowSelectionModel={rowSelectionModel}
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={(model: { name: true }) =>
                setColumnVisibility(model)
              }
              initialState={{
                sorting: {
                  sortModel: [{ field: 'clientName', sort: 'asc' }],
                },
              }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlayFields,
              }}
              rows={rows.filter((client) => filterClients(searchValue, client))}
              columns={columns}
              pageSizeOptions={[10]}
              onRowClick={(params) => onClick(params.row)}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#f8f8f9',
                  textTransform: 'uppercase',
                },
              }}
            />
          </div>
        </Box>
      </Box>
    </>
  )
}
