import { Box, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import colors from 'common/colors'

export default function CustomRadioButton({
  options,
  setSelectedOption,
  value,
}) {
  const handleOnChange = useCallback((option) => {
    setSelectedOption(option.target.value)
  })

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column">
      <RadioGroup
        value={value}
        onChange={handleOnChange}
        color="error"
        sx={{
          '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
            color: colors.brand,
          },
        }}
      >
        {options.map((item) => {
          {
            return (
              <FormControlLabel
                color="primary"
                key={item.label}
                value={item.value}
                control={<Radio />}
                label={item.label}
                sx={{ alignItems: 'center' }}
              />
            )
          }
        })}
      </RadioGroup>
    </Box>
  )
}
