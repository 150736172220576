//@ts-expect-error
import type { TenantIntegration, IntegrationData } from 'state/integrations'

import { get } from 'lodash-es'
//@ts-expect-error
import store from 'store/index'

export function clearIntegrationData() {
  const availableIntegrations = get(
    store.getState(),
    'integrations.availableIntegrations',
  )
  for (let i = 0; i < availableIntegrations.length; i++) {
    const authData = localStorage.getItem(availableIntegrations[i].name)

    if (authData) {
      localStorage.removeItem(availableIntegrations[i].name)
    }
  }
}
