import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import FieldImportGrid from 'components/field-import/index'
//@ts-expect-error
import FileUploadInput from 'components/lands/upload-boundaries/fileUploadInput'
import { useSelector } from 'react-redux'
//@ts-expect-error
import { AppState } from 'state/index'
//@ts-expect-error
import { getClientFarm } from 'common/misc'

export default function UploadBoundariesComponent(props) {
  const { t } = useTranslation()
  const { onImportField, isTransferringData, setTransferringData } = props

  const clients = useSelector(
    (state: AppState) => state.client.collection || [],
  )

  const cffEnabled = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )
  const prefDefault = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff?.client ?? '',
  )

  const prefDefaultFarm = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff?.farm,
  )
  const { client, farms } = getClientFarm(cffEnabled, clients, prefDefault)

  return (
    <Box sx={{ p: 3, width: '85%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ boxShadow: 'none', background: '#fff' }}
        >
          <Toolbar sx={{ padding: '0 !important' }}>
            <h2 style={{ flexGrow: 1, color: 'black' }}>
              {t('upload_boundaries')}
            </h2>
            <Typography
              variant="h5"
              sx={{
                padding: 1,
                fontWeight: 500,
                fontSize: 'medium',
                color: '#439313',
              }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, ml: 1 }}
              >
                <InfoOutlinedIcon
                  sx={{
                    '& path': {
                      fill: '#FFF',
                    },
                  }}
                />
              </IconButton>
              {t('valid_fields_polygons_import')}
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, ml: 1 }}
              >
                <InfoOutlinedIcon
                  sx={{
                    '& path': {
                      fill: '#FFF',
                    },
                  }}
                />
              </IconButton>
              {t('create_client_farm')}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <FieldImportGrid
        onImportField={onImportField}
        defaultClient={client}
        defaultFarm={farms[0]}
        clients={clients}
        fileUpload={FileUploadInput}
        isTransferringData={isTransferringData}
        setTransferringData={setTransferringData}
      />
    </Box>
  )
}
