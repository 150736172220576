import { FloatingPanel } from 'components/floating'
import {
  // FarmtrxIconDocumentation,
  Info,
} from '@troo/farmtrx-icons'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { useEffect } from 'react'

function getButtonState(defaultState) {
  return defaultState ? defaultMode : expandedMode
}

const defaultMode = {
  justifyContent: 'start',
  background: '#F2F2F2',
  color: '#003057',
  maxWidth: 'fit-content',
  '& > span:nth-of-type(2)': {
    display: 'none',
  },
  '& > span:nth-of-type(1)': {
    marginRight: 0,
  },
}

const expandedMode = {
  justifyContent: 'start',
  background: '#F2F2F2',
  color: '#003057',
  maxWidth: 'initial',
  '& > span:nth-of-type(2)': {
    display: 'block',
  },
  '& > span:nth-of-type(1)': {
    marginRight: '8px',
  },
}

export default function HarvestToolbar(props) {
  const {
    onPointInfo,
    //onAreaInfo,
    //onAddNote
  } = props

  const { t } = useTranslation()
  const [defaultState, setDefaultState] = useState(true)
  const [selectedBtn, setSelectedBtn] = useState()

  useEffect(() => {
    return () => {
      if (selectedBtn === 'point-inspection') {
        onPointInfo()
      }
    }
  }, [selectedBtn])

  const expandToggle = useCallback(() => {
    setDefaultState(!defaultState)
  })

  const showBtnText = useCallback((e, id) => {
    document.querySelector('#harvest-toolbar').style.boxShadow = 'none'
    let prevSelectedBtn = selectedBtn
    const el = prevSelectedBtn && document.querySelector(`#${prevSelectedBtn}`)
    if (el) {
      let classList = el && Array.from(el.classList)
      classList.map((cls) => {
        if (cls.indexOf('makeStyles-expandedMode') != -1) {
          document.getElementById(prevSelectedBtn).classList.remove(cls)
        }
      })
    }

    document.getElementById(id).classList.add(expandedMode)
    setSelectedBtn(id)
  })
  return (
    <FloatingPanel position="top">
      <ButtonGroup
        id="harvest-toolbar"
        orientation="vertical"
        aria-label="vertical contained button group"
        variant="contained"
      >
        <Tooltip
          title={t('point_inspection')}
          placement="right"
          disableInteractive
        >
          <Button
            id="point-inspection"
            sx={getButtonState(defaultState)}
            key="point-inspection"
            startIcon={
              <Info onClick={(e) => showBtnText(e, 'point-inspection')} />
            }
            onClick={onPointInfo}
          >
            <span>{t('point_inspection')}</span>
          </Button>
        </Tooltip>
        {/*<Tooltip title={t('area_inspection')} placement="right" disableInteractive>
          <Button
            id="area-inspection"
            className={`toolButtons ${
              defaultState ? classes.defaultMode : classes.expandedMode
            }`}
            key="area-inspection"
            startIcon={
              <CircleOutlinedIcon
                onClick={(e) => showBtnText(e, 'area-inspection')}
              />
            }
            onClick={onAreaInfo}
          >
            <span>{t('area_inspection')}</span>
          </Button>
        </Tooltip>
        <Tooltip title={t('add_note')} placement="right">
                    <Button id='add-note' className={`toolButtons ${defaultState ? classes.defaultMode : classes.expandedMode}`} key="add-note" startIcon={<FarmtrxIconDocumentation onClick={(e) => showBtnText(e, 'add-note')} />} onClick={onAddNote}>
                        <span> {t('add_note')} </span>
                    </Button>
    </Tooltip>*/}

        <Button
          sx={getButtonState(defaultState)}
          key="expand"
          startIcon={
            defaultState ? (
              <DoubleArrowIcon />
            ) : (
              <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />
            )
          }
          onClick={expandToggle}
        >
          <span>{t('collapse')}</span>
        </Button>
      </ButtonGroup>
    </FloatingPanel>
  )
}
