import { FloatingPanel } from 'components/floating'
import {
  startDrawingBoundary,
  switchDrawType,
  startDrawingHole,
  stopDrawingHole,
  stopDrawingBoundary,
  stopEditBoundary,
} from 'actions/map'
import {
  CancelOutlined as CancelOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
} from '@mui/icons-material'
import {
  DrawCircleIcon,
  DrawCircleHoleIcon,
  DrawPolygonIcon,
  DrawPolygonHoleIcon,
  EditBoundaryIcon,
} from './icons'
import {
  ExpandableToolbar,
  ExpandableToolbarButton,
} from 'components/expandable-toolbar'
import { useCallback, useEffect, useState } from 'react'
import useMap from 'hooks/use-map'
import { cloneDeep, get } from 'lodash-es'

import useSource from 'hooks/use-source-boundary'
import { useSelector } from 'react-redux'

export default function BoundaryTool(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const map = useMap()
  const source = useSource()
  const field = useSelector((state) => get(state, 'field.singleton'))
  const drawHole = useSelector((state) => get(state, 'map.drawHole'))
  const unsavedChanges = useSelector((state) =>
    get(state, 'map.unsavedBoundary'),
  )
  const [originalFeature, setOriginalFeature] = useState(null)

  const { onEdit, onDrawPolygon, onDrawCircle, onDone, onCancel } = props

  const [selectedBtn, setSelectedBtn] = useState()

  useEffect(() => {
    if (field) {
      if (map && originalFeature == null) {
        const feature = source.getFeatureById(field.id)
        if (feature) {
          setOriginalFeature(cloneDeep(feature))
        }
      } else {
        if (map && originalFeature.getId() !== field.id) {
          cancelDraw(false)
          const feature = source.getFeatureById(field.id)
          if (feature) {
            setOriginalFeature(cloneDeep(feature))
          }
        }
      }
    } else {
      setOriginalFeature(null)
    }
  }, [map, field, originalFeature])

  const drawPolygonBoundary = useCallback(() => {
    if (drawHole) {
      dispatch(stopDrawingHole())
    }
    dispatch(startDrawingBoundary())
    onDrawPolygon()
    setSelectedBtn('drawPolygon')
  }, [drawHole, map, originalFeature])

  const drawCircularBoundary = useCallback(() => {
    if (drawHole) {
      dispatch(stopDrawingHole())
    }
    dispatch(startDrawingBoundary())
    onDrawCircle()
    setSelectedBtn('drawCircle')
  }, [drawHole])

  const addHole = useCallback((type) => {
    dispatch(startDrawingHole())
    dispatch(switchDrawType(type))
    setSelectedBtn(`drawHole${type}`)
  })

  const editBoundary = useCallback(() => {
    onEdit()
    setSelectedBtn('editBoundary')
  }, [unsavedChanges])

  const cancelDraw = useCallback(
    (stopEdit = true) => {
      if (map && unsavedChanges) {
        const updatedFeature = source.getFeatureById(field.id)
        if (updatedFeature && originalFeature) {
          updatedFeature.setGeometry(originalFeature.getGeometry())
        } else if (originalFeature) {
          source.addFeature(originalFeature)
        } else {
          source.removeFeature(updatedFeature)
        }

        const features = source.getFeatures()

        let toRemove = []
        for (let f in features) {
          if (features[f].id_ == undefined) {
            toRemove.push(features[f])
          }
        }
        for (let f in toRemove) {
          source.removeFeature(toRemove[f])
        }
      }
      dispatch(stopDrawingHole())
      dispatch(stopDrawingBoundary())
      dispatch(stopEditBoundary())
      setOriginalFeature(null)
      if (stopEdit) {
        onCancel()
      }
    },
    [unsavedChanges, originalFeature],
  )

  return (
    <FloatingPanel position="top">
      <ExpandableToolbar>
        <ExpandableToolbarButton
          active={selectedBtn === 'drawPolygon' ? 1 : undefined}
          icon={<DrawPolygonIcon />}
          label={`${t('draw')} ${t('polygon')}`}
          onClick={drawPolygonBoundary}
        />
        <ExpandableToolbarButton
          active={selectedBtn === 'drawCircle' ? 1 : undefined}
          icon={<DrawCircleIcon />}
          label={`${t('draw')} ${t('circle')}`}
          onClick={drawCircularBoundary}
        />
        <ExpandableToolbarButton
          active={selectedBtn === 'editBoundary' ? 1 : undefined}
          icon={<EditBoundaryIcon />}
          label={`${t('edit')} ${t('boundary')}`}
          onClick={editBoundary}
        />
        <ExpandableToolbarButton
          active={selectedBtn === 'drawHoleCircle' ? 1 : undefined}
          icon={<DrawCircleHoleIcon />}
          label={t('draw_circular_hole')}
          onClick={() => addHole('Circle')}
        />
        <ExpandableToolbarButton
          active={selectedBtn === 'drawHoleMultiPolygon' ? 1 : undefined}
          icon={<DrawPolygonHoleIcon />}
          label={t('draw_polygon_hole')}
          onClick={() => addHole('MultiPolygon')}
        />
        <ExpandableToolbarButton
          disabled={!unsavedChanges}
          icon={<SaveOutlinedIcon />}
          label={t('save')}
          onClick={onDone}
        />
        <ExpandableToolbarButton
          icon={<CancelOutlinedIcon />}
          label={t('cancel')}
          onClick={cancelDraw}
        />
      </ExpandableToolbar>
    </FloatingPanel>
  )
}
