import styled from 'styled-components'
import { colors } from 'components/foundation'
import { ReactElement } from 'react'
import React from 'react'
import * as PropTypes from 'prop-types'
type FloatingPanelProps = {
  position: string
  children: (React.Component | ReactElement | JSX.Element | null)[]
  background: string
}
export default function FloatingPanel(props: FloatingPanelProps) {
  const { children } = props

  return <StyledRoot {...props}>{children}</StyledRoot>
}

FloatingPanel.propTypes = {
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
}

//@ts-ignore
FloatingPanel.Header = styled.h1`
  color: ${colors.brand};
  font-size: clamp(
    10px,
    ${((window.screen.width + window.screen.height) / 2) * 0.01}px,
    10px
  );
  max-font-size: 10px;
  text-align: center;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
`
//@ts-ignore
const StyledRoot = styled.div<FloatingPanelProps>`
  display: block;
  position: absolute;
  z-index: 1;
  ${(props: { position: string }) => {
    switch (props.position) {
      case 'top':
        return 'top: 24px;'
      case 'right':
        return 'top: calc(25% + 40px);'
      case 'left':
        return 'top: calc(25% + 40px);'
      case 'bottom':
        return 'bottom: 59px;'
      default:
        return 'bottom: 10px;'
    }
  }}
  ${(props: { position: string }) => {
    switch (props.position) {
      case 'top':
        return 'left: 24px;'
      case 'right':
        return 'right: 24px;'
      case 'left':
        return 'left: calc(40% - 13px);'
      case 'bottom':
        if (window.screen.width < 800) {
          return 'left: calc(0.5%);'
        } else {
          return 'left: calc(25% - 13px);'
        }
      default:
        return 'left: calc(40% - 13px);'
    }
  }}
  ${(props: { background: string }) => {
    switch (props.background) {
      case 'white':
        return 'background: white;'
    }
  }}
  padding: 5px;
  border-radius: 4px;
`
