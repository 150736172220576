import { Grid2 } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash-es'
//@ts-expect-error
import type { AppState } from 'state/index'
//@ts-expect-error
import type { Tenant } from 'state/account'

export default function AccountDisabledPage() {
  const { t } = useTranslation()
  const partner: Tenant = useSelector((state) =>
    get(state as AppState, 'account.partnerContactDetails'),
  )

  let partnerName: string | undefined = 'FarmTRX'
  let partnerEmail: string | undefined = 'support@farmtrx.com'
  if (!isEmpty(partner)) {
    partnerName = partner.fullName
    partnerEmail = partner.email
  }

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ md: 12, lg: 12 }}>
        {t<string>('account_deactivated', {
          partner_name: partnerName,
        })}
      </Grid2>
      {partnerEmail ? (
        <Grid2 size={{ md: 12, lg: 12 }}>
          {t<string>('email')} {partnerEmail}
        </Grid2>
      ) : null}
      {partner?.phoneNumber ? (
        <Grid2 size={{ md: 12, lg: 12 }}>
          {t<string>('phone_number')} {partner?.phoneNumber}
        </Grid2>
      ) : null}
    </Grid2>
  )
}
