//@ts-expect-error
import MapContext from 'context/map-context'
//@ts-expect-error
import SplitMapContext from 'context/split-map-context'
export default function useMap(mapRef = 'map') {
  if (mapRef === 'map') {
    return React.useContext(MapContext)
  } else if (mapRef === 'map_a' || mapRef === 'map_b') {
    const mapRefs = React.useContext(SplitMapContext)
    return mapRefs[mapRef]
  }
  return React.useContext(MapContext)
}
