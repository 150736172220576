import { Box, CircularProgress } from '@mui/material'
import {
  SyncProblem as SyncProblemIcon,
  Sync as SyncIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
} from '@mui/icons-material'

//@ts-expect-error
import * as c from 'common/c'
export type SyncStatusProps = {
  status: String
}

const syncStatusIconsStyle = (status) => ({
  height: '30px',
  width: '30px',
  color:
    status === c.IN_PROGRESS
      ? '#0f5132'
      : status === c.DONE
        ? '#0f5132'
        : status === c.ERROR
          ? '#F00'
          : '',
})

export default function SyncStatus(props: SyncStatusProps) {
  const { status } = props

  switch (status) {
    case c.IN_PROGRESS:
      return (
        <Box px={2}>
          <CircularProgress size={30} />
        </Box>
      )
    case c.DONE:
      return (
        <Box px={2}>
          <PublishedWithChangesIcon sx={syncStatusIconsStyle(status)} />
        </Box>
      )
    case c.ERROR:
      return (
        <Box px={2}>
          <SyncProblemIcon sx={syncStatusIconsStyle(status)} />
        </Box>
      )
    default:
      return (
        <Box px={2}>
          <SyncIcon sx={syncStatusIconsStyle(status)} />
        </Box>
      )
  }
}
