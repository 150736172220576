import styled from 'styled-components'
import { BlankLink } from 'components/foundation'

declare module 'components/layers-group/item-switch.js'
export type ItemSwitchProps = {
  onClick: Function
  className: string
  on: number
  title: string
}

export default function ItemSwitch(props) {
  const { on, children, onClick, className = '', title } = props

  return (
    <StyledRoot
      on={on ? 1 : 0}
      onClick={onClick}
      className={className}
      title={title}
    >
      {children}
    </StyledRoot>
  )
}

//@ts-ignore
const StyledRoot = styled(BlankLink)`
  display: block;
  padding-left: 4px;
  color: #212121;
  opacity: 0.8;
  font-size: 1rem;
  font-weight: ${({ on }) => (on ? '700' : '400')};
  background: ${({ on }) => (on ? '#F4F4F4' : 'transparent')};
`
