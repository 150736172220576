import { Autocomplete, Typography, TextField, Stack } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
//@ts-expect-error
import { ClientModel } from 'state/client'
//@ts-expect-error
import { AppState } from 'state/index'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
//@ts-expect-error
import { assignSelectedClient } from 'actions/client'
//@ts-expect-error
import { assignSelectedFarms } from 'actions/farm'
//@ts-expect-error
import { fetchBBox } from 'api/field'
//@ts-expect-error
import { setDefaultExtent } from 'actions/field'

export type ClientSelectorProps = {
  selectedClient?: ClientModel
  onChange?: Function
  hideLabel?: boolean
}
export default function ClientSelector(
  props: ClientSelectorProps,
): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const clients: ClientModel[] = useSelector(
    (state: AppState) => state.client.collection,
  )
  const year = useSelector((state: AppState) => state.field.year)
  const clientsWithBoundaries: { [key: number | string]: number[] } =
    useSelector((state: AppState) => state.client.clientFieldFeaturesFetched)
  const [selectedClient, setSelectedClient] = useState<ClientModel | null>(null)
  useEffect(() => {
    if (props.selectedClient !== undefined) {
      setSelectedClient(props.selectedClient)
    }
  }, [props.selectedClient])

  const onClientChange = useCallback(
    (event, newValue, reason) => {
      dispatch(fetchBBox({ clientId: newValue.id, year }))
      if (clientsWithBoundaries[newValue.id] !== undefined) {
        dispatch(setDefaultExtent(clientsWithBoundaries[newValue.id]))
      }
      if (props.onChange) {
        props.onChange(event, newValue, reason)
      } else {
        let client, farms
        if (reason === 'clear') {
          client = ''
          farms = []
        } else {
          client = newValue
          farms = newValue?.['farms'] || []
        }
        localStorage.setItem('selectedClient', JSON.stringify(client))
        dispatch(assignSelectedClient(client))
        setSelectedClient(client)
        localStorage.setItem('selectedFarms', JSON.stringify(farms))
        dispatch(assignSelectedFarms(farms))
      }
    },
    [props.onChange, clientsWithBoundaries, year],
  )
  return (
    (<Stack spacing={1}>
      {props?.hideLabel ? null : <Typography>{t('clients') + ':'}</Typography>}
      <Autocomplete
        fullWidth
        style={{ maxHeight: '40px' }}
        sx={{
          '& input': {
            height: '1em',
          },
          '& label': {
            lineHeight: '1.5em',
            fontSize: '1em',
          },
        }}
        id="clients"
        autoHighlight
        isOptionEqualToValue={(option, value) => option.id === value.id}
        size="small"
        options={clients}
        selectOnFocus
        getOptionLabel={(option) => option?.name || ''}
        value={selectedClient ?? null}
        onChange={(event, newValue, reason) => {
          onClientChange(event, newValue, reason)
        }}
        renderInput={(params) => {
          return props.hideLabel ? (
            //@ts-ignore
            (<TextField
              {...params}
              label={t('clients')}
              placeholder={t('clients')}
              style={{ width: '100%' }}
            />)
          ) : (
            //@ts-ignore
            (<TextField
              {...params}
              placeholder={t('clients')}
              style={{ width: '100%' }}
            />)
          );
        }}
      />
    </Stack>)
  );
}
