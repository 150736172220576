import {
  FarmtrxIconHardware,
  FarmtrxIconHarvesterCombine,
  FarmtrxIconUsers,
  FarmtrxIconCrop,
  FarmtrxIconSettings,
} from '@troo/farmtrx-icons'
import { Box, Divider } from '@mui/material'
import { Navigate, Route, Routes } from 'react-router'
import { CommonSidebar, CommonSidebarLink } from '../common-sidebar'
import CropsComponent from './crops'
import EquipmentsComponent from './equipment'
import HardwareComponent from './hardware'
import CropHeadersComponent from './headers'
import UserAccountsComponent from './user-accounts'
import PreferencesComponent from 'components/admin/preferences'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getUserList } from 'api/user'

export default function FarmSettings() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserList())
  }, [])

  return (
    <Box sx={{ display: 'flex', overflowY: 'auto' }} width={1}>
      <CommonSidebar>
        <CommonSidebarLink
          to="../hardware"
          icon={<FarmtrxIconHardware title={t('hardware')} />}
          label={t('hardware')}
        />
        <Divider />
        <CommonSidebarLink
          to="../combines"
          icon={<FarmtrxIconHarvesterCombine title={t('combines')} />}
          label={t('combines')}
        />
        <Divider />
        <CommonSidebarLink
          to="../headers"
          icon={<FarmtrxIconHarvesterCombine title={t('headers')} />}
          label={t('headers')}
        />
        <Divider />
        <CommonSidebarLink
          to="../userAccounts"
          icon={<FarmtrxIconUsers title={t('user_accounts')} />}
          label={t('user_accounts')}
        />
        <Divider />
        <CommonSidebarLink
          to="../crops"
          icon={<FarmtrxIconCrop title={t('crops_varieties')} />}
          label={t('crops_varieties')}
        />
        <Divider />
        <CommonSidebarLink
          key="preferences"
          to="../preferences"
          icon={<FarmtrxIconSettings title={t('preferences')} />}
          label={t('preferences')}
        />
        <Divider />
      </CommonSidebar>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to="/farmSetup/hardware" replace={true} />}
        />

        <Route path="/crops" element={<CropsComponent />} />
        <Route path="/headers" element={<CropHeadersComponent />} />
        <Route path="/combines" element={<EquipmentsComponent />} />
        <Route path="/hardware" element={<HardwareComponent />} />
        <Route path="/userAccounts" element={<UserAccountsComponent />} />
        <Route path="/preferences" element={<PreferencesComponent />} />
      </Routes>
    </Box>
  )
}
