import styled from 'styled-components'

export default function ContentLeft({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}

//@ts-ignore
const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

ContentLeft.Content = function ({ children }) {
  return <StyledContent>{children}</StyledContent>
}
ContentLeft.Content.displayName = 'ContentLeft Content'

//@ts-ignore
const StyledContent = styled.div`
  flex-grow: 4;
`

ContentLeft.Content.propTypes = {
  children: PropTypes.node.isRequired,
}

ContentLeft.SideList = function ({ children }) {
  return <StyledSide>{children}</StyledSide>
}

ContentLeft.SideList.displayName = 'ContentLeft Side List'

//@ts-ignore
const StyledSide = styled.div`
  flex-grow: 1;
  margin-left: 4px;
  width: calc(10% - 20px);
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`

ContentLeft.SideItem = function SideItem({ children }) {
  return <StyledSideItem>{children}</StyledSideItem>
}

ContentLeft.SideItem.propTypes = {
  children: PropTypes.node.isRequired,
}

//@ts-ignore
const StyledSideItem = styled.div`
  & + & {
    margin-top: 4px;
  }
`
