//@ts-expect-error
import * as c from 'common/c'
import { cloneDeep } from 'lodash-es'
import update from 'immutability-helper'

declare module 'state/notification.js' {
  export type Notification = {
    id: number
    tenantId: string
    userId: string
    message: string
    expiresAt: string
    category: string
    severity: string
    isRead: boolean
  }
}

const initialState = {
  collection: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.NOTIFICATIONS_FETCHED: {
      //@ts-expect-error
      return update(state, { collection: { $set: action.payload } })
    }

    case c.NOTIFICATIONS_READ: {
      let notifications = cloneDeep(state.collection)
      let pLength = action.payload.length

      for (let i = 0; i < pLength; i++) {
        const index = notifications.findIndex(
          (p: any) => p.id == action.payload[i].notificationId,
        )
        if (index > -1) {
          //@ts-ignore
          notifications[index]['isRead'] = true
        }
      }
      //@ts-ignore
      return update(state, { collection: { $set: notifications } })
    }

    case c.NOTIFICATIONS_DISMISSED: {
      let notifications = cloneDeep(state.collection)
      let pLength = action.payload.length

      for (let i = 0; i < pLength; i++) {
        const index = notifications.findIndex(
          (p: any) => p.id == action.payload[i].notificationId,
        )
        if (index > -1) {
          notifications.splice(index, 1)
        }
      }
      //@ts-ignore
      return update(state, { collection: { $set: notifications } })
    }

    case c.NOTIFICATIONS_ADDED: {
      //@ts-ignore
      return update(state, { collection: { $unshift: [action.payload] } })
    }

    default:
      return state
  }
}
