import { withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Autocomplete, Grid2, Box, TextField, CardMedia } from '@mui/material'
import CustomRadioButton from 'components/form/radio-button-group'
import metreMeasurementImg from 'images/metre-measurement@2x.png'
import feetMeasurementImg from 'images/feet-measurement@2x.png'
import { sortBy } from 'lodash-es'
import { getInitialUnits } from 'common/misc'
import { useEffect } from 'react'

const requiredFieldStyle = {
  color: 'red',
}

const labelCustomStyle = {
  alignItems: 'flex-start',
}

const StyledTextField = withStyles({
  root: {
    ['@media (max-width: 1280px) and (orientation:landscape)']: {
      width: '35ch',
    },
    ['@media (min-width: 1300px) and (orientation:landscape)']: {
      width: '40ch',
    },
    ['@media (max-width: 800px) and (orientation:portrait)']: {
      width: '30ch',
    },
  },
})(TextField)

export default function AccountDetailsForm(props) {
  const { t } = useTranslation()
  const countries = useSelector(
    (state) => state.account['options']['countries'],
  )

  const authEmail = useSelector((state) => state.account.auth.user.email)
  const units = useSelector(
    (state) => state.account.singleton?.prefs?.units ?? '',
  )

  const [countriesCollection, setCountriesCollection] = useState([])
  const [editTenant, setEditTenant] = useState(false)
  const [preferredMeasurement, setPreferredMeasurement] = useState(
    units?.length_in == 'meters' ? 'Metric' : 'Imperial',
  )

  const tenantInfo = useSelector((state) => state.account['singleton'])

  const unitOptions = [
    {
      buttonImage: feetMeasurementImg,
      label: t('imperial_description'),
      value: 'Imperial',
      name: 'preferredMeasurement',
      defaultSelection: 'Imperial',
    },
    {
      buttonImage: metreMeasurementImg,
      label: t('metric_description'),
      value: 'Metric',
      name: 'preferredMeasurement',
    },
  ]
  const [formFields, setFormFields] = useState({
    fullName: '',
    email: authEmail ? authEmail : '',
    phoneNumber: '',
    country: {},
    streetAddress: '',
    postalCode: '',
    prefs: {
      units: getInitialUnits(false),
    },
  })

  useEffect(() => {
    if (Object.keys(tenantInfo).length) {
      setFormFields(tenantInfo)
      setEditTenant(true)
    } else {
      setEditTenant(false)
    }
  }, [tenantInfo])

  useEffect(() => {
    for (const p in countries) {
      countries[p].name = t(countries[p].name)
    }
    setCountriesCollection(
      sortBy(
        Object.keys(countries ?? {}).map((key) => ({
          ...countries[key],
          id: key,
        })),
        ['name'],
      ),
    )
  }, [countries])

  const setSelectedOption = (option) => {
    setPreferredMeasurement(option)
    setFormFields((formFields) => ({
      ...formFields,
      ['prefs']: {
        ...formFields.prefs,
        units: getInitialUnits(option === 'Metric'),
      },
    }))
  }

  const handleInputChange = (e, newValue) => {
    if (e) {
      let { name, value } = e.target
      if (!name) {
        name = 'country'
        value = newValue
      } else if (name === 'country') {
        value = newValue
      }
      setFormFields((formFields) => ({
        ...formFields,
        [name]: value,
      }))
    }
  }

  const handleBlur = (e) => {
    if (e) {
      let value = e.target.value
      if (value) {
        let countryMatched = countriesCollection.find(
          (c) => c.name.toLowerCase() === value.toLowerCase(),
        )
        if (countryMatched) {
          setFormFields((formFields) => ({
            ...formFields,
            ['country']: countryMatched,
          }))
        }
      }
    }
  }

  useEffect(() => {
    if (
      'email' in formFields &&
      formFields.email != '' &&
      'fullName' in formFields &&
      formFields.fullName != '' &&
      'country' in formFields &&
      formFields.country != null &&
      typeof formFields.country !== 'string' &&
      Object.keys(formFields.country).length > 0 &&
      'units' in formFields.prefs &&
      Object.keys(formFields.prefs.units).length
    ) {
      props.formFields(formFields)
      props.enableAccButton(true)
      if (editTenant) {
        props.setEditTenant(true)
      } else {
        props.setEditTenant(false)
      }
    } else {
      props.enableAccButton(false)
    }
  }, [formFields])

  return (
    <Grid2 sx={{ paddingBottom: '100px' }}>
      <Box mx={11} mt={6}>
        <h2 style={{ marginLeft: '8px' }}>{t('enter_account_details')}</h2>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: '10px' },
          }}
          noValidate
          autoComplete="off"
        >
          <FormControlLabel
            sx={labelCustomStyle}
            control={<StyledTextField placeholder={t('enter_full_name')} />}
            label={
              <>
                <span>{t('name')}</span>
                <span style={requiredFieldStyle}>*</span>
              </>
            }
            name="fullName"
            labelPlacement="top"
            value={formFields['fullName']}
            onChange={handleInputChange}
          />

          <FormControlLabel
            sx={labelCustomStyle}
            control={<StyledTextField disabled placeholder={t('email')} />}
            label={
              <>
                <span>{t('email')}</span>
                <span style={requiredFieldStyle}>*</span>
              </>
            }
            name="email"
            labelPlacement="top"
            value={formFields['email']}
            onChange={handleInputChange}
          />
          <FormControlLabel
            sx={labelCustomStyle}
            control={<StyledTextField placeholder={t('phone_number')} />}
            label={t('phone_number')}
            name="phoneNumber"
            labelPlacement="top"
            value={formFields['phoneNumber']}
            onChange={handleInputChange}
          />
          <FormControlLabel
            sx={labelCustomStyle}
            control={
              <Autocomplete
                name="country"
                value={formFields?.country}
                selectOnFocus
                options={countriesCollection}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    //getOptionLabel should be the one that the user sees
                    return option
                  }
                  if (!Object.keys(option).length) {
                    return ''
                  } else return option.name
                }}
                onBlur={(e) => handleBlur(e)}
                onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                renderOption={(props, option) => (
                  <li {...props}>{t(option.name)}</li>
                )}
                freeSolo
                onChange={(e, newValue) => handleInputChange(e, newValue)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    variant="outlined"
                    name="country"
                    autoComplete="none"
                    placeholder={t('country')}
                  />
                )}
              />
            }
            label={
              <>
                <span>{t('country')}</span>
                <span style={requiredFieldStyle}>*</span>
              </>
            }
            labelPlacement="top"
          />
          <FormControlLabel
            sx={labelCustomStyle}
            control={
              <StyledTextField
                placeholder={t('street_address')}
                multiline
                rows={3}
              />
            }
            label={t('address')}
            name="streetAddress"
            labelPlacement="top"
            value={formFields['streetAddress']}
            onChange={handleInputChange}
          />
          <FormControlLabel
            sx={labelCustomStyle}
            control={<StyledTextField placeholder={t('postal_code')} />}
            label={t('postal_code')}
            name="postalCode"
            labelPlacement="top"
            value={formFields['postalCode']}
            onChange={handleInputChange}
          />
          <FormControlLabel
            sx={{ alignItems: 'flex-start', width: '100%' }}
            control={
              <Box
                sx={{
                  flexGrow: 1,
                  padding: '10px 30px',
                  borderRadius: '5px',
                  border: '1px solid lightgray',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <CardMedia
                  component="img"
                  image={feetMeasurementImg}
                  style={{ marginRight: '30px', width: '100px' }}
                />
                <CustomRadioButton
                  options={unitOptions}
                  value={preferredMeasurement}
                  setSelectedOption={setSelectedOption}
                />
              </Box>
            }
            label={
              <>
                <span>{t('preferred_measurement_unit')}</span>
                <span style={requiredFieldStyle}>*</span>
              </>
            }
            name={unitOptions[0]['name']}
            labelPlacement="top"
            onChange={handleInputChange}
          />
        </Box>
      </Box>
    </Grid2>
  )
}
