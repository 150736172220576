import MultiPoint from 'ol/geom/MultiPoint'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'
import colors from 'common/colors'
import { get } from 'lodash-es'
import { hexToRgba } from 'common/misc'
import * as c from 'common/c'

export const colorList = {
  aqua: _buildStyle('#7FDBFF'),
  black: _buildStyle('#111111'),
  blue: _buildStyle('#0074D9'),
  brown: _buildStyle('#8b4513'),
  dull_red: _buildStyle('#b22222'),
  green: _buildStyle('#2ECC40'),
  maroon: _buildStyle('#85144b'),
  orange: _buildStyle('#FF4136'),
  pink: _buildStyle('#ff1493'),
  red: _buildStyle('#FF0000'),
  slate_blue: _buildStyle('#7b68ee'),
  yellow: _buildStyle('#FFDC00'),
  silver: _buildStyle('#DDDDDD'),
  white: _buildStyle('#FFFFFF'),
}

const cropStyles = new Map()

export const getCropStyle = (cropId, hex) => {
  if (cropId) {
    let cropStyle = cropStyles.get(cropId)
    if (hex !== undefined && !cropStyle) {
      cropStyle = _buildStyle(hex)
      cropStyles.set(cropId, cropStyle)
    }
    return cropStyle
  }
  return colorList[DEFAULT_STYLE]
}

function _buildStyle(hex) {
  const rgba02 = hexToRgba(hex, 0.0)
  const rgba04 = hexToRgba(hex, 0.0)
  return {
    sample: hex,
    normal: new Style({
      stroke: new Stroke({
        color: hex,
        width: 3,
      }),
      fill: new Fill({ color: rgba02 }),
    }),
    select: new Style({
      stroke: new Stroke({
        color: hex,
        width: 6,
      }),
      fill: new Fill({ color: rgba04 }),
    }),
  }
}

const DEFAULT_STYLE = 'orange'

export const defaultStyle = colorList[DEFAULT_STYLE]['normal']
export const selectStyle = colorList[DEFAULT_STYLE]['select']

export const vertices = new Style({
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({ color: colors.brandSecondary }),
  }),
  geometry: function (feature) {
    let coords = []

    feature
      .getGeometry()
      .getPolygons()
      .forEach((poly) => {
        const polyCoords = poly.getCoordinates()
        polyCoords.forEach((obj) => {
          coords = coords.concat(obj)
        })
      })

    return new MultiPoint(coords)
  },
})

export const defaultStyles = [defaultStyle]

export const modifyStyles = [selectStyle, vertices]

export const modify = (styleName) => {
  const stylesArr = []
  stylesArr.push(get(colorList, `${styleName}.select`))
  stylesArr.push(vertices)

  return stylesArr
}

const stylesByType = {
  [c.FTYPE_NOTE]: new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: 'black' }),
      stroke: new Stroke({
        color: 'white',
        width: 2,
      }),
    }),
  }),
}

export const getStyleByType = (type) => {
  return stylesByType[type]
}
