//@ts-expect-error
import * as c from 'common/c'

export const notificationsFetched = (data) => {
  return {
    type: c.NOTIFICATIONS_FETCHED,
    payload: data,
  }
}

export const notificationsRead = (data) => {
  return {
    type: c.NOTIFICATIONS_READ,
    payload: data,
  }
}

export const notificationsDismissed = (data) => {
  return {
    type: c.NOTIFICATIONS_DISMISSED,
    payload: data,
  }
}
