//@ts-expect-error
import ModalContainer from 'components/modal/modal-container'
//@ts-expect-error
import ModalActions from 'components/modal/modal-actions'
import { Button } from '@mui/material'
//@ts-expect-error
import { closeModal } from 'actions/app'
//@ts-expect-error
import { deleteClient } from 'api/client'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import type { AppState } from 'state/index'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

export default function OCFFDeleteClient(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedClient } = props
  const { id, name } = selectedClient
  const clients = useSelector((state) => (state as AppState).client.collection)

  const defaultClient = useSelector(
    (state) => (state as AppState).account.singleton.prefs?.cff?.client,
  )

  const [defaultDeletionMsg, showDefaultDeletionMsg] = useState(false)

  useEffect(() => {
    if (
      (defaultClient && parseInt(defaultClient) === parseInt(id)) ||
      clients.length === 1
    ) {
      showDefaultDeletionMsg(true)
    }
  }, [selectedClient, defaultClient, clients])

  const closePopup = () => dispatch(closeModal())

  const deleteItem = () => {
    dispatch(deleteClient(id))
  }

  return (
    <ModalContainer>
      {defaultDeletionMsg ? (
        <>
          <div>
            {t('cannot_delete_default', {
              entity: t('client'),
            })}
          </div>
          <ModalActions>
            <Button variant="contained" color="primary" onClick={closePopup}>
              {t('ok')}
            </Button>
          </ModalActions>
        </>
      ) : selectedClient['farmsQuantity'] > 0 ? (
        <>
          <div>
            {t('delete_child_entity_first', {
              parentEntityName: name,
              childEntity: t('farms'),
              parentEntity: t('client'),
            })}
          </div>
          <ModalActions>
            <Button variant="contained" color="primary" onClick={closePopup}>
              {t('ok')}
            </Button>
          </ModalActions>
        </>
      ) : (
        <>
          <div>{t('delete_item_warning', { name: name })}</div>
          <ModalActions>
            <Button variant="contained" color="inherit" onClick={closePopup}>
              {t('cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={deleteItem}>
              {t('delete')}
            </Button>
          </ModalActions>
        </>
      )}
    </ModalContainer>
  )
}
