import {
  Alert,
  Box,
  Button,
  IconButton,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TextField,
  SxProps,
  Theme,
} from '@mui/material'
//@ts-expect-error
import type { AppState } from 'state/index'
import {
  Add as AddIcon,
  Clear as ClearIcon,
  SaveOutlined as SaveOutlinedIcon,
  ModeEditOutlineOutlined as ModeEditOutlineOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material'
import { useLocation } from 'react-router'
//@ts-expect-error
import colors from 'common/colors'
//@ts-expect-error
import { convertToFeetInches } from 'common/unitConvert'
import { difference, isEmpty } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import {
  createHeaders,
  editHeader,
  deleteHeader,
  getHeaderTypes,
  //@ts-expect-error
} from 'api/headers'
import {
  EquipmentFetchModel,
  EquipmentModelAPIRes,
  fetchEquipmentModels,
  //@ts-expect-error
} from 'api/equipment-model'
//@ts-expect-error
import MakeSelectorComponent from '../equipment/make-selector'
//@ts-expect-error
import ModelSelectorComponent from '../equipment/model-selector'
import { useCallback, useEffect, useState, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import { HeaderModel } from 'state/headers'

const cutWidthFtInStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: '10px',
}

const textFieldStyle = {
  root: {
    width: '18ch',
  },
}

const iconButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  margin: 1,
  '&:disabled svg path': {
    color: '#ECE9F1',
  },
})

declare module 'components/farm-settings/headers/index.js' {
  type HeaderErrors = {
    name: string
    cutWidth: string
    widthft: string
    widthin: string
    make: string
  }
  type BrandOption = {
    id: number
    name: string
    inputValue: string | undefined
  }
  type ModelOption = {
    id: number | null
    inputValue: string | undefined
    name: string
    makeBrandId: number | null
    equipmentType: string
  }
  type HeaderRowData = {
    id: string
    brandOption: BrandOption | null
    modelOption: Partial<ModelOption> | null
    name: string
    headerType: string | null
    cutWidth: number | null
    units: string
    widthin: number | null
    widthft: number | null
    year: number | null
  }

  export type HeaderComponentProps = {
    enableHeadersNextButton?: Function
  }
  export function HeadersComponent(
    props: HeaderComponentProps,
  ): ReactElement<any, any>
}

export default function CropHeadersComponent(
  props: HeaderComponentProps,
): ReactElement<any, any> {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const permissions = useSelector(
    (state) => (state as AppState).account.permissions,
  )
  const headers = useSelector((state) => (state as AppState).headers.collection)

  const headerTypes = useSelector(
    (state) => (state as AppState).headers.headerTypes,
  )

  const userPrefs = useSelector(
    (state) => (state as AppState).account.singleton.prefs,
  )

  const [canEdit, setCanEdit] = useState(false)
  const [equipmentModels, setEquipmentModels] = useState({})
  const [rows, setRows] = useState<HeaderRowData[]>([])
  const [rowSelected, setSelectedRow] = useState<string | number>(-1)
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([])
  const [addButtonClicked, toggleAddRow] = useState(false)
  const [brandAdded, setBrandAdded] = useState(false)
  const [errors, setErrors] = useState<HeaderErrors>({
    name: '',
    cutWidth: '',
    widthft: '',
    widthin: '',
    make: '',
  })

  const [editObj, setEditObj] = useState({})

  const { pathname } = useLocation()

  const [prefsUnit, setPrefsUnit] = useState({})
  const [disableDeleteItem, setDisableDeleteItem] = useState(false)

  const brands = useSelector((state) => (state as AppState).brand.brands)

  useEffect(() => {
    dispatch(getHeaderTypes())
  }, [])

  useEffect(() => {
    setCanEdit(permissions.includes('farm:write'))
  }, [permissions])

  useEffect(() => {
    const prefsUnit =
      userPrefs?.units?.length_in === 'meters' ||
      userPrefs?.units?.length_in === undefined
        ? 'm'
        : 'ftIn'
    setPrefsUnit(prefsUnit)
  }, [userPrefs])

  useEffect(() => {
    if (
      pathname !== '/farmSetup/headers' &&
      props?.enableHeadersNextButton != undefined
    ) {
      if (headers && headers.length) {
        props?.enableHeadersNextButton(true)
      } else {
        props?.enableHeadersNextButton(false)
      }
    }

    const missingModelIds: (string | undefined)[] = difference(
      headers
        .filter((h) => h.equipmentModelId)
        .map((h) => (h as HeaderModel)?.equipmentModelId?.toString()),
      Object.keys(equipmentModels),
    )

    if (missingModelIds.length > 0) {
      const reqIds: EquipmentFetchModel = { ids: missingModelIds }
      fetchEquipmentModels(reqIds).then(
        (missingModels: EquipmentModelAPIRes[]) => {
          for (const missingModel of missingModels) {
            equipmentModels[missingModel.id] = missingModel
          }
          setEquipmentModels({ ...equipmentModels })
        },
      )
    }

    let _rowData: HeaderRowData[] = []
    headers.forEach((item) => {
      const brandOption = brandOptions.find((brand) => {
        return brand.id === item.makeBrandId
      })
      const modelOption = {
        id: item.equipmentModelId,
      }

      let widthin = 0
      let widthft = 0
      if (item.units === 'ftIn') {
        const calculated = toFeetInches(item?.cutWidth)
        widthin = Number(calculated.widthin)
        widthft = Number(calculated.widthft)
      }
      _rowData.push({
        id: item.id,
        modelOption: modelOption || null,
        brandOption: brandOption || null,
        name: item.name,
        headerType: item.headerType,
        cutWidth: item.cutWidth,
        units: item.units,
        year: item.year,
        widthin: widthin,
        widthft: widthft,
      })
    })
    setRows(_rowData)
  }, [headers, brandOptions])

  useEffect(() => {
    if (brandAdded) {
      let addedBrand, rowIndex
      rowIndex = rows.findIndex((row) => row.id === rowSelected)
      addedBrand = brands.filter(
        (brand) =>
          brand.name.trim() === rows[rowIndex]?.brandOption?.name?.trim(),
      )
      let item = {
        ...rows[rowIndex],
        makeBrandId: addedBrand[0].id,
      }
      rows[rowIndex] = item
      setRows(rows)
    } else {
      let _brandOptions: BrandOption[] = []
      brands.forEach((item) => {
        _brandOptions.push({
          id: item.id,
          name: item.name,
          inputValue: '',
        })
      })
      setBrandOptions(_brandOptions)
    }
  }, [brands])

  const toFeetInches = useCallback((val) => {
    if (val != '') {
      const { feet, inches } = convertToFeetInches(val)
      return {
        widthin: inches as number,
        widthft: feet as number,
      }
    } else {
      return {
        widthin: null,
        widthft: null,
      }
    }
  }, [])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent, updatedRow: HeaderRowData, newValue?: any) => {
      //@ts-ignore
      const { name, value } = e.target
      const { id } = updatedRow
      const newRows = rows.map((row) => {
        if (row.id === id) {
          if (name === 'cutWidth') {
            return { ...row, [name]: Number(value), ['units']: 'm' }
          } else if (name === 'widthin' || name === 'widthft') {
            if (name === 'widthin') {
              return {
                ...row,
                [name]: value,
                ['cutWidth']: Number(toMetres(row['widthft'], value)),
              }
            } else if (name === 'widthft') {
              return {
                ...row,
                [name]: value,
                ['cutWidth']: Number(toMetres(value, row['widthin'])),
              }
            }
          } else if (name === 'units' && value === 'ftIn') {
            const calculated = toFeetInches(row?.cutWidth)
            return {
              ...row,
              widthft: calculated.widthft,
              widthin: calculated.widthin,
              [name]: value,
            }
          } else {
            if (name === 'year') {
              /* handle an empty year */
              if (value === '') {
                return { ...row, [name]: null }
              }
            }
            return { ...row, [name]: value }
          }
        }
        validate({ ...row, [name]: value }, { [name]: value })

        return row
      })
      setRows(newRows)
    },
    [rows],
  )

  const validate = (row, data) => {
    let temp: HeaderErrors = { ...errors }
    if ('name' in data) {
      temp.name = data.name ? '' : t('required_field')
    }
    if ('cutWidth' in data) {
      temp.cutWidth =
        data.cutWidth > 0
          ? ''
          : data.cutWidth == 0
            ? 'Cut Width cannot be zero or empty'
            : t('required_field')
    }

    //If widthft is updated to zero or empty value but widthin has a non-zero number
    else if (
      'widthft' in data &&
      (data.widthft == 0 || data.widthft === '') &&
      row['widthin'] > 0
    ) {
      temp.widthft = temp.widthin = ''
    }
    //If widthft is updated to zero or empty value and widthin also is empty or zero
    else if (
      ('widthft' in data &&
        (data.widthft == 0 || data.widthft === '') &&
        (row['widthin'] == 0 || row['widthin'] === '')) ||
      ('widthin' in data &&
        (data.widthin == 0 || data.widthin === '') &&
        (row['widthft'] == 0 || row['widthft'] === ''))
    ) {
      temp.widthft = temp.widthin =
        'Width value is required, either in feet or inches.'
    }

    //If widthft is updated to take a non zero value
    else if ('widthft' in data && (data.widthft != 0 || data.widthft !== '')) {
      temp.widthft = temp.widthin = ''
    }

    //If widthin is updated
    else if ('widthin' in data && (data.widthft != 0 || data.widthft !== '')) {
      temp.widthft = temp.widthin = ''
    }
    //end
    setErrors({
      ...temp,
    })
  }

  const enableSelectedRow = useCallback(
    (row) => {
      if (disableDeleteItem) {
        setDisableDeleteItem(false)
      }
      const calculated = toFeetInches(row?.cutWidth)
      let data = { ...row }
      if (row.units === 'ftIn') {
        data = {
          ...data,
          ...{
            widthft: calculated.widthft,
            widthin: calculated.widthin,
          },
        }
      }
      setEditObj(data)
      toggleAddRow(false)
      setSelectedRow(row.id)
      if (row.units === 'ftIn') {
        const index = rows.findIndex((item) => item.id === row.id)
      } else return
    },
    [rows, disableDeleteItem],
  )

  const clearData = useCallback(
    (obj) => {
      const newRows = rows.map((row) => {
        if (row.id === obj.id) {
          return { ...row, ...editObj }
        }
        return row
      })
      setRows(newRows)
      if (!addButtonClicked) {
        setSelectedRow(-1)
      } else {
        toggleAddRow(false)
        setRows(rows.filter((item) => item.id !== obj.id))
      }
      setEditObj({})
    },
    [rows],
  )

  const addEmptyRow = useCallback(
    (e) => {
      setErrors({
        name: '',
        cutWidth: '',
        widthft: '',
        widthin: '',
        make: '',
      })
      if (disableDeleteItem) {
        setDisableDeleteItem(false)
      }
      if (addButtonClicked) {
        e.preventDefault()
      } else {
        toggleAddRow(true)
        let id = uuidv4()
        setSelectedRow(id)
        const newRow = {
          id,
          name: '',
          units: Object.keys(prefsUnit).length > 0 ? (prefsUnit as string) : '',
          cutWidth: null,
          widthft: null,
          widthin: null,
          year: null,
          headerType: 'row',
          modelOption: null,
          brandOption: null,
        }
        setRows([...rows, newRow])
        setEditObj(newRow)
      }
    },
    [headers, rows, prefsUnit, disableDeleteItem],
  )

  const toMetres = useCallback((ft, inches) => {
    return (ft * 0.3048 + inches * 0.0254).toFixed(2)
  }, [])

  const saveData = useCallback(
    async (dataToSave) => {
      let obj = {
        id: dataToSave.id,
        makeBrandId: dataToSave.brandOption?.id || null,
        equipmentModelId: dataToSave.modelOption?.id || null,
        name: dataToSave.name,
        headerType: dataToSave.headerType,
        year: dataToSave.year,
        cutWidth: dataToSave.cutWidth,
        units: dataToSave.units,
      }

      if (dataToSave['units'] !== 'm') {
        obj['cutWidth'] = parseFloat(
          toMetres(dataToSave['widthft'], dataToSave['widthin']),
        )
      }

      if (addButtonClicked) {
        await dispatch(createHeaders(obj))
      } else {
        await dispatch(editHeader(obj.id, obj))
      }
      setSelectedRow(-1)
      toggleAddRow(false)
      setEditObj({})
    },
    [headers, rows, editObj],
  )

  const disableButton = useCallback(
    (data) => {
      if (data?.name === '') {
        return true
      } else if (
        (data?.units === 'm' && data?.cutWidth === '') ||
        data?.cutWidth <= 0
      ) {
        return true
      } else if (
        data?.units === 'ftIn' &&
        data?.widthft === '' &&
        data?.widthft <= 0 &&
        data?.widthin <= 0
      ) {
        return true
      } else return false
    },
    [rows, headers, errors],
  )

  const deleteItem = useCallback(
    async (id) => {
      if (headers?.length > 1) {
        await dispatch(deleteHeader(id))
        toggleAddRow(false)
        if (rowSelected == id) setEditObj({})
      } else {
        setDisableDeleteItem(true)
      }
    },
    [addButtonClicked, headers, rowSelected, disableDeleteItem],
  )

  const onMakeChange = useCallback(
    (updatedRow, newSelectedBrandOption) => {
      const newRows = rows.map((row) => {
        if (row.id === updatedRow.id) {
          if (row.brandOption?.id !== newSelectedBrandOption?.id) {
            row.modelOption = null
          }
          row.brandOption = newSelectedBrandOption || null
        }
        return row
      })
      validate(updatedRow, { brandOption: newSelectedBrandOption })
      setRows(newRows)
    },
    [rows],
  )

  const onModelChange = useCallback(
    (updatedRow, newModelOption) => {
      const newRows = rows.map((row) => {
        if (row.id === updatedRow.id) {
          row.modelOption = newModelOption
        }
        return row
      })

      validate(updatedRow, { modelOption: newModelOption })
      setRows(newRows)
    },
    [rows],
  )

  const renderName = useCallback(
    (row) => {
      return row?.brandOption?.name || ''
    },
    [brands],
  )

  return (
    <>
      <Box px={3} py={4} width={1} style={{ overflowX: 'auto' }}>
        {pathname !== '/farmSetup/headers' ? (
          <h2> {t('select_headers')}</h2>
        ) : null}
        {disableDeleteItem ? (
          <Alert severity="error">
            {t('delete_item_disable_message', { item: t('header') })}
          </Alert>
        ) : null}
        <Box
          my={1}
          sx={{
            border: '1px solid #003057',
            borderRadius: '2px',
            overflowY: 'auto',
          }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('name')}</TableCell>

                  <TableCell align="left">{t('make')}</TableCell>
                  <TableCell align="left">{t('model')}</TableCell>

                  <TableCell align="left">{t('production_year')}</TableCell>
                  <TableCell align="left">{t('header_type')}</TableCell>
                  <TableCell align="left" colSpan={2}>
                    {t('cut_width')}
                  </TableCell>
                  <TableCell align="left">{t('units')}</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length
                  ? rows?.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {Object.keys(editObj).length &&
                            rowSelected === row.id ? (
                              <TextField
                                sx={{ width: '9ch' }}
                                value={row?.name}
                                required
                                size="small"
                                name="name"
                                disabled={row.id != rowSelected}
                                {...(row.id == rowSelected && {
                                  ...(errors['name'] && {
                                    error: true,
                                    helperText: errors['name'],
                                  }),
                                })}
                                onChange={(e) => handleInputChange(e, row)}
                              />
                            ) : (
                              row.name
                            )}
                          </TableCell>
                          {/*Make brands*/}
                          <TableCell component="th" scope="row">
                            {Object.keys(editObj).length &&
                            rowSelected === row.id ? (
                              <MakeSelectorComponent
                                onValueChange={(newBrandOption) => {
                                  onMakeChange(row, newBrandOption)
                                }}
                                value={row.brandOption}
                                row={row}
                              />
                            ) : (
                              renderName(row)
                            )}
                          </TableCell>
                          {/*make brands end*/}
                          {/*Model*/}
                          <TableCell component="th" scope="row">
                            {Object.keys(editObj).length &&
                            rowSelected === row.id ? (
                              <ModelSelectorComponent
                                makeBrandId={row?.brandOption?.id || null}
                                equipmentType="Header"
                                value={row.modelOption}
                                onValueChange={(newModelOption) => {
                                  onModelChange(row, newModelOption)
                                }}
                                disabled={row?.brandOption ? false : true}
                              />
                            ) : row.modelOption?.id ? (
                              equipmentModels[row.modelOption.id]?.name
                            ) : (
                              ''
                            )}
                          </TableCell>
                          {/*Model end*/}
                          {/*Production Year*/}
                          <TableCell align="left">
                            {Object.keys(editObj).length &&
                            rowSelected === row.id ? (
                              <TextField
                                sx={{ width: '9ch' }}
                                type="number"
                                name="year"
                                value={row.year ? row.year : ''}
                                disabled={row.id != rowSelected}
                                onChange={(e) => handleInputChange(e, row, '')}
                              />
                            ) : row.year ? (
                              row.year
                            ) : (
                              ''
                            )}
                          </TableCell>
                          {/*Production year end*/}
                          {/*Header Type*/}
                          <TableCell align="left">
                            {Object.keys(editObj).length &&
                            rowSelected === row.id ? (
                              <TextField
                                id="headerType"
                                select
                                name="headerType"
                                sx={textFieldStyle}
                                variant="outlined"
                                placeholder={t('select_header_type')}
                                value={row.headerType ? row.headerType : ''}
                                disabled={row.id != rowSelected}
                                onChange={(e) => handleInputChange(e, row)}
                                slotProps={{
                                  select: {
                                    native: true,
                                  },
                                }}
                              >
                                {headerTypes ? (
                                  headerTypes.map((item) => (
                                    <option value={item} key={item}>
                                      {t(item)}
                                    </option>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </TextField>
                            ) : (
                              t(row.headerType ? row.headerType : '')
                            )}
                          </TableCell>
                          {/*Header Type end*/}
                          {Object.keys(editObj).length ? (
                            // Display when a row is being edited, for cut width in meters and for the same row
                            // that the edit is happening
                            // When a row is being edited display for the other rows when the edit is happening
                            // Display when a row is being edited for cut width in feet and inches columns and for the same row that the edit is happening
                            rowSelected === row.id ? (
                              row?.units === 'm' ? (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  colSpan={2}
                                >
                                  <TextField
                                    sx={{ width: '9ch' }}
                                    type="number"
                                    slotProps={{ htmlInput: { min: 1 } }}
                                    name="cutWidth"
                                    value={row?.cutWidth || ''}
                                    required
                                    size="small"
                                    disabled={row.id != rowSelected}
                                    onChange={(e) => handleInputChange(e, row)}
                                    {...(row.id == rowSelected && {
                                      ...(errors['cutWidth'] && {
                                        error: true,
                                        //helperText: errors['cutWidth'],
                                      }),
                                    })}
                                  />
                                </TableCell>
                              ) : (
                                <>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    colSpan={2}
                                  >
                                    <div style={cutWidthFtInStyle}>
                                      {Object.keys(editObj).length &&
                                      rowSelected === row.id ? (
                                        <TextField
                                          sx={{ width: '9ch' }}
                                          type="number"
                                          slotProps={{ htmlInput: { min: 1 } }}
                                          name="widthft"
                                          placeholder={t('in_feet')}
                                          value={row?.widthft || ''}
                                          required
                                          size="small"
                                          disabled={row.id != rowSelected}
                                          onChange={(e) =>
                                            handleInputChange(e, row)
                                          }
                                          {...(row.id == rowSelected && {
                                            ...(errors['widthft'] && {
                                              error: true,
                                            }),
                                          })}
                                        />
                                      ) : (
                                        <p>
                                          {toFeetInches(row?.cutWidth).widthft}
                                        </p>
                                      )}
                                      {Object.keys(editObj).length &&
                                      rowSelected === row.id ? (
                                        <TextField
                                          sx={{ width: '9ch' }}
                                          style={{ marginLeft: 20 }}
                                          type="number"
                                          slotProps={{
                                            htmlInput: { min: 0, max: 12 },
                                          }}
                                          placeholder={t('in_inches')}
                                          name="widthin"
                                          value={row?.widthin || ''}
                                          required
                                          size="small"
                                          disabled={row.id != rowSelected}
                                          onChange={(e) =>
                                            handleInputChange(e, row)
                                          }
                                          {...(row.id == rowSelected && {
                                            ...(errors['widthin'] && {
                                              error: true,
                                              // helperText: errors['widthin'],
                                            }),
                                          })}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: 20 }}>
                                          {toFeetInches(row?.cutWidth).widthin}
                                        </p>
                                      )}
                                    </div>
                                  </TableCell>
                                </>
                              )
                            ) : row.units === 'm' ? (
                              <TableCell component="th" scope="row" colSpan={2}>
                                {row.cutWidth}
                              </TableCell>
                            ) : (
                              <>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  colSpan={2}
                                >
                                  <div style={cutWidthFtInStyle}>
                                    <p>{`${
                                      toFeetInches(row?.cutWidth).widthft
                                    }'`}</p>
                                    <p style={{ marginLeft: 20 }}>
                                      {`${toFeetInches(row?.cutWidth).widthin}"`}
                                    </p>
                                  </div>
                                </TableCell>
                              </>
                            )
                          ) : //End
                          // On load display of the cut width column - editObj is not available - start
                          row.units === 'm' ? (
                            <TableCell component="th" scope="row" colSpan={2}>
                              {row.cutWidth}
                            </TableCell>
                          ) : (
                            // On load display of the cut width in feet and inches - editObj is not available - start
                            <>
                              <TableCell component="th" scope="row" colSpan={2}>
                                <div style={cutWidthFtInStyle}>
                                  <p>{`${
                                    toFeetInches(row?.cutWidth).widthft
                                  }'`}</p>
                                  <p style={{ marginLeft: 20 }}>
                                    {`${toFeetInches(row?.cutWidth).widthin}"`}
                                  </p>
                                </div>
                              </TableCell>
                            </>
                            // end
                          )}
                          <TableCell align="left">
                            {rowSelected === row.id ? (
                              <TextField
                                id="units"
                                sx={{ width: '9ch' }}
                                select
                                name="units"
                                size="small"
                                placeholder={'Please select your Units'}
                                value={row?.units}
                                disabled={row.id != rowSelected}
                                onChange={(e) => handleInputChange(e, row)}
                                {...(row.id == rowSelected && {
                                  ...(errors['units'] && {
                                    error: true,
                                    helperText: errors['units'],
                                  }),
                                })}
                                slotProps={{
                                  select: {
                                    native: true,
                                  },
                                }}
                              >
                                <option value="ftIn">{t('feet_inches')}</option>
                                <option value="m">{t('meters')}</option>
                              </TextField>
                            ) : row?.units === 'm' ? (
                              t('meters')
                            ) : (
                              t('feet_inches')
                            )}
                          </TableCell>
                          {canEdit && (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ borderBottom: 'none' }}
                            >
                              {!rowSelected || row.id != rowSelected ? (
                                <Box display="flex">
                                  <IconButton
                                    color="secondary"
                                    aria-label="edit the item"
                                    sx={iconButtonStyle}
                                    id="editBtn"
                                    onClick={() => enableSelectedRow(row)}
                                    disabled={!isEmpty(editObj)}
                                    size="large"
                                  >
                                    <ModeEditOutlineOutlinedIcon
                                      fontSize="small"
                                      style={{ color: colors.brandLight }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    aria-label="delete the item"
                                    sx={iconButtonStyle}
                                    id="deleteBtn"
                                    onClick={() => deleteItem(row.id)}
                                    size="large"
                                  >
                                    <DeleteOutlineOutlinedIcon
                                      fontSize="small"
                                      style={{ color: '#B2023B' }}
                                    />
                                  </IconButton>
                                </Box>
                              ) : (
                                <div hidden={row.id != rowSelected}>
                                  <IconButton
                                    color="secondary"
                                    aria-label={t('save')}
                                    sx={iconButtonStyle}
                                    id="saveBtn"
                                    disabled={disableButton(row)}
                                    onClick={() => saveData(row)}
                                    size="large"
                                  >
                                    <SaveOutlinedIcon
                                      fontSize="small"
                                      style={{ color: colors.brandLight }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    aria-label={t('cancel')}
                                    sx={iconButtonStyle}
                                    id="cancelBtn"
                                    onClick={() => clearData(row)}
                                    size="large"
                                  >
                                    <ClearIcon
                                      fontSize="small"
                                      style={{ color: '#B2023B' }}
                                    />
                                  </IconButton>
                                </div>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {canEdit && (
          <Box display="flex">
            <Button
              variant="outlined"
              aria-label="add header"
              onClick={(e) => addEmptyRow(e)}
              sx={{ borderRadius: '25px', marginTop: '20px' }}
              endIcon={<AddIcon />}
            >
              {t('add_header')}
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}
