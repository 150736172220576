import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
//@ts-expect-error
import { getDeviceString } from 'common/misc'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import React, { ReactElement } from 'react'

//@ts-expect-error
import * as c from 'common/c'
//@ts-expect-error
import type { AppState } from 'state/index'

const borderBottomCustomStyle = { borderBottom: 'none' }

export default function HardwareComponent(): ReactElement<any, any> {
  const { t } = useTranslation()
  const customClaims = useSelector(
    (state: AppState) => state.account.customClaims,
  )
  const permissions = useSelector(
    (state) => (state as AppState).account.permissions,
  )
  const isAdmin =
    permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM]

  let hardware = useSelector((state) =>
    get(state as AppState, 'hardware.collection', []),
  )

  return (
    <>
      {/* <Box width={1} > */}
      {
        <Box px={3} py={4} width={1} style={{ overflowX: 'auto' }}>
          {
            <Box
              my={1}
              sx={{ border: '1px solid #003057', borderRadius: '2px' }}
            >
              {
                <TableContainer>
                  {
                    <Table aria-label="FarmTRX Hardware table" stickyHeader>
                      {
                        <TableHead>
                          {
                            <TableRow>
                              {
                                <TableCell align="left">
                                  {t('hardware_id')}
                                </TableCell>
                              }
                              {
                                <TableCell align="left">
                                  {t('hardware_type')}{' '}
                                </TableCell>
                              }
                              {
                                <TableCell align="left">
                                  {t('hardware_name')}
                                </TableCell>
                              }
                              {isAdmin ? (
                                <TableCell align="left">
                                  {t('hardware_version')}{' '}
                                </TableCell>
                              ) : null}
                              {
                                <TableCell align="left">
                                  {t('firmware_version')}
                                </TableCell>
                              }
                            </TableRow>
                          }
                        </TableHead>
                      }
                      {
                        <TableBody>
                          {hardware && hardware.length ? (
                            hardware &&
                            hardware.map((row, index) => (
                              <TableRow key={index}>
                                {
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={borderBottomCustomStyle}
                                  >
                                    {row.farmtrxHardwareId}
                                  </TableCell>
                                }
                                {
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={borderBottomCustomStyle}
                                  >
                                    {t(row.hardwareType.toLowerCase())}
                                  </TableCell>
                                }
                                {
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={borderBottomCustomStyle}
                                  >
                                    {getDeviceString(row.deviceType)}
                                  </TableCell>
                                }
                                {isAdmin ? (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={borderBottomCustomStyle}
                                  >
                                    {row.hardwareVersion}
                                  </TableCell>
                                ) : null}
                                {
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={borderBottomCustomStyle}
                                  >
                                    {Array.isArray(
                                      (row as any)?.metadata?.firmware_version,
                                    ) &&
                                    (row as any)?.metadata?.firmware_version
                                      .length > 0
                                      ? (row as any).metadata.firmware_version[
                                          (row as any)?.metadata
                                            ?.firmware_version.length - 1
                                        ].version
                                      : t('unknown')}
                                  </TableCell>
                                }
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              {
                                <TableCell>
                                  {
                                    <Box width={1} justifyContent="center">
                                      {t('hw_populate_cloud_upload_msg')}
                                    </Box>
                                  }
                                </TableCell>
                              }
                            </TableRow>
                          )}
                        </TableBody>
                      }
                    </Table>
                  }
                </TableContainer>
              }
            </Box>
          }
        </Box>
      }
    </>
  )
}
