//@ts-expect-error
import * as c from 'common/c'
import { get, findIndex, cloneDeep } from 'lodash-es'
declare module 'state/job.js' {
  export type JobData = {
    jobType: string
    id: string
    status?: string
    metadata?: string
    tenantId: string
    createdAt: string
    updatedAt: string
  }
  interface Job {
    collection: JobData[]
  }
}

const initialState = {
  collection: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.CREATE_JOB: {
      //@ts-expect-error
      return update(state, {
        collection: { $unshift: [action.payload] },
      })
    }

    case c.UPDATE_JOB: {
      let jobs: JobData[] = cloneDeep(state.collection)
      const item = action.payload
      const index = findIndex(
        get(state, 'collection', []),
        (job: JobData) => job.id === item.id,
      )

      if (index > -1) {
        jobs[index] = item
      }
      //@ts-expect-error
      return update(state, { collection: { $set: jobs } })
    }

    case c.REMOVE_JOB: {
      const index = findIndex(
        get(state, 'collection', []),
        (job: JobData) => job.id === action.payload,
      )

      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          collection: { $splice: [[index, 1]] },
        })
      } else return
    }
    default:
      return state
  }
}
