import { createFilterOptions } from '@mui/material/Autocomplete'
import { Autocomplete, Box, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortBy } from 'lodash-es'
import { useCallback, useEffect, useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createEquipmentModel,
  EquipmentFetchModel,
  fetchEquipmentModels,
  //@ts-expect-error
} from 'api/equipment-model'
//@ts-expect-error
import type { EquipmentModelAPIRes } from 'api/equipment-model'
import { FilterOptionsState } from '@mui/material'

declare module 'components/farm-settings/equipment/model-selector.js' {
  export type ModelSelectorProps = {
    makeBrandId: number | null
    equipmentType: string | null
    value: Partial<ModelOption> | null
    onValueChange: Function
    onLoad?: boolean
    disabled?: boolean
  }
  type ModelOption = {
    id: number | null
    inputValue: string | undefined
    name: string
    makeBrandId: number | null
    equipmentType: string
  }
  export function ModelSelectorComponent(
    props: ModelSelectorProps,
  ): ReactElement<any, any>
}

export default function ModelSelectorComponent(
  props: ModelSelectorProps,
): ReactElement<any, any> {
  const { t } = useTranslation()
  const filter = createFilterOptions()

  const { makeBrandId, equipmentType, value, onValueChange, onLoad, disabled } =
    props

  const [isLoading, setIsLoading] = useState(true)
  const [models, setModels] = useState<Partial<ModelOption>[]>([])
  const [selectedVal, setSelectedVal] = useState<Partial<ModelOption | null>>({
    id: null,
    name: '',
  })

  const fetchModelsFromApi = useCallback(() => {
    setIsLoading(true)
    if (makeBrandId != null && equipmentType != null) {
      const data: EquipmentFetchModel = { makeBrandId, equipmentType }
      fetchEquipmentModels(data).then((apiModels: EquipmentModelAPIRes[]) => {
        const sortedModels: EquipmentModelAPIRes[] = [
          ...sortBy<EquipmentModelAPIRes>(
            apiModels.filter((m) => {
              const val = parseFloat(m.name)
              return !isNaN(val)
            }),
            (value) => parseFloat(value.name),
          ),
          ...sortBy(
            apiModels.filter((m) => {
              const val = parseFloat(m.name)
              return isNaN(val)
            }),
            ['name'],
          ),
        ]

        let setSelection = false
        const options: ModelOption[] = sortedModels.map((item) => {
          const newItem = {
            id: item.id,
            name: item.name,
            inputValue: '',
            makeBrandId: item.makeBrandId,
            equipmentType: item.equipmentType,
          }
          if (newItem.id == value?.id) {
            setSelection = true
            setSelectedVal(newItem)
          }
          return newItem
        })
        setModels(options)
        if (!setSelection) {
          const selection = options.find((option) => option.id === value?.id)
          setSelectedVal(selection || null)
        }

        setIsLoading(false)
      })
    } else {
      setModels([])
      setIsLoading(false)
      setSelectedVal(null)
    }
  }, [makeBrandId, equipmentType])

  useEffect(() => {
    fetchModelsFromApi()
    setSelectedVal(null)
  }, [makeBrandId, fetchModelsFromApi])

  const handleModelChange = useCallback(
    (e, newValue) => {
      if (newValue && newValue.inputValue != '') {
        // Add a new model
        createEquipmentModel({
          makeBrandId,
          equipmentType,
          name: newValue.inputValue,
        } as Partial<EquipmentModelAPIRes>).then((newModel) => {
          const newModelOption = {
            id: (newModel as EquipmentModelAPIRes).id,
            name: (newModel as EquipmentModelAPIRes).name,
            makeBrandId: (newModel as EquipmentModelAPIRes).makeBrandId,
            equipmentType: (newModel as EquipmentModelAPIRes).equipmentType,
            inputValue: '',
          }
          setModels([...models, newModelOption])
          setSelectedVal(newModelOption)
          onValueChange(newModelOption)
        })
      } else {
        setSelectedVal(newValue)
        onValueChange(newValue)
      }
    },
    [makeBrandId, models],
  )

  const renderName = useCallback((selectedVal) => {
    return selectedVal?.name
  }, [])

  return isLoading ? (
    <div>{t('loading')}</div>
  ) : !onLoad ? (
    <Autocomplete
      disabled={disabled}
      key="model-selector"
      value={selectedVal}
      onChange={(e, newValue) => handleModelChange(e, newValue)}
      filterOptions={(
        options: Partial<ModelOption>[],
        params: FilterOptionsState<Partial<ModelOption>>,
      ) => {
        const filtered = filter(options, params)
        const { inputValue } = params

        const exists = models.find(
          (m) => m.name?.toLowerCase() === inputValue?.toLowerCase(),
        )

        if (inputValue !== '' && !exists) {
          filtered.push({
            inputValue,
            name: `${t('add')} "${inputValue}"`,
          })
        }

        return filtered
      }}
      id="equipment-model-selector"
      options={models}
      getOptionLabel={(option: ModelOption) => {
        if (option.inputValue) {
          return option.inputValue
        }
        return option.name
      }}
      renderOption={(props, option: ModelOption) => (
        <li {...props}>
          <Box display="flex" alignItems="center">
            {option.inputValue !== '' ? (
              <Box>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </Box>
            ) : (
              ''
            )}
            <Box ml={1}>{option.name}</Box>
          </Box>
        </li>
      )}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('select_model')}
          variant="outlined"
          placeholder={t('select_model')}
        />
      )}
    />
  ) : (
    <>{renderName(selectedVal)}</>
  )
}
