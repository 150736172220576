import Integrations from './integration.tsx'
import { useMemo } from 'react'

export default function IntegrationsPanel() {
  const availableIntegrations = useSelector(
    (state) => state.integrations.availableIntegrations,
  )
  const enabledIntegrations = useSelector(
    (state) => state.integrations.tenantIntegrations,
  )

  const el = useMemo(() => {
    return (
      <Integrations
        availableIntegrations={availableIntegrations}
        enabledIntegrations={enabledIntegrations}
      />
    )
  }, [availableIntegrations, enabledIntegrations])

  return <>{el}</>
}
