//@ts-expect-error
import ModalContainer from 'components/modal/modal-container'
//@ts-expect-error
import ModalActions from 'components/modal/modal-actions'
//@ts-expect-error
import { getYieldHistogram } from 'api/harvest'
//@ts-expect-error
import { AppState } from 'state/index'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useState, ReactElement, useEffect } from 'react'

export type HistogramProps = {
  harvestId: number
  layerType: string
}

export default function Histogram(props: HistogramProps) {
  const userPrefs = useSelector(
    (state: AppState) => state.account.singleton.prefs,
  )
  const yieldUnits = userPrefs?.units?.yieldunit_in

  const { t } = useTranslation()
  const [histogram, setHistogram] = useState<any>(String)
  const [isLoading, setIsLoading] = useState(true)

  const getHistogram = useCallback(() => {
    setIsLoading(true)
    getYieldHistogram(props.harvestId, props.layerType, yieldUnits).then(
      (data: String) => {
        setHistogram(data)
        setIsLoading(false)
      },
    )
  }, [props.harvestId, props.layerType, yieldUnits])

  useEffect(() => {
    getHistogram()
  }, [])

  return isLoading ? (
    <div>{t('loading')}</div>
  ) : (
    <ModalContainer>
      <ModalActions>
        <img
          src={`data:image/png;base64,${histogram}`}
          width="500"
          alt="histogram"
        />
      </ModalActions>
    </ModalContainer>
  )
}
