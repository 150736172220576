import styled from 'styled-components'
import { Muted } from 'components/foundation'
import { FormGroup, FormControl } from './foundation'

export default function NumberField(props) {
  const {
    name,
    defaultValue = 0,
    label,
    hint,
    onFocus = () => {},
    // onChange = () => {},
    // onKeyUp = () => {},
    placeholder = '',
  } = props

  const [value, setValue] = useState(defaultValue)

  function onChange(e) {
    setValue(e.target.value)
    if (props.onChange) {
      props.onChange(e)
    }
  }

  function onKeyUp(e) {
    if (props.onKeyUp) {
      props.onKeyUp(e)
    }
  }

  return (
    <FormGroup {...props}>
      <label htmlFor={name}>{label}</label>
      <StyledFormControl
        type="number"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
      />
      {hint ? <Muted>{hint}</Muted> : null}
    </FormGroup>
  )
}

const StyledFormControl = styled(FormControl)`
  text-align: right;
`

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
  hint: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
}

NumberField.displayName = 'NumberField'
