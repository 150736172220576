import { ReactElement } from 'react'
import styled from 'styled-components'

export type LayerGroupProps = {
  children: ReactElement
}
export type LayerGroupHeaderProps = {
  children: ReactElement
}
export type LayerGroupItemProps = {
  children: ReactElement
}
export default function LayerGroup({ children }: LayerGroupProps) {
  return <StyledRoot>{children}</StyledRoot>
}

LayerGroup.Header = function ({ children }: LayerGroupHeaderProps) {
  return <StyledHeader>{children}</StyledHeader>
}

LayerGroup.Items = function ({ children }: LayerGroupItemProps) {
  return <StyledList>{children}</StyledList>
}

LayerGroup.Item = function ({ children, show = true, onClick }) {
  return show ? (
    <StyledListItem onClick={() => onClick()}>{children}</StyledListItem>
  ) : null
}

//@ts-ignore
const StyledRoot = styled.div`
  margin: 0 0 0 12px;
`

//@ts-ignore
const StyledHeader = styled.h1`
  font-size: 11px;
  font-weight: 700;
  color: #70787d;
  opacity: 0.8;
  text-transform: uppercase;
`

//@ts-ignore
const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`

//@ts-ignore
const StyledListItem = styled.li`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 6px;
  }
`
