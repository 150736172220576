import { Box, InputAdornment, FormControl, OutlinedInput } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { styled } from '@mui/system'

function _searchField({ onSearch, name, placeholder, className }) {
  const { t } = useTranslation()

  const onChange = useCallback((e) => {
    onSearch(e.target.value)
  })

  // return (<input placeholder={t('search_by_name')} value={name} onChange={onChange} />)
  return (
    <Box className="is_visible">
      <FormControl className={className}>
        <OutlinedInput
          sx={{
            borderColor: '#D4D4D4',
            color: '#4A4A4A',
            opacity: 0.8,
            height: '100%',
            maxHeight: '48px',
            fontSize: '12px',
            '& svg': {
              fontSize: '1.5em',
            },
          }}
          variant="outlined"
          size="small"
          value={name}
          onChange={onChange}
          id="input-with-icon-adornment"
          placeholder={placeholder ?? t('search_by_name')}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  )
}

const SearchField = styled(_searchField)``
export default SearchField
