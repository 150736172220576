import styled from 'styled-components'
import { DefaultShadow } from 'components/container'
styled(DefaultShadow)

//@ts-ignore
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 94vh;
  bottom: 0;
  left: 0;
  right: 0;
`

//@ts-ignore
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  margin: 0;
`

export const SplitGridWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr 1fr;
`

const StyledSidePanel = styled(DefaultShadow)`
  position: absolute;
  width: 250px;
  height: 100%;
  margin: 0;
  left: 0px;
  right: 250px;
`

//@ts-ignore
const StyledContent = styled.div`
  flex-grow: 1;
  margin: 0;
  left: 250px;
  position: absolute;
  width: calc(100% - 250px);
  height: 100%;
`

export const SidePanel = ({ children }) => {
  return <StyledSidePanel id="sidePanel">{children}</StyledSidePanel>
}

export const PageContent = ({ children }) => {
  return <StyledContent id="pageContent">{children}</StyledContent>
}
