//@ts-expect-error
import * as c from 'common/c'
import update from 'immutability-helper'
import { get, findIndex, cloneDeep } from 'lodash-es'

declare module 'state/farm.js' {
  export type FarmIntegrationData = {
    id: string
    clients: string
    fields: string
    self: string
    owningOrganization: string
    archived: boolean
  }
  export type FarmModel = {
    id: number
    name: string
    streetAddress?: string
    postalCode?: string
    email?: string
    clientId: number
    integrationIds: { [key: string]: FarmIntegrationData }
  }
  export type Farm = {
    collection: FarmModel[]
    singleton: FarmModel | undefined
    selectedFarms: []
  }
  export type FarmOptionModel = {
    id: number
    name: string
  }
}

const initialState = {
  collection: [],
  singleton: undefined,
  selectedFarms: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.FARM_CREATED: {
      //@ts-expect-error
      return update(state, {
        collection: { $unshift: [action.payload] },
        singleton: { $set: action.payload },
      })
    }
    case c.FARMS_FETCHED: {
      //@ts-expect-error
      return update(state, {
        collection: { $set: action.payload },
        singleton: { $set: action.payload[0] },
      })
    }

    // case c.FARM_PREFERENCES_FETCHED: {
    //   const preferences = action.payload
    //   return update(state, { prefs: { $set: preferences } })
    // }

    case c.FARMSETTINGS_EMPTY_ITEM_DELETED: {
      const column = action.payload
      let index = state[column].findIndex((p) => p.temp === true)
      //@ts-expect-error
      return update(state, { [column]: { $splice: [[index, 1]] } })
    }

    case c.FARM_UPDATED: {
      const index = findIndex(
        get(state, 'collection', []),
        (farm: FarmModel) => farm.id === action.payload.id,
      )

      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          collection: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }

    case c.FARMS_UPDATED: {
      let farms: FarmModel[] = cloneDeep(state.collection)

      action.payload.map((item) => {
        const index = findIndex(
          get(state, 'collection', []),
          (farm: FarmModel) => farm.id === item.id,
        )

        if (index > -1) {
          farms[index] = item
        }
      })
      //@ts-expect-error
      return update(state, { collection: { $set: farms } })
    }

    case c.FARM_DELETED: {
      const index = findIndex(
        get(state, 'collection', []),
        (farm: FarmModel) => farm.id === action.payload,
      )

      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          collection: { $splice: [[index, 1]] },
        })
      } else return
    }

    case c.FARMS_SELECTED: {
      const farmIds = action.payload
      //@ts-expect-error
      return update(state, {
        selectedFarms: {
          $set: farmIds,
        },
      })
    }

    default:
      return state
  }
}
