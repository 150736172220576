import { Box, Drawer, List, Toolbar } from '@mui/material'

export default function CommonSidebar(props) {
  var drawerWidth = 250

  return (
    <Drawer
      variant="permanent"
      className="sidePanelSidebar"
      style={{
        width: drawerWidth,
        flexShrink: 0,
      }}
      PaperProps={{
        sx: {
          zIndex: '999',
        },
      }}
    >
      <Toolbar className="sidePanelSidebar" />
      <Box className="sidePanelSidebar" style={{ overflow: 'auto' }}>
        <List style={{ width: drawerWidth }}>{props.children}</List>
      </Box>
    </Drawer>
  )
}
