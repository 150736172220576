import styled from 'styled-components'
import LayerGroup from './layer-group.tsx'
import ItemSwitch from './item-switch.tsx'
import { vectorLayers, rasterLayers } from 'common/layer-data'
import { cloneDeep } from 'lodash-es'
import {
  SubscriptionsOutlined as SubscriptionsIcon,
  RadioButtonChecked,
  RadioButtonUnchecked,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { isSubscriptionValid } from 'common/misc'

export default function LayersGroup(props) {
  const {
    layerInfoMap,
    harvestLayers,
    hideLayerLegend,
    removeLayer,
    showLayer,
    harvestId,
    setLayers,
    showVectorLayers = true,
    showPremiumMsg,
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const selectedHarvest = useSelector((state) => state.harvest.current)

  const currentView = useSelector((state) => state.app.view)

  const subscriptions = useSelector((state) => state.account.subscriptions)

  /*If Harvest is not selected then set the first array from the harvests state object as the default selection in the split screen window.*/
  const harvests = useSelector((state) => state.harvest.collection)

  const [activeStatus, setActiveStatus] = useState(false)

  //Check if the user has active subscription
  useEffect(() => {
    /*Fix the Renew FarmTRX Premium message*/
    let defaultHarvest = {}
    if (Object.keys(selectedHarvest).length) {
      defaultHarvest = Object.assign(defaultHarvest, selectedHarvest)
    } else {
      defaultHarvest = Object.assign(defaultHarvest, harvests[0])
    }

    setActiveStatus(
      isSubscriptionValid(
        subscriptions,
        defaultHarvest?.startDate,
        defaultHarvest?.endDate,
      ),
    )
  }, [])

  useEffect(() => {
    if (currentView === 'split') {
      if (showPremiumMsg) {
        setActiveStatus(false)
      } else {
        setActiveStatus(true)
      }
    }
  }, [showPremiumMsg])

  const baseVectorLayers = useMemo(() => {
    const _baseVectorLayers = {}
    for (let i = 0; i < vectorLayers.length; i++) {
      _baseVectorLayers[vectorLayers[i].name] = vectorLayers[i]
    }
    return _baseVectorLayers
  }, [vectorLayers])
  const baseRasterLayers = useMemo(() => {
    const _baseRasterLayers = {}
    for (let i = 0; i < rasterLayers.length; i++) {
      _baseRasterLayers[rasterLayers[i].name] = rasterLayers[i]
    }
    return _baseRasterLayers
  }, [rasterLayers])

  const toggle = useCallback(
    (v) => {
      if (harvestId) {
        let o = {}
        if (harvestLayers[v] != null) {
          o[v] = cloneDeep(harvestLayers[v])
          o[v].selected = !o[v].selected
          if (harvestLayers[v].selected && v !== 'boundary') {
            dispatch(hideLayerLegend({ name: v, harvest: harvestId }))
            dispatch(removeLayer({ name: v, harvest: harvestId }))
          }
        } else if (harvestLayers == null) {
          o = Object.assign({}, baseRasterLayers, baseVectorLayers)
          o[v].selected = !o[v].selected
        }
        const layer = layerInfoMap[v]
        if (o[v].selected && layer) {
          dispatch(
            showLayer({
              name: v,
              legend: layer.legendUrl,
              harvest: layer.harvestId,
              bbox: [
                layer.bboxMiny,
                layer.bboxMinx,
                layer.bboxMaxy,
                layer.bboxMaxx,
              ].join(','),
              zindex: harvestLayers[v].zindex,
            }),
          )
        }

        o = Object.assign({}, harvestLayers, o)
        if (baseRasterLayers[v] != null) {
          for (let i in rasterLayers) {
            let name = rasterLayers[i].name
            if (name !== v && harvestLayers[name].selected) {
              o[name] = harvestLayers[name]
              if (harvestLayers[name].selected) {
                o[name].selected = !harvestLayers[name].selected
                dispatch(hideLayerLegend({ name: name, harvest: harvestId }))
                dispatch(removeLayer({ name: name, harvest: harvestId }))
              }
            }
          }
        }
        const newLayers = {}
        newLayers[harvestId] = o
        dispatch(setLayers(newLayers))
      }
    },
    [
      harvestLayers,
      harvestId,
      baseVectorLayers,
      rasterLayers,
      baseRasterLayers,
      layerInfoMap,
    ],
  )

  return harvestId != null ? (
    <StyledRoot>
      {showVectorLayers ? (
        <LayerGroup>
          <LayerGroup.Header>{t('boundary_and_points')}</LayerGroup.Header>
          <LayerGroup.Items>
            <LayerGroup.Item onClick={() => toggle('boundary')}>
              {harvestLayers?.boundary?.selected ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <ItemSwitch
                on={harvestLayers?.boundary?.selected ? t('boundary') : ''}
              >
                {t('boundary')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['raw_points'] !== undefined}
              onClick={() => toggle('raw_points')}
            >
              {harvestLayers?.raw_points?.selected ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <ItemSwitch
                on={
                  harvestLayers?.raw_points?.selected
                    ? t('raw_yield_points')
                    : ''
                }
              >
                {t('raw_yield_points')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['corrected_points'] !== undefined}
              onClick={() => toggle('corrected_points')}
            >
              {harvestLayers?.corrected_points?.selected ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <ItemSwitch
                on={
                  harvestLayers?.corrected_points?.selected
                    ? t('corrected_yield_points')
                    : ''
                }
              >
                {t('corrected_yield_points')}
              </ItemSwitch>
            </LayerGroup.Item>
          </LayerGroup.Items>
        </LayerGroup>
      ) : null}
      <LayerGroup>
        <LayerGroup.Header>{t('maps')}</LayerGroup.Header>
        {!activeStatus ? (
          <LayerGroup.Items>
            <LayerGroup.Item>
              <ItemSwitch
                sx={{
                  borderLeft: '8px solid #ff00006b !important',
                  background: '#ff00001a !important',
                  color: 'red !important',
                  fontWeight: 'bold !important',
                }}
                title={t('no_subscriptions_raw_points')}
                onClick={() => navigate('/admin/subscription')}
              >
                <span> {t('renew_subscription')}</span>
                <SubscriptionsIcon
                  size="small"
                  style={{
                    color: '#B2023B',
                    height: '0.7em',
                    verticalAlign: 'sub',
                  }}
                  titleAccess={t('no_subscriptions_raw_points')}
                />
              </ItemSwitch>
            </LayerGroup.Item>
          </LayerGroup.Items>
        ) : (
          <LayerGroup.Items>
            <LayerGroup.Item
              show={layerInfoMap['corrected_yield'] !== undefined}
              onClick={() => toggle('corrected_yield')}
            >
              {harvestLayers?.corrected_yield?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.corrected_yield.selected}>
                {t('corrected_yield_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['zones_yield'] !== undefined}
              onClick={() => toggle('zones_yield')}
            >
              {harvestLayers?.zones_yield?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.zones_yield.selected}>
                {t('classified_yield_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['trend_yield'] !== undefined}
              onClick={() => toggle('trend_yield')}
            >
              {harvestLayers?.trend_yield?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.trend_yield.selected}>
                {t('yield_map_trend')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['raw_raster'] !== undefined}
              onClick={() => toggle('raw_raster')}
            >
              {harvestLayers?.raw_raster?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.raw_raster.selected}>
                {t('raw_yield_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['raw_moisture'] !== undefined}
              onClick={() => toggle('raw_moisture')}
            >
              {harvestLayers?.raw_moisture?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.raw_moisture.selected}>
                {t('raw_moisture_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['trend_moisture'] !== undefined}
              onClick={() => toggle('trend_moisture')}
            >
              {harvestLayers?.trend_moisture?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.trend_moisture.selected}>
                {t('trend_moisture_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['swath'] !== undefined}
              onClick={() => toggle('swath')}
            >
              {harvestLayers?.swath?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.swath.selected}>
                {t('swath_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            {/* <LayerGroup.Item
              show={layerInfoMap['header_height'] !== undefined}
              onClick={() => toggle('header_height')}
            >
              {harvestLayers?.header_height?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.header_height.selected}>
                {t('header_height')}
              </ItemSwitch>
            </LayerGroup.Item> */}
          </LayerGroup.Items>
        )}
      </LayerGroup>
    </StyledRoot>
  ) : null
}

//@ts-ignore
const StyledRoot = styled.div`
  margin: 0 0 0 12px;
`
