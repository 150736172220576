import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import React, { useEffect, useState, ReactElement } from 'react'

//@ts-expect-error
import type { AppState } from 'state/index'
//@ts-expect-error
import { getUbjUploads } from 'api/ubj'
//@ts-expect-error
import * as c from 'common/c'

declare module 'components/ubj-uploads/index.js' {
  export type featuresOverviewType = {
    id: number
    geometry: object
    properties: object
  }

  export type fieldAreaMapType = {
    id: number
    area: number
    farmId: number
  }

  export type UbjRowData = {
    id: string
    upload_date: string
    status: string
    file_date: string
    file_size: string
    bucket: string
    ym_id: string
    file_name: string
  }

  export type ubjUploadColumns = {
    field: string
    headerName: string
    minWidth: number
    maxWidth: number
    flex: number
  }
}

export default function UbjUploadsComponent(): ReactElement<any, any> {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const permissions = useSelector(
    (state) => (state as AppState).account.permissions,
  )
  const uploads = useSelector((state) =>
    get(state as AppState, 'ubjUploads.uploads', []),
  )

  const customClaims = useSelector(
    (state: AppState) => state.account.customClaims,
  )

  const [columns, setColumns] = useState<ubjUploadColumns[]>([])
  const [rows, setRows] = useState<UbjRowData[]>([])
  const isAdmin =
    permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM]
  const [columnVisibility, setColumnVisibility] = useState({
    upload_date: true,
    status: isAdmin ?? false,
    file_date: true,
    file_size: isAdmin ?? false,
    bucket: isAdmin ?? false,
    ym_id: true,
    file_name: isAdmin ?? false,
  })

  useEffect(() => {
    const _columns: ubjUploadColumns[] = [
      {
        field: 'upload_date',
        headerName: t('upload_date'),
        maxWidth: 300,
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'status',
        headerName: t('status'),
        minWidth: 70,
        maxWidth: 110,
        flex: 1,
      },
      {
        field: 'file_date',
        headerName: t('file_date'),
        maxWidth: 300,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'file_size',
        headerName: t('file_size'),
        minWidth: 80,
        maxWidth: 100,
        flex: 1,
      },
      {
        field: 'bucket',
        headerName: t('bucket'),
        minWidth: 180,
        maxWidth: 250,
        flex: 1,
      },
      {
        field: 'ym_id',
        headerName: t('ym_id'),
        minWidth: 130,
        maxWidth: 200,
        flex: 1,
      },
      {
        field: 'file_name',
        headerName: t('file_name'),
        minWidth: 200,
        maxWidth: 300,
        flex: 1,
      },
    ]
    setColumns(_columns)
  }, [uploads])

  function CustomNoRowsOverlayFields(): ReactElement {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {t('no_uploads')}
      </div>
    )
  }

  useEffect(() => {
    let _rows: UbjRowData[] = []
    uploads.forEach((upload) => {
      let upload_date = new Date(Number(upload.rxtime) * 1000).toLocaleString()
      if (upload.data.epochseconds) {
        upload_date = new Date(Number(upload.rxtime)).toLocaleString()
      }
      _rows.push({
        id: String(upload.rxtime),
        upload_date: upload_date,
        status: upload.data.status,
        file_date: new Date(
          Number(upload.data.ubjTime) * 1000,
        ).toLocaleString(),
        file_size: String(upload.data.size),
        bucket: upload.data.bucket,
        ym_id: upload.data.ymId,
        file_name: upload.data.ubjFileName,
      })
    })
    setRows(_rows)
  }, [uploads])

  useEffect(() => {
    dispatch(getUbjUploads())
  }, [])

  return (
    <>
      <Box
        px={3}
        py={4}
        width={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          height: '80%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h2>{t('harvest_data_uploads')} </h2>
        </Box>
        <Box
          my={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            height: '80%',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              height: '100%',
              width: '100%',
              marginTop: '2rem',
            }}
          >
            <DataGrid
              autoPageSize
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={(model: {
                upload_date: true
                status: true
                file_date: true
                file_size: true
                bucket: true
                ym_id: true
                file_name: true
              }) => setColumnVisibility(model)}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'upload_date', sort: 'desc' }],
                },
              }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlayFields,
              }}
              rows={rows}
              columns={columns}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#f8f8f9',
                  textTransform: 'uppercase',
                },
              }}
              pageSizeOptions={[20]}
            />
          </div>
        </Box>
      </Box>
    </>
  )
}
