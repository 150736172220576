import { combineReducers } from 'redux'
//@ts-expect-error
import account from 'state/account'
//@ts-expect-error
import app from 'state/app'
//@ts-expect-error
import field from 'state/field'
//@ts-expect-error
import map from 'state/map'
//@ts-expect-error
import farm from 'state/farm'
//@ts-expect-error
import cropVariety from 'state/crop-variety'
//@ts-expect-error
import crop from 'state/crop'
//@ts-expect-error
import brand from 'state/brand'
//@ts-expect-error
import equipment from 'state/equipment'
//@ts-expect-error
import hardware from 'state/hardware'
//@ts-expect-error
import headers from 'state/headers'
//@ts-expect-error
import integrations from 'state/integrations'
//@ts-expect-error
import client from 'state/client'
//@ts-expect-error
import ubjUploads from 'state/ubj-upload'
//@ts-expect-error
import harvest from 'state/harvest'
//@ts-expect-error
import job from 'state/job'
//@ts-expect-error
import notification from './notification'

//@ts-expect-error
import type { Account } from 'state/account'
//@ts-expect-error
import type { Hardware } from 'state/hardware'
//@ts-expect-error
import type { Equipment } from 'state/equipment'
//@ts-expect-error
import type { Header } from 'state/headers'
//@ts-expect-error
import type { Crop } from 'state/crop'
//@ts-expect-error
import type { Client } from 'state/client'
//@ts-expect-error
import type { Farm } from 'state/farm'
//@ts-expect-error
import type { Harvest } from 'state/harvest'
//@ts-expect-error
import type { UbjUploads } from 'state/ubj-upload'
//@ts-expect-error
import type { Integrations } from 'state/integrations'
//@ts-expect-error
import type { Brand } from 'state/brand'
//@ts-expect-error
import type { Field } from 'state/field'
//@ts-expect-error
import type { ApplicationStatus } from 'state/app'
//@ts-expect-error
import type { Map } from 'state/map'
//@ts-expect-error
import type { CropVariety } from 'state/crop-variety'
//@ts-expect-error
import type { Job } from 'state/job'
//@ts-expect-error
import { Notification } from 'state/notification'

declare module 'state/index.js' {
  export type AppState = {
    account: Account
    app: ApplicationStatus
    field: Field
    map: Map
    farm: Farm
    cropVariety: CropVariety
    crop: Crop
    brand: Brand
    equipment: Equipment
    hardware: Hardware
    headers: Header
    integrations: Integrations
    client: Client
    ubjUploads: UbjUploads
    harvest: Harvest
    job: Job
    notification: Notification
  }
}
const state = combineReducers({
  account,
  app,
  field,
  map,
  farm,
  cropVariety,
  crop,
  brand,
  equipment,
  hardware,
  headers,
  integrations,
  client,
  ubjUploads,
  harvest,
  job,
  notification,
})
export default state
