import { updateCropCal } from 'api/harvest'
import { closeModal } from 'actions/app'
import { FormControlLabel, TextField } from '@mui/material'
import ModalTool from 'components/modal/modal-tool'

export default function EditCropCalibration() {
  const [val, setVal] = useState('')
  const [validInput, setValidInput] = useState(false)
  const [fieldChanged, setFieldChanged] = useState(false)
  const [selectedEquipment, setEquipment] = useState()
  const [currentCalibration, setCurrentCalibration] = useState('')
  const harvest = useSelector((state) => state.harvest.current)

  const lowerCalibrationLimit = 2000
  const upperCalibrationLimit = 500000

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const onChangeCropCal = useCallback((e) => {
    setVal(e.target.value)
    setFieldChanged(true)
  }, [])

  const submitChangeCropCal = useCallback(() => {
    dispatch(
      updateCropCal(harvest.id, val, selectedEquipment, harvest.tenantId),
    )
  }, [harvest, val, selectedEquipment])

  const onClose = useCallback(() => {
    dispatch(closeModal())
  })

  useEffect(() => {
    if (val && val >= lowerCalibrationLimit && val <= upperCalibrationLimit) {
      setValidInput(true)
    } else {
      setValidInput(false)
    }
  }, [val])

  let harvesterInfo = harvest?.stats?.harvester_info

  const hardware = useSelector((state) => state.hardware.collection)
  const equipment = useSelector((state) => state.equipment.collection)

  let equipmentArray = useMemo(() => {
    let _equipmentArray = []
    if (hardware && equipment && harvesterInfo) {
      for (let harvester in harvesterInfo) {
        const h = harvesterInfo[harvester]
        //if harvesterInfo has a ymId use it, if it doesn't then try to use whatever harvester info is indexed by.
        const ymId = h?.ymId || harvester
        let hwObj = hardware.find((hw) => hw?.farmtrxHardwareId === ymId)
        let eqObj =
          hwObj && equipment.find((eq) => eq.id === hwObj?.equipmentId)

        let currentCalibrations = h?.crop_calibrations
        _equipmentArray.push({
          name: eqObj ? eqObj.nickname : ymId,
          ymId: ymId,
          currentCalibrations: currentCalibrations ?? '',
        })
      }
      setEquipment(_equipmentArray[0]?.ymId)
    }
    return _equipmentArray
  }, [hardware, equipment, harvesterInfo])

  const onChangeEquipment = useCallback((e) => {
    setEquipment(e.target.value)
  }, [])

  useEffect(() => {
    if (equipmentArray.length > 0) {
      let calibrations = equipmentArray
        .find((e) => e.ymId === selectedEquipment)
        ?.currentCalibrations.toString()
      let lastCalibration = calibrations?.split(',').pop() ?? ''
      setCurrentCalibration(lastCalibration)
      setVal(lastCalibration)
    }
  }, [equipmentArray, selectedEquipment])

  return (
    <ModalTool
      header={t('edit_crop_calibration')}
      content={
        <>
          <FormControlLabel
            control={
              <TextField
                id="select-equipment"
                sx={{ width: '25ch', padding: '10px' }}
                disabled={!equipmentArray.length > 1}
                select
                name="equipment"
                value={selectedEquipment}
                onChange={onChangeEquipment}
                slotProps={{ select: { native: true } }}
                variant="outlined"
                size="small"
                placeholder={t('select_equipment')}
              >
                {equipmentArray.map((equipment) => (
                  <option value={equipment.ymId} key={equipment.ymId}>
                    {equipment.name}
                  </option>
                ))}
              </TextField>
            }
            label={t('equipment')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <TextField
                id="current-calibration"
                sx={{ width: '25ch', padding: '10px' }}
                disabled={true}
                name="equipment"
                value={currentCalibration}
                variant="outlined"
                size="small"
              />
            }
            label={t('current_calibration')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <TextField
                id="edit-crop-calibration"
                sx={{ width: '25ch', padding: '10px' }}
                value={val}
                name="edit-crop-calibration"
                variant="outlined"
                size="small"
                type="number"
                error={!validInput && fieldChanged}
                helperText={
                  !validInput && fieldChanged
                    ? t('invalid_crop_calibration')
                    : ''
                }
                onChange={onChangeCropCal}
              />
            }
            label={t('edit_crop_calibration')}
            labelPlacement="start"
          />
        </>
      }
      onClose={onClose}
      onApply={submitChangeCropCal}
      disabled={!validInput}
    />
  )
}
