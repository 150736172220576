import {
  addEquipment,
  editEquipmentDetails,
  equipmentsFetched,
  removeEquipment,
} from 'actions/equipment'
import http from 'common/http'
import { shouldShowFarmSetupWizard } from 'actions/app'

export const getEquipments = () => {
  return (dispatch) => {
    return http
      .get('/equipment')
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length === 0) {
          dispatch(shouldShowFarmSetupWizard())
        }

        dispatch(equipmentsFetched(res.data))
      })
      .catch(() => {})
  }
}

export const createEquipment = (data) => {
  return (dispatch) => {
    return http
      .post('/equipment', data)
      .then((res) => {
        dispatch(addEquipment(res.data))
      })
      .catch((error) => {
        if (error.response && error.response.data === 'Validation Error') {
          alert('Equipment cannot have duplicate names')
        }
      })
  }
}

export const editEquipment = (id, data) => {
  return (dispatch) => {
    return http.put(`/equipment/${id}`, data).then((res) => {
      dispatch(editEquipmentDetails(res.data))
    })
  }
}

export const deleteEquipment = (id) => {
  return (dispatch) => {
    return http.delete(`/equipment/${id}`).then(() => {
      dispatch(removeEquipment(id))
    })
  }
}
