import AccountDetailsForm from 'components/new-wizard/account-details-form'
import { makeStyles } from '@mui/styles'
import stepperBgLandscape from 'images/stepper-landscape-bg.png'
import stepperBgPortrait from 'images/stepper-portrait-bg.png'

import {
  Box,
  Button,
  Grid2,
  Typography,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  StepConnector,
} from '@mui/material'
import CropVarieties from 'components/new-wizard/crop-varieties'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import EquipmentsComponent from 'components/farm-settings/equipment'
import CropHeadersComponent from 'components/farm-settings/headers'
import { createTenant, editTenant, editTenantPreferences } from 'api/tenant'
import { createUserAccount, editUserAccount } from 'api/user'
import { createFarm } from 'api/farm'
import { closeModal, shouldShowDashboard } from 'actions/app'
import { configureCropVarieties } from 'api/crop-variety'
import useMediaQuery from '@mui/material/useMediaQuery'
import { updateCountryExtent } from 'actions/field'
import { createClient } from 'api/client'
import { useLocation } from 'react-router'

const useStyles = makeStyles(() => ({
  ['@media (orientation:landscape)']: {
    stepContent: {
      width: '100%',
    },
    accountDetailsContainer: {
      left: '20%',
      height: '100vh',
      borderRadius: '50px 0 0 50px',
      position: 'absolute',
      background: 'white',
      width: '100vw',
      display: 'flex',
    },
  },
  ['@media (orientation:portrait)']: {
    accountDetailsContainer: {
      left: '0',
      top: '120px',
    },
    stepContent: {
      left: '0',
      top: '120px',
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
    stepperStyle: {
      backgroundImage: `url(${stepperBgPortrait})`,
      height: '10vh',
      padding: '80px',
      width: '100%',
      minWidth: '100%',
    },
    actionsContainer: {
      '&&': {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: 0,
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.3)',
        width: '100%',
        right: 0,
        bottom: 0,
        '& > div': {
          display: 'flex',
        },
      },
    },
  },
  connectorLine: {
    ['@media (orientation:landscape)']: {
      // eslint-disable-line no-useless-computed-key
      minHeight: '100px',
    },
  },
}))

//function getStepContent(step) {
function GetStepContent(props) {
  const tenantInfo = useCallback((data) => {
    props.tenantInfo(data)
  })

  const enableEqNextStep = useCallback((data) => {
    props.enableEqNextButton(data)
  })

  const enableHeadersNextStep = useCallback((data) => {
    props.enableHeadersNextButton(data)
  })

  const enableAccNextStep = useCallback((data) => {
    props.enableAccNextButton(data)
  })

  const enableFinish = useCallback((data) => {
    props.enableNextButton(data)
  })

  const selectedCrops = useCallback((data) => {
    props.selectedCrops(data)
  })

  const setEditTenant = useCallback((data) => {
    props.editTenant(data)
  })

  switch (props.activeStepParam) {
    case 0:
      return (
        <AccountDetailsForm
          enableAccButton={enableAccNextStep}
          formFields={tenantInfo}
          setEditTenant={setEditTenant}
        />
      )
    case 1:
      return (
        <EquipmentsComponent
          enableEqNextButton={enableEqNextStep}
          hideTimeDelay
        />
      )
    case 2:
      return (
        <CropHeadersComponent enableHeadersNextButton={enableHeadersNextStep} />
      )
    case 3:
      return (
        <CropVarieties
          enableFinish={enableFinish}
          selectedCrops={selectedCrops}
        />
      )
    default:
      return 'Unknown step'
  }
}

export default function WizardStepper({ partner }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const matches = useMediaQuery('(orientation: landscape)')

  const [activeStep, setActiveStep] = React.useState(0)
  const [tenantInputs, setTenantInputs] = useState()
  const [accNextBtnState, setAccNextBtnState] = useState(true)
  const [eqNextBtnState, setEqNextBtnState] = useState(true)
  const [headersNextBtnState, setHeadersNextBtnState] = useState(true)
  const [enableNextBtnState, setEnableNextBtnState] = useState(true)

  const [selectedCrops, setSelectedCrops] = useState([])
  const [isEditTenant, setIsEditTenant] = useState(false)

  //const farmName = useSelector((state) => state.farm.farmName)
  const permissions = useSelector((state) => state.account.permissions)
  const [showPermissions, setShowPermissions] = useState({})
  const userDetails = useSelector((state) => state.account.user)

  const { state } = useLocation()
  const { farmName } = state

  useEffect(() => {
    setShowPermissions({
      skipWizard: permissions.includes('tenant:list'),
    })
  }, [permissions])

  const steps = [
    {
      label: t('account'),
    },
    {
      label: t('equipment'),
    },
    {
      label: t('headers'),
    },
    {
      label: t('crops'),
    },
  ]

  useEffect(() => {
    if (localStorage.getItem('wizardActiveStep')) {
      if (localStorage.getItem('wizardActiveStep') != 'null') {
        setActiveStep(parseInt(localStorage.getItem('wizardActiveStep')))
      }
    }
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    //set current step
    localStorage.setItem('wizardActiveStep', activeStep)
    if (activeStep === 0) {
      tenantInputs['displayName'] = farmName
      if (isEditTenant) {
        dispatch(editTenant(tenantInputs)).then(() => {
          dispatch(
            editUserAccount({
              email: tenantInputs.email,
              fullName: tenantInputs.fullName,
              phoneNumber: tenantInputs.phoneNumber,
              eusaDate: userDetails.eusaDate,
            }),
          )
          dispatch(
            editTenantPreferences({
              path: 'general.country',
              value: tenantInputs && tenantInputs.country.id,
            }),
          ).then(() => {})
        })
      } else {
        dispatch(createTenant({ ...tenantInputs, partner })).then((res) => {
          if (res === 'TenantCreated') {
            dispatch(
              createUserAccount({
                email: tenantInputs.email,
                fullName: tenantInputs.fullName,
                phoneNumber: tenantInputs.phoneNumber,
                eusaDate: userDetails.eusaDate,
              }),
            )

            const { streetAddress, postalCode, email } = tenantInputs

            dispatch(
              createClient({
                name: 'Default_Client',
                streetAddress,
                postalCode,
                email,
              }),
            ).then((res) => {
              if (res.message === 'Client created') {
                dispatch(
                  createFarm({
                    name: 'Default_Farm',
                    streetAddress,
                    postalCode,
                    clientId: res.data['id'],
                    tenantId: res.data['tenantId'],
                  }),
                )
              }
            })
            if (tenantInputs?.country) {
              dispatch(updateCountryExtent(tenantInputs.country.extent))
            }
          }
        })
      }
    } else if (activeStep === 3) {
      // Clear any existing error modal due to missing data from a failure to fetch the tenant for example.
      dispatch(closeModal())

      dispatch(configureCropVarieties({ crops: selectedCrops }))
      dispatch(shouldShowDashboard())
      localStorage.setItem('wizardActiveStep', null)
    }
  }

  const handleSkipWizard = () => {
    dispatch(closeModal())

    dispatch(shouldShowDashboard())
    localStorage.setItem('wizardActiveStep', null)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getTenantInfo = (data) => {
    setTenantInputs(data)
  }

  const getAccNextBtnState = (data) => {
    setAccNextBtnState(data)
  }

  const getEqNextBtnState = (data) => {
    setEqNextBtnState(data)
  }

  const getHeadersNextBtnState = (data) => {
    setHeadersNextBtnState(data)
  }

  const getEnableNextButton = (data) => {
    setEnableNextBtnState(data)
  }

  const getSelectedCrops = (data) => {
    setSelectedCrops(data)
  }

  const getIsEditTenant = (data) => {
    setIsEditTenant(data)
  }

  return (
    <Grid2 container>
      <Grid2
        size={{ md: 2, lg: 4 }}
        sx={{
          backgroundImage: `url(${stepperBgLandscape})`,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation={matches ? 'vertical' : 'horizontal'}
          style={{ marginLeft: '80px' }}
          connector={
            <StepConnector
              classes={{
                line: classes.connectorLine,
              }}
            />
          }
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent
                sx={{
                  position: 'absolute',
                  left: '25%',
                  zIndex: 99,
                  top: '5%',
                  borderLeft: 0,
                  padding: 0,
                  margin: 0,
                  maxWidth: '70vw',
                }}
              >
                <Typography component="div">
                  {
                    <GetStepContent
                      enableAccNextButton={getAccNextBtnState}
                      enableEqNextButton={getEqNextBtnState}
                      enableHeadersNextButton={getHeadersNextBtnState}
                      activeStepParam={index}
                      tenantInfo={getTenantInfo}
                      selectedCrops={getSelectedCrops}
                      editTenant={getIsEditTenant}
                      enableNextButton={getEnableNextButton}
                    />
                  }
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid2>

      <Box
        mx={15}
        sx={{
          textAlign: 'end',
          zIndex: 99,
          position: 'absolute',
          bottom: '20px',
          right: '20px',
        }}
      >
        <div>
          {showPermissions.skipWizard ? (
            <Button color="primary" onClick={handleSkipWizard}>
              {t('skip')}
            </Button>
          ) : null}
          <Button
            disabled={activeStep === 0}
            color="primary"
            onClick={handleBack}
          >
            {t('back')}
          </Button>
          <Button
            variant="contained"
            disabled={
              (activeStep === 0 && !accNextBtnState) ||
              (activeStep === 1 && !eqNextBtnState) ||
              (activeStep === 2 && !headersNextBtnState) ||
              (activeStep === 3 && !enableNextBtnState)
            }
            onClick={handleNext}
            sx={{ borderRadius: '25px', width: '160px' }}
            endIcon={<ArrowForwardIcon />}
          >
            {activeStep === steps.length - 1 ? t('finish') : t('next')}
          </Button>
        </div>
      </Box>
      <Grid2
        size={{ md: 8, lg: 8 }}
        sx={{
          left: '20%',
          height: '100vh',
          borderRadius: '50px',
          position: 'absolute',
          background: 'white',
          width: '100vw',
          display: 'flex',
          minWidth: '100%',
        }}
        style={{ zIndex: 9 }}
      ></Grid2>
    </Grid2>
  )
}
