import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  ReactElement,
  RefObject,
} from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noop } from 'lodash-es'
//@ts-expect-error
import { isNotBlank } from 'common/misc'
//@ts-expect-error
import useOnClickOutside from 'hooks/click-outside'
import { defaultShadow, BlankLink } from 'components/foundation'
//@ts-expect-error
import colors from 'common/colors'

declare module 'components/popup/popup-internal.js' {
  const PopupInternal: React.FunctionComponent<PopupInternalProps>
  //export default PopupInternal
  export type PixelCoords = {
    x: number
    y: number
  }
  export type PopupInternalProps = {
    onClose: Function
    children: ReactElement<any, any>
    title: string
    pixel: PixelCoords
    ref?: RefObject<HTMLDivElement>
  }
}
export default function PopupInternal(
  props: PopupInternalProps,
): ReactElement<any, any> | null {
  const {
    onClose = noop,
    children,
    title,
    pixel = {
      x: 0,
      y: 0,
    },
  } = props

  const ref = useRef<HTMLDivElement>(null)
  const [withTitle, setWithTitle] = useState(false)

  useOnClickOutside(ref, () => {
    onClose()
  })

  const onCloseClicked = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    setWithTitle(isNotBlank(title))
  }, [title])

  return (
    <StyledWrapper ref={ref} pixel={pixel} withTitle={withTitle}>
      {withTitle ? <StyledHeader key="title">{title}</StyledHeader> : null}
      <StyledClose key="close-link" onClick={onCloseClicked}>
        <FontAwesomeIcon icon="times" color={colors.grey} />
      </StyledClose>
      {children}
      <Arrow pixel={pixel} />
    </StyledWrapper>
  )
}

PopupInternal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  pixel: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

//@ts-ignore
const StyledHeader = styled.h1<StyledHeaderProps>`
  font-size: large;
  margin-top: 9px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
`

interface StyledWrapperProps {
  pixel: PixelCoords
  withTitle?: boolean
  children: any
  ref: any
}

interface StyledHeaderProps {
  key: string
  children?: string
}

interface StyledHeaderProps {
  key: string
  children?: string
}

//@ts-ignore
const StyledWrapper = styled.div<StyledWrapperProps>`
  background-color: white;
  padding: 8px;
  position: fixed;
  top: ${({ pixel }) => pixel.y - 20 + 'px'};
  left: ${({ pixel }) => pixel.x - 5 + 'px'};
  width: 238px;
  min-height: 100px;
  padding-top: 40px;
  z-index: 1000;
  overflow-y: auto;
  ${defaultShadow}
  border-radius: 4px;
  padding-top: ${(props) => (props.withTitle ? '0px' : '40px')};
`

StyledWrapper.propTypes = { withTitle: PropTypes.bool }

StyledWrapper.defaultProps = { withTitle: false }

//@ts-ignore
const StyledClose = styled(BlankLink)`
  position: absolute;
  right: 12px;
  top: 10px;
`
interface ArrowProps {
  pixel: PixelCoords
}
//@ts-ignore
const Arrow = styled.div<ArrowProps>`
  position: fixed;
  top: ${(props) => props.pixel.y + 38 + 'px'};
  left: ${({ pixel }) => pixel.x + 229 + 'px'};
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid white;
  height: 0;
  width: 0;
`
