import {
  Grid2,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSelector, useDispatch } from 'react-redux'
import * as c from 'common/c'
import { editUserPreferences } from 'api/user'
import { fetchNotifications } from 'api/notification'
import { useTheme } from '@mui/material/styles'

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: 250,
    },
  },
  marginThreshold: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
}

function getStyles(category, categories, theme) {
  return {
    fontWeight: categories.includes(category)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  }
}

export default function UserPreference() {
  const theme = useTheme()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userPrefs = useSelector((state) => state.account.user['prefs'])

  const [categories, setCategories] = useState([])

  useEffect(() => {
    let categories = []
    if (userPrefs) {
      const obj = userPrefs?.notifications?.suppression
      categories = Object.keys(obj).filter((k) => obj[k] === true)
    }
    setCategories(categories)
  }, [userPrefs])

  const handleChange = useCallback((e) => {
    const {
      target: { value },
    } = e
    let lastValue,
      categoryAdded = false

    if (categories?.length > e.target.value.length) {
      lastValue = categories.filter((c) => !e.target.value.includes(c))
    } else {
      lastValue = e.target.value.filter((c) => !categories.includes(c))
      categoryAdded = true
    }

    setCategories(typeof value === 'string' ? value.split(',') : value)

    const reqBody = {
      path: `notifications.suppression.${lastValue[0]}`,
      value: categoryAdded ? true : false,
    }

    dispatch(editUserPreferences(reqBody)).then(() => {
      return dispatch(fetchNotifications())
    })
  })

  return (
    <Grid2 container alignSelf={'start'} spacing={2}>
      <Grid2 size={12}>
        <Typography align={'left'} paragraph variant="h5">
          <b>{t('modify_notification_preferences')}</b>
        </Typography>
      </Grid2>

      <Grid2 size={4} alignContent={'center'}>
        <Typography paragraph>
          {t('suppress_notification_categories')}
        </Typography>
      </Grid2>
      <Grid2 size={8}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-chip-label">
            {t('suppress_notification_categories')}
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={categories}
            onChange={handleChange}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Notification Category"
              />
            }
            MenuProps={MenuProps}
          >
            {c.notificationCategory.map((category) => (
              <MenuItem
                key={category}
                value={category}
                style={getStyles(category, categories, theme)}
              >
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>
    </Grid2>
  )
}
