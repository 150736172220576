import { t } from 'i18next'
import React from 'react'
//@ts-expect-error
import { ClientModel } from 'state/client'
//@ts-expect-error
import { FarmOptionModel } from 'state/farm'
import { jwtDecode } from 'jwt-decode'
//@ts-expect-error
import { Subscription, SubscriptionPeriod } from 'state/account'

export type JWTTokenData = {
  [key: string]: any
  iss?: string | undefined
  sub?: string | undefined
  aud?: string | string[] | undefined
  exp?: number | undefined
  nbf?: number | undefined
  iat?: number | undefined
  jti?: string | undefined
}
export type ClientFarmResult = {
  client: ClientModel
  farms: FarmOptionModel[]
}
const yieldKeys = {
  tonnes_ha: ['yldt_ha', 'YLDt_ha'],
  bu_ac: ['yldbu_ac', 'YLDbu_ac'],
  kg_ha: ['yldkg_ha', 'YLDkg_ha'],
}

/**
 * Checks if as string is blank
 *
 * @param {string} str
 * @returns {boolean} true if blank (null, undefined or with spaces).
 */
export function isBlank(str: string): boolean {
  return !str || /^\s*$/.test(str);
}

export function isNotBlank(str: string): boolean {
  return !isBlank(str)
}

export function str(str: string): string {
  return JSON.stringify(str, null, 2)
}

export function noop() {}

export function hexToRgba(hex: string, opacity: number = 1): string {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c: string[] | string = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    const numericVal = parseInt(c)
    return `rgba(${[
      (numericVal >> 16) & 255,
      (numericVal >> 8) & 255,
      numericVal & 255,
    ].join(',')}, ${opacity})`
  }

  throw new Error('Bad Hex')
}

export function toDateString(date: Date): string {
  return (
    ('0' + date.getDate()).slice(-2) +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    date.getFullYear()
  )
}

export function getPartner(): string {
  return window.location.host.split('.')[1]
    ? window.location.host.split('.')[0]
    : 'farmtrx'
}

export function getInitialUnits(isMetric: boolean): object {
  const units = {
    area_in: isMetric ? 'ha' : 'ac',
    length_in: isMetric ? 'meters' : 'feet',
    rate_in: isMetric ? 'ha_hr' : 'ac_hr',
    speed_in: isMetric ? 'kmh' : 'mph',
    temperature_in: isMetric ? 'celsius' : 'fahrenheit',
    testweight_in: isMetric ? 'kg_hl' : 'lb_usbu',
    totalyield_in: isMetric ? 'tonne' : 'bu',
    volume_in: isMetric ? 'cubic_meters' : 'bushels',
    weight_in: isMetric ? 'tonne' : 'tonsUS',
    yieldunit_in: isMetric ? 'tonnes_ha' : 'bu_ac',
  }
  return units
}

export function requestYieldUnit(preferredYield): string {
  const yieldUnit = yieldKeys[preferredYield]
  if (yieldUnit != null) {
    return yieldUnit[0]
  }
  return yieldKeys.tonnes_ha[0]
}

export function getTranslatedName(name: string): string {
  const tmp = name.toLowerCase().replace(' ', '_')
  const translatedName = t(tmp)
  //If we don't have a translation for it, return name
  return translatedName === tmp ? name : translatedName
}

export function getYearsWithHarvests(harvestYears): number[] {
  const filtered = harvestYears?.filter((hYear) => {
    if (hYear.hasHarvest) {
      return hYear.harvestYr
    }
  })
  if (!filtered?.length) {
    return [new Date().getFullYear()]
  }
  return filtered.map((hYear) => hYear.harvestYr)
}

export function getAppBarHeight(): number {
  const [appBarHeight, setAppBarHeight] = React.useState(0)

  React.useEffect(() => {
    const getAppBarHeight: ResizeObserverCallback = ([{ target: appBar }]) => {
      appBar && setAppBarHeight(appBar.clientHeight)
    }

    const resizeObserver = new ResizeObserver(getAppBarHeight)

    resizeObserver.observe(document.querySelector('header.MuiAppBar-root')!)
  }, [])

  return appBarHeight
}

export function getClientFarm(
  cffEnabled: boolean,
  clients: ClientModel[],
  prefDefault: string,
): {
  client: ClientModel | undefined
  farms: FarmOptionModel[]
  selectedFarms: FarmOptionModel[]
} {
  let client: ClientModel | undefined = undefined
  let selectedClient: ClientModel | undefined = undefined
  if (clients.length === 0) {
    return {
      client,
      farms: [],
      selectedFarms: [],
    }
  }
  if (cffEnabled) {
    let defaultClient = localStorage.getItem('selectedClient')
    if (
      defaultClient !== '' &&
      defaultClient !== 'undefined' &&
      defaultClient &&
      JSON.parse(defaultClient) !== ''
    ) {
      selectedClient = JSON.parse(defaultClient)
    }
    if (
      selectedClient &&
      'id' in selectedClient &&
      clients.findIndex((c: ClientModel) => {
        return c.id === selectedClient?.id
      }) > -1
    ) {
      client = selectedClient
    } else if (clients.length > 0) {
      client = clients[0]
      localStorage.setItem('selectedClient', JSON.stringify(client))
    }
  } else if (clients.length) {
    if (prefDefault && typeof parseInt(prefDefault) === 'number') {
      client = clients.find((c) => c?.id === parseInt(prefDefault))
      if (client === undefined) {
        client = clients[0]
      }
      localStorage.setItem('selectedClient', JSON.stringify(client))
    } else {
      client = clients[0]
      localStorage.setItem('selectedClient', JSON.stringify(client))
    }
  }
  //Get Farm(s) from the above client
  let farms: FarmOptionModel[] = client?.farms || []
  let selectedFarms: FarmOptionModel[] = client?.farms || []
  if (cffEnabled) {
    const defaultFarms = localStorage.getItem('selectedFarms')
    if (defaultFarms !== '' && defaultFarms !== 'undefined' && defaultFarms) {
      selectedFarms = JSON.parse(defaultFarms)
    } else {
      localStorage.setItem(
        'selectedFarms',
        farms?.length ? JSON.stringify(farms) : JSON.stringify([]),
      )
    }
  }
  //@ts-ignore
  return { client, farms, selectedFarms }
}

export function isTokenValid(token?: string, offset: number = 120): boolean {
  if (token && token !== '') {
    const decodedToken: JWTTokenData = jwtDecode(token)
    //Check to make sure token is valid for offset seconds from now, default is 2 minutes
    //can set longer for operations like harvest sync
    const now = Math.floor(Date.now() / 1000) + offset
    const tokenValid: boolean =
      (decodedToken?.exp && decodedToken.exp > now) || false
    return tokenValid
  }
  return false
}

export function useLegacySLD(lpVersion: string) {
  const [major, minor, patch] = lpVersion.split('.').map(Number)
  return (
    major < 5 ||
    (major <= 5 && minor < 3) ||
    (major <= 5 && minor <= 3 && patch < 1)
  )
}

//Check if start date or end date are within a valid subscription
//start date and end date can be the same value (or just a year)
export function isSubscriptionValid(
  subscriptions: Subscription[],
  startDate: string,
  endDate: string,
): boolean {
  if (
    subscriptions &&
    subscriptions.length &&
    subscriptions[0]?.subscriptionPeriods &&
    startDate &&
    endDate
  ) {
    let subscriptionsFound: SubscriptionPeriod[] = []
    let subscriptionPeriods: SubscriptionPeriod[] =
      subscriptions[0].subscriptionPeriods
    subscriptionsFound = subscriptionPeriods.filter((subscription) => {
      return (
        (subscription.subscriptionStatus === 'paid' ||
          subscription.subscriptionStatus === 'free_year' ||
          subscription.subscriptionStatus === 'comped') &&
        ((startDate >= subscription.startDate &&
          startDate <= subscription.endDate) ||
          (endDate <= subscription.endDate &&
            endDate >= subscription.startDate))
      )
    })
    if (subscriptionsFound && subscriptionsFound.length) {
      return true
    }
  }
  return false
}

export function getDeviceString(deviceType: number) {
  switch (deviceType) {
    case 1:
      return t('legacy_yield_monitor')
    case 2:
      return t('legacy_moisture_sensor')
    case 3:
      return t('yield_monitor_2')
    case 4:
      return t('moisture_sensor_2')
    case 5:
      return t('yield_monitor_pro')
    case 6:
      return t('rtk_base_station')
    case 7:
      return t('adsb_receiver')
    case 8:
      return t('yield_simulator')
    case 9:
      return t('radar_sensor')
    case 10:
      return t('ecu3')
    case 11:
      return t('moisture_sensor_3a')
    case 12:
      return t('ecu4')
    case 13:
      return t('header_angle_sensor')
    case 14:
      return t('moisture_sensor_3b')
    default:
      return t('unknown')
  }
}
