import {
  AppBar,
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import Logo from 'components/logo'
import Logout from 'components/logout'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router'
import {
  Notifications as NotificationsIcon,
  HelpOutlined as HelpOutlinedIcon,
  Delete as DeleteIcon,
  PriorityHigh as PriorityHighIcon,
  LowPriority as LowPriorityIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material'
import ImpersonateCheck from 'components/impersonate-check'
import { dismissNotifications, readNotifications } from 'api/notification'

function getLinkStyle(isActive) {
  return isActive ? activeStyle : farmLinksStyle
}

const activeStyle = {
  borderRadius: '4px',
  textDecoration: 'none',
  color: '#3475E0',
  width: 'inherit',
  fontWeight: 'bold',
}

const farmLinksStyle = {
  color: 'gray',
  textDecoration: 'none',
  display: 'flex',
  width: 'inherit',
  whiteSpace: 'nowrap',
}

export default function Header() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.account.permissions)
  const notifications = useSelector((state) => state.notification.collection)
  const [showFields, setShowField] = useState(false)
  const [showReports, setShowReports] = useState(false)
  const [showAdmin, setShowAdmin] = useState(false)
  const [showSupport] = useState(false)
  const [showFarmSetup, setShowFarmSetup] = useState(false)
  const [allowDismissNotifications, setAllowDismissNotifications] =
    useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [notificationsIconEl, setNotificationsIconEl] = React.useState(null)
  const notificationsOpen = Boolean(notificationsIconEl)
  const [unreadNotifications, setUnreadNotifications] = useState(null)
  const tenantObj = useSelector((state) => state.account.singleton)

  useEffect(() => {
    if (notifications.length) {
      setUnreadNotifications(notifications.filter((n) => !n.isRead))
    } else {
      setUnreadNotifications([])
    }
  }, [notifications])

  const handleNotificationsClick = (event) => {
    setNotificationsIconEl(event.currentTarget)
  }

  const handleNotificationsClose = () => {
    setNotificationsIconEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onHelp = () => {
    return window.open(`${HELPGUIDE_URL}`, '_blank')
  }

  const markAsRead = (id) => {
    if (Number.isInteger(parseInt(id))) {
      setTimeout(() => dispatch(readNotifications([id])), 2000)
    } else {
      let ids = []
      unreadNotifications.map((a) => ids.push(a.id))
      dispatch(readNotifications(ids))
    }
  }

  const dismissItems = useCallback((id) => {
    if (Number.isInteger(parseInt(id))) {
      dispatch(dismissNotifications([id]))
    } else {
      let ids = []
      notifications.map((n) => ids.push(n.id))
      dispatch(dismissNotifications(ids))
    }
  })

  useEffect(() => {
    setShowField(permissions.includes('field:read'))
    setShowReports(permissions.includes('reports:read'))
    setShowAdmin(
      permissions.includes('users:write') ||
        permissions.includes('tenant:list'),
    )
    setShowFarmSetup(permissions.includes('farm:read'))
    setAllowDismissNotifications(permissions.includes('farm:write'))
  }, [permissions])

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Logo />
          <div style={{ marginRight: 1, flexGrow: 2 }}>
            <List style={{ display: 'flex' }}>
              {showFields && (
                <ListItem>
                  <NavLink
                    style={({ isActive }) => getLinkStyle(isActive)}
                    to="/"
                  >
                    <ListItemText disableTypography primary={t('map')} />
                  </NavLink>
                </ListItem>
              )}
              {showFarmSetup && (
                <ListItem>
                  <NavLink
                    to="/fields"
                    style={({ isActive }) => getLinkStyle(isActive)}
                  >
                    <ListItemText disableTypography primary={t('fields')} />
                  </NavLink>
                </ListItem>
              )}
              {showReports && (
                <ListItem>
                  <NavLink
                    to="/reports"
                    style={({ isActive }) => getLinkStyle(isActive)}
                  >
                    <ListItemText disableTypography primary={t('reports')} />
                  </NavLink>
                </ListItem>
              )}
              {showSupport && (
                <ListItem>
                  <NavLink
                    to="/support"
                    style={({ isActive }) => getLinkStyle(isActive)}
                  >
                    <ListItemText disableTypography primary={t('support')} />
                  </NavLink>
                </ListItem>
              )}
              {showFarmSetup && (
                <ListItem>
                  <NavLink
                    to="/farmSetup"
                    style={({ isActive }) => getLinkStyle(isActive)}
                  >
                    <ListItemText
                      style={{ whiteSpace: 'nowrap' }}
                      disableTypography
                      primary={t('farm_setup')}
                    />
                  </NavLink>
                </ListItem>
              )}
              {showAdmin && (
                <ListItem style={{ textAlign: 'center' }}>
                  <NavLink
                    to="/admin"
                    style={({ isActive }) => getLinkStyle(isActive)}
                  >
                    <ListItemText disableTypography primary={t('admin')} />
                  </NavLink>
                </ListItem>
              )}
            </List>
          </div>
          <div
            style={{
              display: 'flex',
              flexGrow: '5',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <li style={{ padding: '8px', listStyle: 'none' }}>
              <b>{tenantObj?.['displayName']}</b>
            </li>
            <ImpersonateCheck />
            <Divider orientation="vertical" variant="middle" flexItem />

            <Tooltip title={t('notifications')} disableInteractive>
              <Badge badgeContent={unreadNotifications?.length} color="primary">
                <IconButton
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleNotificationsClick}
                >
                  <NotificationsIcon />
                </IconButton>
              </Badge>
            </Tooltip>

            <Menu
              key="notifications"
              anchorEl={notificationsIconEl}
              id="notifications-icon"
              open={notificationsOpen}
              onClose={handleNotificationsClose}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: '300px',
                    width: '300px',
                  },
                },
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 8,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Stack
                direction="row"
                spacing={2}
                ml={1}
                mb={1}
                sx={{
                  justifyContent: 'center',
                  display: notifications?.length ? 'flex' : 'none',
                  position: 'sticky',
                  top: 0,
                  bottom: 0,
                  backgroundColor: '#fafafa',
                  marginLeft: 'unset',
                  zIndex: 1,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={markAsRead}
                  disabled={unreadNotifications?.length == 0}
                >
                  {t('mark_all_read')}
                </Button>
                {allowDismissNotifications ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={dismissItems}
                  >
                    {t('dismiss_all')}
                  </Button>
                ) : null}
              </Stack>
              {notifications?.length ? (
                notifications.map((n, index) => {
                  return (
                    <div key={index}>
                      <MenuItem
                        onMouseEnter={() =>
                          n?.isRead ? null : markAsRead(n.id)
                        }
                        key={n?.id}
                        sx={{
                          textWrap: 'auto',
                          paddingLeft: '8px !important',
                          backgroundColor: n?.isRead ? null : '#c6def8',
                          '& .MuiTypography-root': n?.isRead
                            ? null
                            : {
                                fontWeight: 'bold',
                              },
                        }}
                      >
                        <IconButton size="small" sx={{ mr: 1 }}>
                          {n.severity === 'important' ? (
                            <PriorityHighIcon />
                          ) : (
                            <LowPriorityIcon />
                          )}
                        </IconButton>
                        <Typography>{n.message}</Typography>
                        {allowDismissNotifications ? (
                          <IconButton onClick={() => dismissItems(n.id)}>
                            <DeleteIcon titleAccess="Dismiss" />
                          </IconButton>
                        ) : null}
                      </MenuItem>
                      <Divider sx={{ margin: '0 !important' }} />
                    </div>
                  )
                })
              ) : (
                <MenuItem key="no-notifications" sx={{ textWrap: 'auto' }}>
                  <Typography>{t('no_notifications')}</Typography>
                </MenuItem>
              )}
            </Menu>

            <Tooltip title={t('help')} disableInteractive>
              <IconButton size="small" sx={{ ml: 2 }} onClick={handleClick}>
                <HelpOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              key="help"
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 8,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={onHelp}>
                <ListItemIcon>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
                {t('help')}
              </MenuItem>
            </Menu>

            <Divider />
            <Logout />
          </div>
          <Outlet />

          {/*<SettingsApplications color="primary" onClick={showPreferenceModal} />*/}
        </Toolbar>
      </AppBar>
    </div>
  )
}
